import {
  TRADING_PARTNERS_LIST_OPEN,
  TRADING_PARTNERS_LIST_OPEN_SUCCESS,
  TRADING_PARTNERS_LIST_OPEN_ERROR,
  NOTIFICATION_SETTINGS_OPEN,
  NOTIFICATION_SETTINGS_OPEN_SUCCESS,
  NOTIFICATION_SETTINGS_OPEN_ERROR,
  GENERAL_SETTINGS_OPEN,
  GENERAL_SETTINGS_OPEN_SUCCESS,
  GENERAL_SETTINGS_OPEN_ERROR,
  GENERAL_SETTINGS_UPDATE,
  GENERAL_SETTINGS_UPDATE_SUCCESS,
  GENERAL_SETTINGS_UPDATE_ERROR,
  GET_NOTIFICATION_TYPES,
  GET_NOTIFICATION_TYPES_SUCCESS,
  GET_NOTIFICATION_TYPES_ERROR,
  BLOTTER_PRESETS_OPEN,
  BLOTTER_PRESETS_OPEN_SUCCESS,
  BLOTTER_PRESETS_OPEN_ERROR,
  BLOTTER_PRESETS_UPDATE,
  BLOTTER_PRESETS_UPDATE_SUCCESS,
  BLOTTER_PRESETS_UPDATE_ERROR,
} from '../constants';

import auth from '../../auth';

const initialState = {
  tradingPartnersList: {
    open: false,
    status: 'pending',
    error: null,
  },
  notificationsSettings: {
    open: false,
    status: 'pending',
    error: null,
  },
  generalSettings: {
    open: false,
    savedRfqPresets: [],
    status: 'pending',
    error: null,
  },
  blotterPresets: {
    open: false,
    savedBlotterPresets: [],
    status: 'pending',
    error: null,
  },
  notificationTypes: {
    items: [],
    status: 'pending',
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TRADING_PARTNERS_LIST_OPEN:
      let next = { ...state.tradingPartnersList };
      next.status = 'pending';
      return {
        ...state,
        tradingPartnersList: next,
      };
    case TRADING_PARTNERS_LIST_OPEN_SUCCESS:
      next = { ...state.tradingPartnersList };
      next.status = 'success';
      next.open = action.payload.open;
      return {
        ...state,
        tradingPartnersList: next,
      };
    case TRADING_PARTNERS_LIST_OPEN_ERROR:
      next = { ...state.tradingPartnersList };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        tradingPartnersList: next,
      };
    case NOTIFICATION_SETTINGS_OPEN:
      next = { ...state.notificationsSettings };
      next.status = 'pending';
      return {
        ...state,
        notificationsSettings: next,
      };
    case NOTIFICATION_SETTINGS_OPEN_SUCCESS:
      next = { ...state.notificationsSettings };
      next.status = 'success';
      next.open = action.payload.open;
      return {
        ...state,
        notificationsSettings: next,
      };
    case NOTIFICATION_SETTINGS_OPEN_ERROR:
      next = { ...state.notificationsSettings };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        notificationsSettings: next,
      };
    case GENERAL_SETTINGS_OPEN:
      next = { ...state.generalSettings };
      next.status = 'pending';
      return {
        ...state,
        generalSettings: next,
      };
    case GENERAL_SETTINGS_OPEN_SUCCESS:
    case GENERAL_SETTINGS_UPDATE_SUCCESS:
      next = { ...state.generalSettings };
      next.status = 'success';
      next.open = action.payload.open;
      next.savedRfqPresets = action.payload.savedRfqPresets;
      return {
        ...state,
        generalSettings: next,
      };
    case GENERAL_SETTINGS_OPEN_ERROR:
    case GENERAL_SETTINGS_UPDATE_ERROR:
      next = { ...state.generalSettings };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        generalSettings: next,
      };
    case BLOTTER_PRESETS_OPEN:
      next = { ...state.blotterPresets };
      next.status = 'pending';
      return {
        ...state,
        blotterPresets: next,
      };
    case BLOTTER_PRESETS_OPEN_SUCCESS:
    case BLOTTER_PRESETS_UPDATE_SUCCESS:
      next = { ...state.blotterPresets };
      next.status = 'success';
      next.open = action.payload.open;
      next.savedBlotterPresets = action.payload.savedBlotterPresets;
      return {
        ...state,
        blotterPresets: next,
      };
    case BLOTTER_PRESETS_OPEN_ERROR:
    case BLOTTER_PRESETS_UPDATE_ERROR:
      next = { ...state.blotterPresets };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        blotterPresets: next,
      };
    case GET_NOTIFICATION_TYPES:
      next = { ...state.notificationTypes };
      next.status = 'pending';
      return {
        ...state,
        notificationTypes: next,
      };
    case GET_NOTIFICATION_TYPES_SUCCESS:
      next = { ...state.notificationTypes };
      next.status = 'success';
      next.items = action.payload;
      return {
        ...state,
        notificationTypes: next,
      };
    case GET_NOTIFICATION_TYPES_ERROR:
      next = { ...state.notificationTypes };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        notificationTypes: next,
      };
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
