import { takeEvery, put, select, call } from 'redux-saga/effects';

import {
  INIT_CHART,
  INIT_CHART_SUCCESS,
  DESTROY_CHART,
  DESTROY_CHART_SUCCESS,
  UPDATE_CHART,
  UPDATE_CHART_SUCCESS,
  INIT_TABLE,
  INIT_TABLE_SUCCESS,
  DESTROY_TABLE,
  DESTROY_TABLE_SUCCESS,
  UPDATE_TABLE,
  UPDATE_TABLE_SUCCESS,
  CURRENCY_NOK,
} from '../constants';

import {
  initChart as actionInitChart,
  destroyChart as actionDestroyChart,
  updateChart as actionUpdateChart,
  initTable as actionInitTable,
  destroyTable as actionDestroyTable,
  updateTable as actionUpdateTable,
} from '../actions';

import { isObject } from '../../../core/utils/functions';

import chartData from '../config/data';

import api from '../api';
import { getDefault, getConfig } from '../selectors';

import auth from '../../auth';
import workspaces from '../../workspaces';

function* initChart(action) {
  const {
    payload: { id, widget },
  } = action;

  const payload = {
    id,
    menu: [],
  };

  const meta = { receivedAt: new Date() };
  let savedState = {};
  if (isObject(widget)) {
    payload.widget = widget;
    if (isObject(widget.savedState)) {
      savedState = widget.savedState;
    }
  }

  Object.keys(chartData).forEach((key) => {
    payload.menu.push({ id: key, displayName: chartData[key].displayName });
  });

  const defaultConfig = yield select(getDefault);
  let contractName = defaultConfig.contractName;

  if (savedState.contractName) {
    contractName = savedState.contractName;
  }

  const chart = chartData[contractName];
  payload.contractName = contractName;

  const config = yield select(getConfig);

  if (!config[contractName]) {
    payload.config = { [contractName]: chart };
  }

  if (chart && chart.searchName) {
    const data = yield call(api.getPriceData, chart.searchName);
    payload.data = formatChartData(data.data, chart);
    payload.currency = CURRENCY_NOK;
    payload.lastModified = data.lastModified;
  }

  yield put(actionInitChart(INIT_CHART_SUCCESS, payload, meta));
}

function* destroyChart(action) {
  const payload = {
    id: action.payload.id,
  };

  const meta = { receivedAt: new Date() };

  yield put(actionDestroyChart(DESTROY_CHART_SUCCESS, payload, meta));
}

function* updateChart(action) {
  const {
    payload: { id, contractName, widget },
  } = action;

  const payload = {
    id,
  };

  const meta = { receivedAt: new Date() };

  payload.contractName = contractName;

  if (isObject(widget)) {
    const newWidget = {
      ...widget,
      savedState: { contractName: contractName },
    };

    yield put(
      workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
    );

    payload.widget = newWidget;
  }

  const chart = chartData[contractName];

  const config = yield select(getConfig);

  if (!config[contractName]) {
    payload.config = { [contractName]: chart };
  }

  if (chart && chart.searchName) {
    console.log('data', data);
    const data = yield call(api.getPriceData, chart.searchName);
    console.log('data1', data);
    payload.data = formatChartData(data.data, chart);
    payload.currency = CURRENCY_NOK;
    payload.lastModified = data.lastModified;
  }

  yield put(actionUpdateChart(UPDATE_CHART_SUCCESS, payload, meta));
}

function* initTable(action) {
  const {
    payload: { id, widget },
  } = action;

  const payload = {
    id,
    menu: [],
  };

  const meta = { receivedAt: new Date() };
  let savedState = {};
  if (isObject(widget)) {
    payload.widget = widget;
    if (isObject(widget.savedState)) {
      savedState = widget.savedState;
    }
  }

  /* const keys = Object.keys(chartData);
  for (let i = 0; i < keys.length; i++) {
    const chart = chartData[keys[i]];
    const data = yield call(api.getPriceData, chart.searchName);
    payload.chartData[keys[i]].data = data.data;
    payload.chartData[keys[i]].lastModified = data.lastModified;
  } */

  const chart = chartData.lfex;
  const data = yield call(api.getPriceData, chart.searchName);
  if (data) {
    const rows = formatTable(data.data);
    payload.rows = rows;
  }

  yield put(actionInitChart(INIT_TABLE_SUCCESS, payload, meta));
}
function* updateTable(action) {
  console.log('done Updateing');
  const {
    payload: { id, widget },
  } = action;

  const payload = {
    id,
    menu: [],
  };

  const meta = { receivedAt: new Date() };

  const chart = chartData.lfex;
  const data = yield call(api.getPriceData, chart.searchName);
  if (data) {
    const rows = formatTable(data.data);
    payload.rows = rows;
  }

  yield put(actionInitChart(UPDATE_TABLE_SUCCESS, payload, meta));
}

function* destroyTable(action) {
  const payload = {
    id: action.payload.id,
  };

  const meta = { receivedAt: new Date() };

  yield put(actionDestroyTable(DESTROY_TABLE_SUCCESS, payload, meta));
}

export function* watchInitChart() {
  yield takeEvery(INIT_CHART, initChart);
}

export function* watchDestroyChart() {
  yield takeEvery(DESTROY_CHART, destroyChart);
}

export function* watchUpdateChart() {
  yield takeEvery(UPDATE_CHART, updateChart);
}

export function* watchInitTable() {
  yield takeEvery(INIT_TABLE, initTable);
}

export function* watchDestroyTable() {
  yield takeEvery(DESTROY_TABLE, destroyTable);
}

export function* watchUpdateTable() {
  yield takeEvery(UPDATE_TABLE, updateTable);
}

function formatChartData(data, config) {
  const formatedData = [];
  data.forEach((d) => {
    const obj = {};

    if (config.xAxis === 'Week') {
      obj.week = `W${d.Week}-${d.Yr}`;
    } else if (config.xAxis === 'Month') {
      obj.week = d.Month;
    } else {
      obj.week = d.Date;
    }

    config.yAxis.forEach((y) => {
      obj[y] = d[y];
    });

    formatedData.push(obj);
  });

  return formatedData;
}

function formatTable(data) {
  let rows = [];
  const items = ['Index', '3-4KG', '4-5KG', '5-6KG'];
  const index = data.length - 1;
  if (index > -1) {
    const last = data[index];
    const previous = data[index - 1];
    rows = items.map((item) => {
      const lastItemValue = last[item];
      const previousItemValue = previous[item];
      const change = Number(lastItemValue - previousItemValue).toFixed(2);
      const percentChange = ((change / lastItemValue) * 100).toFixed(2) + '%';
      return {
        title: `LFEX ${item}`,
        price: lastItemValue,
        change: change,
        percentChange: percentChange,
      };
    });
  }
  return rows;
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
