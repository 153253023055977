// TODO: Analyse createAction from redux
export const create = (type, payload, meta) => {
  return {
    type,
    payload,
    meta,
  };
};

export const socket = (type, payload, meta) => {
  return {
    type,
    payload,
    meta,
  };
};
