import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import debounceRender from 'react-debounce-render';

import UserTab from './common/UserTab';
import ActiveMessaging from './ActiveMessaging';
import ChatTabs from './common/ChatTabs';
import ChatsList from './common/ChatsList';
import {
  getActiveTab,
  getSelectedChatId,
  getMappedFilteredChats,
  getNewMessagesCount,
  getChatGroups,
  getChatContacts,
} from '../selectors';
import {
  setActiveTab as setActiveTabAction,
  selectChat as selectChatAction,
  createChat as createChatAction,
 
} from '../actions';

import auth from '../../auth';
import users from '../../users';

const styles = () => ({
  container: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
  },
  userTabContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    borderRight: ' 1px solid',
    borderRightColor: 'rgba(255,255,255,.2)',
    minWidth: '300px',
    maxWidth: '360px',
  },

  activeContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
});

const getUserName = (userId, object) => {
  const user = object[userId];
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`;
};

const Chat = ({
  classes,
  activeTab,
  setActiveTab,
  selectedChatId,
  user,
  chats,
  usersObject,
  selectChat,
  createChat,
  newMessagesCount,
  chatGroups,
  chatContacts,
}) => {
  const roomInfo = {
    name: getUserName(user.id, usersObject),
    loggedIn: true,
    userId: user.id,
    organisationId: user.organisationId
  };

  // TODO: REFACTOR. USE NAME CONSTANTS. NOT INDEX!
  const getChatsView = () => {
    switch (activeTab) {
      case 0:
        return chats;
      case 1:
        return chatGroups;
      case 2:
        return chatContacts;
      default:
        return [];
    }
  };

  // TODO: REFACTOR!
  const onRoomClick = ({ id, name }) => {
    const tabs = ['recent', 'organisation', 'user' ];

    const mapIds = {
      organisation: 'organisationId',
      user: 'id',
    };

    const type = tabs[activeTab];

    const payload = {
      id,
      name,
      type: type.toUpperCase(),
      ownId: user[mapIds[type]],
    };

    selectChat(payload);

  };



  return (
    <div className={`${classes.container} undraggable`}>
      <div className={classes.userTabContainer}>
        <UserTab roomInfo={roomInfo} selected />
        <ChatTabs
          tabValue={activeTab}
          handleTabChange={(e, value) => {
            setActiveTab(value);
          }}
          groupsCount={newMessagesCount.groups}
          contactsCount={newMessagesCount.contacts}
          recentCount={newMessagesCount.recent}
        />
        <ChatsList
          rooms={getChatsView()}
          selectedChatId={selectedChatId}
          onRoomClick={onRoomClick}
        />
      </div>
      <div className={classes.activeContainer}>
        <ActiveMessaging selectedChatId={selectedChatId} />
      </div>
    </div>
  );
};

Chat.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  usersObject: PropTypes.object.isRequired,
  newMessagesCount: PropTypes.object.isRequired,
  chats: PropTypes.array.isRequired,
  selectedChatId: PropTypes.string,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  selectChat: PropTypes.func.isRequired,
  createChat: PropTypes.func.isRequired,
  chatGroups: PropTypes.array,
  chatContacts: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    theme: state.themes,
    activeTab: getActiveTab(state),
    selectedChatId: getSelectedChatId(state),
    user: auth.selectors.getServerUser(state),
    usersObject: users.selectors.getAllObject(state),
    chats: getMappedFilteredChats(state),
    newMessagesCount: getNewMessagesCount(state),
    chatGroups: getChatGroups(state),
    chatContacts: getChatContacts(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch(setActiveTabAction(payload));
    },
    selectChat: (payload) => {
      dispatch(selectChatAction(payload));
    },
    createChat: (payload) => {
      dispatch(createChatAction(payload));
    },
  };
};

const debouncedChat = debounceRender(withStyles(styles)(Chat), 200, { leading: false });

export default connect(mapStateToProps, mapDispatchToProps)(debouncedChat);
