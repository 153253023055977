import { fork, takeEvery, put } from 'redux-saga/effects';

import auth from '../../modules/auth';

import watchInitProccess from './client';
import {
  THEME_TYPES,
  INIT_THEME_SUCCESS,
  INIT_THEME,
  CHANGE_THEME_SUCCESS,
  CHANGE_THEME,
} from '../constants';
import { getFromLS, saveToLS } from '../../core/services/localStorage';

import { initTheme as actionInitTheme, changeTheme as actionChangeTheme } from '../actions';

export default function* root() {
  yield fork(auth.sagas.watchCheckAuth);
  yield fork(auth.sagas.watchSignIn);
  yield fork(auth.sagas.watchBackToSignIn);
  yield fork(auth.sagas.watchSignInNewPasswordSent);

  yield fork(watchInitTheme);
  yield fork(watchChangeTheme);

  yield fork(watchInitProccess);
}

// TODO: REFACTOR! MOVE INTO THEME SAGA
function* initTheme() {
  const payload = {
    type: getFromLS('theme') || THEME_TYPES.dark,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actionInitTheme(INIT_THEME_SUCCESS, payload, meta));
}

function* watchInitTheme() {
  yield takeEvery(INIT_THEME, initTheme);
}

function* changeTheme(action) {
  const { type } = action.payload;

  if (type) {
    saveToLS('theme', type);

    const payload = {
      type: action.payload.type,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionChangeTheme(CHANGE_THEME_SUCCESS, payload, meta));
  }
}

function* watchChangeTheme() {
  yield takeEvery(CHANGE_THEME, changeTheme);
}
