import React from 'react';

import { Button, Icon } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import amber from '@material-ui/core/colors/amber';
import blueGrey from '@material-ui/core/colors/blueGrey';
const styles = {
  button: {
    border: '1px solid',
    paddingRight: '8px',
    paddingLeft: '8px',
    margin: '4px',
    minWidth: '120px',
  },
  confirm: {
    color: teal[100],
    backgroundColor: teal[700],
    borderColor: teal[500],
    '&:hover': {
      backgroundColor: teal[200],
    },
  },
  cancel: {
    color: red[100],
    backgroundColor: red[700],
    borderColor: red[500],
    '&:hover': {
      backgroundColor: red[200],
    },
  },
  reset: {
    color: lightBlue[100],
    backgroundColor: lightBlue[700],
    borderColor: lightBlue[500],
    '&:hover': {
      backgroundColor: lightBlue[200],
    },
  },
  save: {
    color: amber[100],
    backgroundColor: amber[700],
    borderColor: amber[500],
    '&:hover': {
      backgroundColor: amber[200],
    },
  },

  clear: {
    color: blueGrey[300],
    backgroundColor: 'transparent',
    borderColor: blueGrey[500],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  iconConfirm: {
    marginRight: '16px',
    color: teal[300],
  },
  iconCancel: {
    marginRight: '16px',
    color: red[300],
  },
  iconReset: {
    marginRight: '16px',
    color: lightBlue[300],
  },
  iconSave: {
    marginRight: '16px',
    color: amber[300],
  },
  iconClear: {
    marginRight: '16px',
    color: blueGrey[300],
  },
};

export const ConfirmButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.confirm, ...props.style }} onClick={props.onClick}>
      <Icon style={{ ...styles.iconConfirm, ...props.iconStyle }}>
        {props.icon ? props.icon : 'check'}
      </Icon>
      {props.title ? props.title : 'SEND'}
    </Button>
  );
};

export const CancelButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.cancel, ...props.style }} onClick={props.onClick}>
      <Icon style={{ ...styles.iconCancel, ...props.iconStyle }}>
        {' '}
        {props.icon ? props.icon : 'clear'}
      </Icon>
      {props.title ? props.title : 'CANCEL'}
    </Button>
  );
};

export const ResetButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.reset, ...props.style }} onClick={props.onClick}>
      <Icon style={{ ...styles.iconReset, ...props.iconStyle }}>
        {props.icon ? props.icon : 'replay'}
      </Icon>
      {props.title ? props.title : 'RESET'}
    </Button>
  );
};

export const SaveButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.save, ...props.style }} onClick={props.onClick}>
      <Icon style={{ ...styles.iconSave, ...props.iconStyle }}>
        {props.icon ? props.icon : 'save'}
      </Icon>
      {props.title ? props.title : 'SAVE'}
    </Button>
  );
};

export const ClearButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.clear, ...props.style }} onClick={props.onClick}>
      <Icon style={{ ...styles.iconClear, ...props.iconStyle }}>
        {props.icon ? props.icon : 'archive'}
      </Icon>
      {props.title ? props.title : 'PRESET'}
    </Button>
  );
};
