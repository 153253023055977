import fetch from 'cross-fetch';

import config from './config';

import HTTPError from './HTTPError';
import { isObject } from '../../utils/functions';

class Api {
  constructor() {
    this.defaultOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: null,
    };
  }

  create(url, params = {}, options) {
    const requestOptions = {};

    if (isObject(options) && Object.keys(options).length > 0) {
      requestOptions.method = options.method || this.defaultOptions.method;

      if (!options.notUseHeaders) {
        requestOptions.headers = { ...this.defaultOptions.headers, ...options.headers };
      }
      if (options.noContentHeader) {
        requestOptions.headers = { ...options.headers };
      }

      requestOptions.credentials = options.credentials || this.defaultOptions.credentials;
      requestOptions.body = options.body || this.defaultOptions.body;
    }

    let requestParams = String(new URLSearchParams(params));
    requestParams = requestParams ? `?${requestParams}` : '';

    const requestUrl = url.startsWith('http') ? url : `${config.base_path}${url}${requestParams}`;
    const request = fetch(requestUrl, requestOptions);

    return request;
  }

  async getResponseContent(url, params, options) {
    const response = await this.create(url, params, options);
    console.log('response', response);
    if (response.ok) {
      const result = await response.blob();

      return result;
    }

    throw new HTTPError(response.status, response.statusText);
  }
  async getResponse(url, params, options) {
    const response = await this.create(url, params, options);

    if (response.ok) {
      const result = await response.json();
      return result;
    }

    throw new HTTPError(response.status, response.statusText);
  }
  async getResponseDelete(url, params, options) {
    const response = await this.create(url, params, options);

    if (response.ok) {
      const result = await response;
      return result;
    }

    throw new HTTPError(response.status, response.statusText);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
