import { fieldMap, fields } from '../config';

export const createPostTradeFormData = (trade, formType) => {
  const data = trade[`${formType}Data`];

  const nextData = {};
  if (data) {
    Object.keys(data).forEach((d) => {
      if (d !== 'files') {
        nextData[d] = data[d];
      } else {
        const files = data.files || [];
        files.forEach((file) => {
          const fileType = fieldMap[file.type];
          nextData[fileType] = file;
        });
      }
    });
  } else {
    const formFields = fields[formType];

    Object.keys(formFields).forEach((field) => {
      nextData[field] = formFields[field].defaultValue;
    });
  }
  return nextData;
};
