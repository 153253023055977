import {
    GET_ADMINS_SUCCESS,
    GET_ADMINS_ERROR,
    GET_ADMIN_SUCCESS,
    GET_ADMIN_ERROR,
    GET_ADMINS,
    RESET_ADMINS,
    GET_ADMIN,

    INIT_ADMIN_SUCCESS,
    DESTROY_ADMIN_SUCCESS,
    UPDATE_ADMIN_SUCCESS,

    CREATE_ADMIN_ITEM_SUCCESS,
    UPDATE_ADMIN_ITEM_SUCCESS
 
  } from '../constants';
  
  import { insertItems, updateItems } from '../../../core/utils/functions';
  
  import auth from '../../auth';
  
  const initialState = {
    byId: [],
    allIds: [],
    items: {},
    organisations:{},
    users:{},
    status: null,
    error: null,
    receivedAt: null,
    lastCreatedTime: null,
    lastUpdatedTime: null,
  };
  
  const reducer = (state = initialState, action) => {
    let nextState = null;
  
    switch (action.type) {
      case INIT_ADMIN_SUCCESS:
        nextState = {
          ...state,
        };
  
        const { id: idInitAdmin, ...otherNextAdminProps } = action.payload;
  
        if (typeof idInitAdmin !== 'undefined') {
          if (!nextState.items[idInitAdmin]) {
            nextState.items = {
              ...nextState.items,
              [idInitAdmin]: {
                id: idInitAdmin,
                ...otherNextAdminProps,
              },
            };
          }
        }
        return nextState;
  
      case DESTROY_ADMIN_SUCCESS:
        nextState = {
          ...state,
        };
  
        const { id: idDestroyAdmin } = action.payload;
  
        if (typeof idDestroyAdmin !== 'undefined') {
          if (nextState.items[idDestroyAdmin]) {
            nextState.items = {
              ...nextState.items,
            };
  
            delete nextState.items[idDestroyAdmin];
          }
        }
        return nextState;
  
      case UPDATE_ADMIN_SUCCESS:
        nextState = {
          ...state,
        };
  
        const { id: idUpdateAdmin, ...otherNextUpdateAdminProps } = action.payload;
  
        if (typeof idUpdateAdmin !== 'undefined') {
          if (nextState.items[idUpdateAdmin]) {
            nextState.items = {
              ...nextState.items,
              [idUpdateAdmin]: {
                ...nextState.items[idUpdateAdmin],
                ...otherNextUpdateAdminProps,
              },
            };
  
            if (typeof action.payload.filters !== 'undefined') {
              nextState.items[idUpdateAdmin].filters = {
                ...state.items[idUpdateAdmin].filters,
                ...action.payload.filters,
              };
            }
          }
        }
        return nextState;
  
      case GET_ADMINS:
        return {
          ...state,
          status: 'pending',
        };
      case GET_ADMINS_SUCCESS:
        const organisations = action.payload.organisations
        const users = action.payload.users
        return {
          ...state,
          organisations,
          users,
          status: 'success',
          error: null,
          receivedAt: action.meta.receivedAt,
        };
      case GET_ADMINS_ERROR:
        return {
          ...state,
          status: 'error',
          error: action.payload,
        };
  
      case GET_ADMIN:
        return {
          ...state,
          status: 'pending',
        };
      case GET_ADMIN_SUCCESS:
        return {
          ...state,
          ...insertItems(action.payload, state, 'baseCurrency'),
          status: 'success',
          error: null,
          receivedAt: action.meta.receivedAt,
        };
      case GET_ADMIN_ERROR:
        return {
          ...state,
          status: 'error',
          error: action.payload,
        };
  
   
  
      case RESET_ADMINS:
      case auth.constants.SIGN_OUT_SUCCESS:
        return initialState;
      case CREATE_ADMIN_ITEM_SUCCESS:
      case UPDATE_ADMIN_ITEM_SUCCESS:
        const type = action.payload.type
        const item = action.payload.response
        const items = {...state[type]}
        const id = item.id
        items[id]= item
        
        return {
          ...state,
          [type]:items
        }
      default: {
        return state;
      }
    }
  };
  
  export default reducer;