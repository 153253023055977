import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../commons/components/formFields/SelectField';
import UserRow from './UserRow'

const styles = (theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    listItem: {
        flex: 1
    }
});

class Users extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            organisation:"ANY"
        }
    }
    getUsers=()=>{
        if(this.state.organisation === "ANY"){
            return this.props.users
        }else{
            const organisationName = this.state.organisation
            let foundOrgId =""
            Object.keys(this.props.organisationsObject).forEach(id=>{
                if(this.props.organisationsObject[id].name===organisationName){
                    foundOrgId = this.props.organisationsObject[id].id
                }
            })
            const users = this.props.users.filter(user=>user.organisationId === foundOrgId)
            return users
        }
    }

    render() {
        const { classes, organisationsObject } = this.props;
        const users = this.getUsers()
        const organisations = Object.keys(this.props.organisationsObject).map(id=>this.props.organisationsObject[id].name)
        organisations.sort((a, b) => {
            const textA = a.toUpperCase();
            const textB = b.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        organisations.unshift("ANY")
        return (
            <React.Fragment>
                  <SelectField
                                accessor="organisation"
                                displayName="Organisation"
                                value={this.state.organisation || ''}
                                values={organisations}
                                handleChange={(name) => (e) => {
                                    this.setState({ [name]: e.target.value });
                                }}
                                fullWidth
                            />
                {users.map(user => {
                    return (
                        <UserRow user={user} organisationsObject={organisationsObject} key={user.id} editUser={this.props.editUser}/>
                    )
                })}
            </React.Fragment>
        );
    }
}

Users.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(Users);