import { create } from '../../../core/actions';

export const initCalculators = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const initCalculator = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const destroyCalculator = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const initCalculatorsSettings = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateCalculators = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const resetCalculators = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateCalculator = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const calculatorManager = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const changeStateCalculators = (type, payload, meta) => {
  return create(type, payload, meta);
};
