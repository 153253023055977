import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CssBaseline } from '@material-ui/core';

import { HOME_PAGE_URL } from '../configs/constants';

import HomePage from '../commons/components/HomePage';
import LoginPage from '../commons/components/LoginPage';
import ClientPage from '../commons/components/ClientPage';

import auth from '../modules/auth';

import history from '../core/router/history';

// TODO:Move to container or module or config
const HomePageWithAuth = auth.components.withAuth(HomePage);
const showHomePage = {
  whenAuthed: false,
};
const homeRedirect = {
  whenAuthed: true,
  url: auth.constants.CLIENT_PAGE_URL,
};

const LoginPageWithAuth = auth.components.withAuth(LoginPage);
const showLoginPage = {
  whenAuthed: false,
};
const loginRedirect = {
  whenAuthed: true,
  url: auth.constants.CLIENT_PAGE_URL,
};

const ClientPageWithAuth = auth.components.withAuth(ClientPage);

const clientRedirect = {
  whenAuthed: false,
  url: auth.constants.LOGIN_PAGE_URL,
};

// TODO: REFACTOR MuiThemeProvider. Move in store and use select. Вestruction is not a good solution when changing the theme
class App extends React.PureComponent {
  render() {
    const theme = createMuiTheme(this.props.appTheme.theme);
    console.log('theme', theme);
    return (
      <Router history={history}>
        <Switch>
          <DndProvider backend={HTML5Backend}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />

              <Route
                path={HOME_PAGE_URL}
                exact
                component={() => <HomePageWithAuth redirect={homeRedirect} show={showHomePage} />}
              />
              <Route
                path={auth.constants.LOGIN_PAGE_URL}
                exact
                component={() => (
                  <LoginPageWithAuth redirect={loginRedirect} show={showLoginPage} />
                )}
              />
              <Route
                path={auth.constants.CLIENT_PAGE_URL}
                exact
                component={() => <ClientPageWithAuth redirect={clientRedirect} />}
              />
            </MuiThemeProvider>
          </DndProvider>
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    appTheme: state.themes,
  };
}

export default connect(mapStateToProps)(App);
