import { create } from '../../../core/actions';

export const initMarket = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const destroyMarket = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateMarket = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getMarket = (type, payload, meta) => {
  return create(type, payload, meta);
};
