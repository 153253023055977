import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import red from '@material-ui/core/colors/red';
import {
  FormControl,
  MenuItem,
  InputLabel,
  Input,
  Chip,
  FormHelperText,
  Select,
} from '@material-ui/core';
import { textField } from './formFieldStyles';
import { isObject } from '../../../core/utils/functions';
const styles = (theme) => ({
  textField: textField(theme),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    // margin: "4px",
    // padding: "2px",
    // height: "auto"
  },
});

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MAX_ITEMS = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * MAX_ITEMS + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectFieldOrgs = (props) => {
  const { classes, accessor, displayName, value, values, errorMessage, errorValue } = props;
  let object = value;
  if (value.length > 0) {
    if (isObject(value[0])) {
      if (value[0].hasOwnProperty('id')) {
        object = values.filter((org) => value.some((val) => val.id === org.id));
      }
    }
  }
  return (
    <FormControl className={classes.textField} fullWidth={props.fullWidth}>
      <InputLabel htmlFor={accessor}>{displayName}</InputLabel>
      <Select
        multiple
        value={object}
        onChange={props.handleChange(accessor)}
        input={<Input id={accessor} />}
        fullWidth={props.fullWidth}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {value.map((val) => (
              <Chip key={val.id} label={val.shortName} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        error={errorValue || false}
      >
        {values.map((option) => (
          <MenuItem key={option.id} value={option}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>{option.shortName}</div>
              <div>{option.name}</div>
            </div>
          </MenuItem>
        ))}
      </Select>

      <FormHelperText style={{ color: red[500] }}>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

MultipleSelectFieldOrgs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultipleSelectFieldOrgs);
