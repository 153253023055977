import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import PopUp from '../../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import {
  isOpenAmendOrderForm,
  getOrderFromAmendOrderForm,
  getErrorsFromAmendOrderForm,
} from '../../selectors';

import { updateForm } from '../../actions';
import { UPDATE_AMEND_ORDER_FORM, SEND_ORDER_AMENDMENT } from '../../constants';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    color: theme.palette.text.primary,
  },
  title: { fontSize: theme.fontSize * 1.5 },
  container: { backgroundColor: 'rgba(0,0,0,.1)' },
  containerItems: { textAlign: 'center', padding: '10px' },
  banner: {
    backgroundColor: theme.palette.background.header,
    padding: theme.spacing.unit,
    color: theme.palette.text.hint,
  },
  textField: { marginTop: '8px', marginBottom: '8px' },
});

class AmendOrderForm extends React.PureComponent {
  handleChange = (name) => (event) => {
    const payload = {
      action: 'updateOrder',
      fieldName: name,
      value: event.target.value,
    };

    this.props.updateFrom(payload);
  };

  amendOrder = () => {
    const payload = {};
    this.props.amendOrder(payload);
  };

  closeForm = () => {
    const payload = {
      action: 'closeForm',
    };

    this.props.updateFrom(payload);
  };

  render() {
    const { classes, open, order, errors } = this.props;
    let { volume: errorVolume, price: errorPrice } = errors;

    const defaultError = { volue: null, message: '' };

    errorVolume = errorVolume || defaultError;
    errorPrice = errorPrice || defaultError;

    return (
      <PopUp open={open} title="AMEND ORDER" containerWidth="400px">
        <div>
          <div className={classes.container}>
            <Typography className={classes.banner}>ORDER</Typography>
            <div className={classes.containerItems}>
              <TextField
                id="volume"
                type="number"
                label="Volume"
                classes={{ root: classes.textField }}
                className={classes.textField}
                value={order.volume}
                onChange={this.handleChange('volume')}
                margin="normal"
                error={errorVolume.value}
                helperText={errorVolume.message}
              />

              <TextField
                id="price"
                type="number"
                label="Price"
                className={classes.textField}
                value={order.price}
                onChange={this.handleChange('price')}
                inputProps={{ step: 0.1 }}
                margin="normal"
                error={errorPrice.value}
                helperText={errorPrice.message}
              />
            </div>
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeForm} />
              <ConfirmButton onClick={this.amendOrder} />
            </>
          </ToolbarBottom>
        </div>
      </PopUp>
    );
  }
}
AmendOrderForm.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

function mapStateToProps(state) {
  return {
    appTheme: state.themes,
    open: isOpenAmendOrderForm(state), // state.client.amendOrderFormOpen,
    order: getOrderFromAmendOrderForm(state),
    errors: getErrorsFromAmendOrderForm(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFrom: (payload) => {
      dispatch(updateForm(UPDATE_AMEND_ORDER_FORM, payload));
    },
    amendOrder: (payload) => {
      dispatch(updateForm(SEND_ORDER_AMENDMENT, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AmendOrderForm));
