import { create } from '../../../core/actions';

export const initCentralLimitOrderBook = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const destroyCentralLimitOrderBook = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateCentralLimitOrderBook = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getCentralLimitOrderBook = (type, payload, meta) => {
  return create(type, payload, meta);
};
