export const NAME = 'organisations';

export const GET_ORGANISATIONS = 'organisations/get';
export const GET_ORGANISATIONS_SUCCESS = 'organisations/get/success';
export const GET_ORGANISATIONS_ERROR = 'organisations/get/error';

export const GET_ORGANISATION = 'organisation/get';
export const GET_ORGANISATION_SUCCESS = 'organisation/get/success';
export const GET_ORGANISATION_ERROR = 'organisation/get/error';

export const RESET_ORGANISATIONS = 'organisations/reset';
export const RESET_ORGANISATIONS_SUCCESS = 'organisations/reset/success';
export const RESET_ORGANISATIONS_ERROR = 'organisations/reset/error';
