import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip, IconButton, Icon } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import CollapseContainerAlt from '../../../commons/components/collapseContainerAlt';
import constants from '../constants';
import { createFile as actionCreateFile, getFiles as actionGetFiles } from '../actions';
import { getTradingPartners, getOrganisationsObject } from '../selectors';
import { visibilities } from '../../../commons/models/constants';
import Dropzone from './Dropzone';
import { getAllMyFiles, getAllSharedFiles, getFileContent } from '../selectors';
import moment from 'moment';
import { formatDate, formatDateTime } from '../../../commons/config/formatters';
import FileViewer from './FileViewer';
import { ResetButton } from '../../../commons/components/buttons';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(),
    overflowY: 'auto',
  },
  noFilesToShow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  fileRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  fileRowItem: {
    flex: 1,
    padding: theme.spacing(0.5),
  },
  icon: {
    padding: theme.spacing(0.5),
  },
  content: {
    flex: 1,
    overflowY: 'scroll',
  },
  footer: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
const documentTypes = [
  'INVOICE',
  'PACKAGING_LIST',
  'PRODUCT_SPECIFICATION',
  'FISH_CV',
  'ORDER_SPECIFICATION',
];

const FileManager = (props) => {
  const { classes, tradingPartners, organisationsObject } = props;
  const [filesRetrieved, setFilesRetrieved] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [fileIdToShow, setFileIdToShow] = useState(null);
  const [fileToShow, setFileToShow] = useState(null);
  useEffect(() => {
    if (!filesRetrieved) {
      props.getFiles();
      setFilesRetrieved(true);
    }

    if (fileIdToShow !== null && fileToShow === null) {
      const files = [...props.myFiles, ...props.sharedFiles];
      const file = files.filter((f) => f.id === fileIdToShow)[0];

      if (file) {
        if (file.preview) {
          const newFile = Object.assign(file);
          setFileToShow(newFile);
        }
      }
    }
  });
  const closeFileViewer = () => {
    setShowFile(false);
    setFileIdToShow(null);
    setFileToShow(null);
  };
  const openFileViewer = (payload) => {
    props.getFileContent(payload);
    setFileIdToShow(payload.id);
    setShowFile(true);
  };
  const FileRow = (rowProps) => {
    const { file } = rowProps;
    const orgId = file.organisationId;
    const org = organisationsObject[orgId] || {};
    const fileDate = formatDateTime(file.updatedTime);
    return (
      <div className={classes.fileRowContainer}>
        <div className={classes.fileRowItem}>{org.shortName}</div>
        <div className={classes.fileRowItem}>{file.type}</div>
        <div className={classes.fileRowItem}>{file.filename}</div>
        <div className={classes.fileRowItem}>{fileDate}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            cursor: 'pointer',
          }}
        >
          <IconButton onClick={() => openFileViewer({ id: file.id })} aria-label="Visibility">
            <Icon>visibility</Icon>
          </IconButton>
          {rowProps.myRow && (
            <IconButton onClick={() => props.deleteFile({ id: file.id })} aria-label="Delete">
              <Icon>delete</Icon>
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <CollapseContainerAlt title={'UPLOAD FILES'}>
          <Dropzone></Dropzone>
        </CollapseContainerAlt>{' '}
        <CollapseContainerAlt title={'MY FILES'}>
          {props.myFiles.length === 0 ? (
            <div className={classes.noFilesToShow}> No Files To Show</div>
          ) : (
            <div>
              {props.myFiles.map((file) => {
                return <FileRow file={file} key={file.id} myRow />;
              })}
            </div>
          )}
        </CollapseContainerAlt>{' '}
        <CollapseContainerAlt title={'FILES SHARED WITH ME'}>
          {props.sharedFiles.length === 0 ? (
            <div className={classes.noFilesToShow}> No Files To Show</div>
          ) : (
            <div>
              {props.sharedFiles.map((file) => {
                return <FileRow file={file} key={file.id} />;
              })}
            </div>
          )}
        </CollapseContainerAlt>
      </div>

      <div className={classes.footer}>
        <ResetButton title="Refresh" onClick={props.getFiles} />
      </div>
      <FileViewer file={fileToShow} open={showFile} onClose={closeFileViewer} />
    </div>
  );
};

FileManager.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    tradingPartners: getTradingPartners(state),
    myFiles: getAllMyFiles(state),
    sharedFiles: getAllSharedFiles(state),
    organisationsObject: getOrganisationsObject(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createFile: (payload) => {
      dispatch(actionCreateFile(constants.CREATE_FILE, payload));
    },
    getFiles: (payload) => {
      dispatch(actionGetFiles(constants.GET_FILES, payload));
    },
    getFileContent: (payload) => {
      dispatch(actionGetFiles(constants.GET_FILE_CONTENT, payload));
    },
    deleteFile: (payload) => {
      dispatch(actionGetFiles(constants.DELETE_FILE, payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FileManager));
