import { create } from '../../../core/actions';

export const tradingPartnersListOpen = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const notificationSettingsOpen = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const generalSettingsOpen = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const generalSettingsUpdate = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getNotificationTypes = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const blotterPresetsOpen = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const blotterPresetsUpdate = (type, payload, meta) => {
  return create(type, payload, meta);
};
