import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, IconButton, Icon } from '@material-ui/core';

import OrderCell from './OrderCell';

const styles = (theme) => ({
  tableRow: {
    backgroundColor: theme.palette.background.row,
    height: '48px',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.tableBorder,
  },
});

const OrderRow = (props) => {
  const { classes, headers } = props;

  return (
    <TableRow classes={{ root: classes.tableRow }}>
      {headers.map((header) => {
        if (header.key === 'remove') {
          return (
            <TableCell className={props.classes.tableCell} key={`${header.key} ${props.order.id}`}>
              <IconButton onClick={() => props.handleRowDel(props.order)}>
                <Icon>delete</Icon>
              </IconButton>
            </TableCell>
          );
        }
        return (
          <OrderCell
            onProductTableUpdate={props.onProductTableUpdate}
            key={`${header.key} ${props.order.id}`}
            cellData={{
              value: props.order[header.key],
              name: header.key,
              id: props.order.id,
              editing: false,
            }}
            organisations={props.organisations}
            activeCell={props.activeCell}
            textInput={props.textInput}
            order={props.order}
            localCurrency={props.localCurrency}
            finalCurrency={props.finalCurrency}
            contract={props.contract}
            tradingPartners={props.tradingPartners}
          />
        );
      })}
    </TableRow>
  );
};

OrderRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderRow);
