import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Input, FormControl } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import FileRow from './FileRow';
import FileViewer from './FileViewer';
import SelectField from '../../../commons/components/formFields/SelectField';
import TextInputField from '../../../commons/components/formFields/TextInputField';
import MultipleSelectFieldOrgs from '../../../commons/components/formFields/multipleSelectFieldOrgs';
import { ResetButton } from '../../../commons/components/buttons';
import constants from '../constants';
import { createFile as actionCreateFile } from '../actions';
import { getTradingPartners } from '../selectors';
import { visibilities } from '../../../commons/models/constants';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(),
  },
  dropContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    color: theme.palette.text.opacity,
    padding: theme.spacing(),
    borderRadius: '8px',
    backgroundColor: theme.palette.background.row,
    height: '190px',
    maxHeight: '190px',
  },

  fileInput: {
    opacity: 0,
    position: 'absolute',
    ZIndex: -1,
  },
  uploadIcon: {
    fontSize: '48px',
  },
  browse: {
    padding: theme.spacing(),
    border: `1px solid ${lightBlue[300]}`,
    color: lightBlue[300],
    '&:hover': {
      transition: 'all .5s ease',
      backgroundColor: lightBlue[500],
      color: 'white',
    },
    cursor: 'pointer',
  },
  itemContainer: {
    padding: theme.spacing(0.5),
  },
  uploadedFiles: {
    padding: theme.spacing(),
    flex: 1,
    overflowY: 'scroll',
  },
  buttonRow: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
const documentTypes = [
  'INVOICE',
  'PACKAGING_LIST',
  'PRODUCT_SPECIFICATION',
  'FISH_CV',
  'ORDER_SPECIFICATION',
];
const Dropzone = (props) => {
  const { classes } = props;
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [showFile, setShowFile] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [visibility, setVisibility] = useState(visibilities.TRADING_PARTNERS);
  const [organisations, setOrganisations] = useState([]);
  const [errorOrganisations, setErrorOrganisations] = useState({ message: '', value: false });
  const [documentType, setDocumentType] = useState('INVOICE');

  useEffect(() => {
    if (props.sendTo && organisations.length === 0) {
      setVisibility(visibilities.SELECTED_ORGANISATIONS);
      const org = props.tradingPartners.filter((tp) => tp.id === props.sendTo)[0];
      if (org) {
        setOrganisations([org]);
      }
    }
  });
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*, .pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      const nextFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            documentType: documentType,
            filename: file.name,
          }),
        ),
      ];
      setFiles(nextFiles);
      setDragging(false);
    },
    onDragEnter: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
  });
  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };
  const handleShowFile = (file) => {
    setFile(file);
    setShowFile(true);
  };

  const handleDocumentType = (name) => (event) => {
    setDocumentType(event.target.value);
  };
  const handleVisibilityChange = (name) => (event) => {
    setVisibility(event.target.value);
  };
  const handleOrganisationsChange = (name) => (event) => {
    setOrganisations(event.target.value);
  };
  const handleDocumentNameChange = (name) => (event) => {
    const newFiles = [...files];
    newFiles[name].documentName = event.target.value;
    setFiles(newFiles);
  };
  const createFiles = () => {
    files.forEach((file) => {
      const payload = { file, visibility, organisations };
      props.createFile(payload);
    });

    setFiles([]);
  };
  const images = files.map((file) => (
    <div key={file.name}>
      <div>
        <p>{file.name}</p>
        <img src={file.preview} style={{ width: '200px' }} alt="" />
      </div>
    </div>
  ));

  return (
    <div className={`${classes.container} undraggable`}>
      <div className={classes.buttonRow}>
        <SelectField
          accessor="documentType"
          displayName="Document Type"
          value={documentType}
          values={documentTypes}
          handleChange={handleDocumentType}
          fullWidth
        ></SelectField>
        <SelectField
          accessor="visibility"
          displayName="Visibility"
          value={visibility}
          values={visibilities}
          handleChange={handleVisibilityChange}
          fullWidth
        />

        {visibility === 'SELECTED_ORGANISATIONS' && (
          <MultipleSelectFieldOrgs
            key="selectedOrganisationsIds"
            accessor="selectedOrganisationsIds"
            displayName="Visible To:"
            value={organisations}
            values={props.tradingPartners}
            handleChange={handleOrganisationsChange}
            errorMessage={errorOrganisations.message}
            errorValue={errorOrganisations.value}
            fullWidth
          />
        )}
      </div>

      <div
        className={classes.dropContainer}
        {...getRootProps()}
        style={dragging ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <div className={classes.itemContainer}>
          <div className={`material-icons ${classes.uploadIcon}`}>get_app</div>
        </div>
        <div className={classes.itemContainer}>
          <div>Drag and Drop files</div>
        </div>

        <input style={{ flex: 1 }} {...getInputProps()} id="upload-file"></input>

        <div className={classes.itemContainer}>
          <div>or</div>
        </div>
        <div className={classes.itemContainer}>
          <div type="button" onClick={open} className={classes.browse}>
            Browse Files
          </div>
        </div>
      </div>

      <div className={classes.uploadedFiles}>
        {files.map((file, index) => (
          <FileRow
            file={file}
            index={index}
            key={file.name}
            removeFile={() => removeFile(index)}
            showFile={() => handleShowFile(file)}
            handleDocumentNameChange={handleDocumentNameChange}
          />
        ))}
      </div>
      <div className={classes.footer}>
        <ResetButton title="UPLOAD" icon="publish" onClick={createFiles} />
      </div>
      <FileViewer file={file} open={showFile} onClose={() => setShowFile(false)} preview />
    </div>
  );
};

Dropzone.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    tradingPartners: getTradingPartners(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createFile: (payload) => {
      dispatch(actionCreateFile(constants.CREATE_FILE, payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dropzone));
