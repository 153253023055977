import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';

const styles = (theme) => ({
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },

  columnName: {
    padding: theme.spacing(),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  columnRate: {
    padding: theme.spacing(),
    color: teal[300],
    fontSize: '16px',
  },
});
const ColumnPickerRow = (props) => {
  const { classes, onClick, label, checked } = props;
  return (
    <div className={classes.checkRow}>
      <Checkbox classes={{ root: classes.iconButton }} checked={checked} onChange={onClick} />
      <div className={classes.row}>
        <div className={classes.columnName}>{label}</div>
        <div className={classes.columnRate}></div>
      </div>
    </div>
  );
};

ColumnPickerRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ColumnPickerRow);
