import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Modal, Typography, InputBase } from '@material-ui/core';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import { isOpenFormResponse } from '../../selectors';
import { createAdminItem, updateAdminItem } from '../../actions';
import { CREATE_ADMIN_ITEM, UPDATE_ADMIN_ITEM } from '../../constants';
import SelectField from '../../../../commons/components/formFields/SelectField';
import TextInputField from '../../../../commons/components/formFields/TextInputField';

import orders from '../../../orders';

const ERROR_RECIPIENT = 'At least one organisation must be selected.';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DEFAULT_PRICE = 5.6;

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '80vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },

  container: { marginTop: '10px' },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.text.disabled,
  },
  title: { fontSize: theme.fontSize * 1.5 },

  iconButton: {
    paddingLeft: '0px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },

  checkboxLabel: {
    marginLeft: 0,
    maxWidth: '200px',
  },
});

class AddUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      organisation: '',
      errors: {},
      firstNameError: { value: false, message: '' },
      lastNameError: { value: false, message: '' },
      emailError: { value: false, message: '' },
      phoneNumberError: { value: false, message: '' },
      organisationError: { value: false, message: '' },
      selectedOrganisations: [],
    };
  }

  componentDidMount() {
    if (this.props.organisations) {
      const selectedOrganisations = Object.keys(this.props.organisations).map(
        (id) => this.props.organisations[id].name,
      );
      selectedOrganisations.sort((a, b) => {
        const textA = a.toUpperCase();
        const textB = b.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.setState({ selectedOrganisations });
    }
  }

  componentDidUpdate(prevProps) {}

  handleChange = (name) => (event) => {
    const value = event.target.value;
    let error = { value: false, message: '' };
    if (name !== 'phoneNumber') {
      if (value === '' || value === null || value === undefined) {
        error = { value: true, message: 'Value is required' };
      }
    } else {
      let reg = new RegExp(/\+\d{10}/);

      if (value !== '') {
        if (!reg.test(value)) {
          error = { value: true, message: "Enter International Number Starting with '+" };
        }
      }
    }
    this.setState({ [name]: value, [`${name}Error`]: error });
  };
  getErrors = () => {
    let obj = {
      hasErrors: false,
    };
    const fieldsToCheck = ['firstName', 'lastName', 'email', 'phoneNumber', 'organisation'];
    fieldsToCheck.forEach((f) => {
      if (f !== 'phoneNumber') {
        obj[`${f}Error`] = { value: false, message: '' };
        if (this.state[f] === '' || this.state[f] === null || this.state[f] === undefined) {
          obj[`${f}Error`] = { value: true, message: 'Value is required' };
          obj.hasErrors = true;
        }
      } else {
        if (this.state.phoneNumberError.value === true) {
          obj.hasErrors = true;
          obj[`${f}Error`] = this.state.phoneNumberError;
        }
      }
    });
    return obj;
  };
  getOrganisationId = () => {
    let orgId = '';
    const name = this.state.organisation;
    Object.keys(this.props.organisations).forEach((id) => {
      if (this.props.organisations[id].name === name) {
        orgId = id;
      }
    });
    return orgId;
  };
  send = () => {
    const errors = this.getErrors();
    if (!errors.hasErrors) {
      const firstName = this.state.firstName;
      const lastName = this.state.lastName;
      const email = this.state.email;
      const phoneNumber = this.state.phoneNumber;
      const organisationId = this.getOrganisationId(this.state.organisation);

      const payload = {
        type: 'users',
        body: {
          firstName,
          lastName,
          email,
          phoneNumber,
          organisationId,
        },
      };
      this.props.create(payload);
      this.props.closeForm();
    }
    this.setState({
      firstNameError: errors.firstNameError,
      lastNameError: errors.lastNameError,
      emailError: errors.emailError,
      phoneNumberError: errors.phoneNumberError,
      organisationError: errors.organisationError,
    });
  };

  render() {
    const { classes, open } = this.props;
    return (
      <div className={`undraggable`}>
        <Modal open={open} onClose={this.props.closeForm}>
          <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
            <Typography className={classes.title}>ADD USER</Typography>
            <div className={classes.container}>
              <TextInputField
                accessor="firstName"
                displayName="First Name"
                value={this.state.firstName}
                handleChange={this.handleChange}
                error={this.state.firstNameError}
                fullWidth
              />
              <TextInputField
                accessor="lastName"
                displayName="Last Name"
                value={this.state.lastName}
                handleChange={this.handleChange}
                error={this.state.lastNameError}
                fullWidth
              />
              <TextInputField
                accessor="email"
                displayName="Email"
                value={this.state.email}
                handleChange={this.handleChange}
                error={this.state.emailError}
                fullWidth
              />
              <TextInputField
                accessor="phoneNumber"
                displayName="Phone Number"
                value={this.state.phoneNumber}
                handleChange={this.handleChange}
                error={this.state.phoneNumberError}
                fullWidth
              />
              <SelectField
                accessor="organisation"
                displayName="Organisation"
                value={this.state.organisation || ''}
                values={this.state.selectedOrganisations}
                handleChange={(name) => (e) => {
                  this.setState({
                    [name]: e.target.value,
                    organisationError: { value: false, message: '' },
                  });
                }}
                error={this.state.organisationError}
                fullWidth
              />
            </div>

            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.closeForm} />
                <ConfirmButton onClick={this.send} />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
      </div>
    );
  }
}

AddUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    update: (payload) => {
      dispatch(updateAdminItem(UPDATE_ADMIN_ITEM, payload));
    },
    create: (payload) => {
      dispatch(createAdminItem(CREATE_ADMIN_ITEM, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddUser));
