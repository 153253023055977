import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import teal from "@material-ui/core/colors/teal";

const styles = (theme) => ({

  progressContainer:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    height: "100vh"
  },
  circularProgress: {
    color: teal[500],
    margin: theme.spacing.unit * 2
  },
});

const Progress = ({classes}) => {
  return (
    <div className={classes.progressContainer}>
        <CircularProgress className={classes.circularProgress}/>
    </div> 
  );
};

Progress.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles(styles)(Progress);
