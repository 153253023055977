import {
  GET_FXRATES_SUCCESS,
  GET_FXRATES_ERROR,
  GET_FXRATE_SUCCESS,
  GET_FXRATE_ERROR,
  GET_FXRATES,
  RESET_FXRATES,
  GET_FXRATE,
  INSERT_FXRATES_FROM_SOCKET,
  INSERT_FXRATES_FROM_SOCKET_SUCCESS,
  INSERT_FXRATES_FROM_SOCKET_ERROR,
  INIT_FXRATE_SUCCESS,
  DESTROY_FXRATE_SUCCESS,
  UPDATE_FXRATE_SUCCESS,
  UPDATE_FXRATES_FROM_SOCKET,
  UPDATE_FXRATES_FROM_SOCKET_SUCCESS,
  UPDATE_FXRATES_FROM_SOCKET_ERROR,
} from '../constants';

import { insertItems, updateItems } from '../../../core/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: [],
  allIds: [],
  items: {},
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case INIT_FXRATE_SUCCESS:
      nextState = {
        ...state,
      };
      const { id: idInitFxrate, ...otherNextFxrateProps } = action.payload;

      if (typeof idInitFxrate !== 'undefined') {
        if (!nextState.items[idInitFxrate]) {
          nextState.items = {
            ...nextState.items,
            [idInitFxrate]: {
              id: idInitFxrate,
              ...otherNextFxrateProps,
            },
          };
        }
      }
      return nextState;

    case DESTROY_FXRATE_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idDestroyFxrate } = action.payload;

      if (typeof idDestroyFxrate !== 'undefined') {
        if (nextState.items[idDestroyFxrate]) {
          nextState.items = {
            ...nextState.items,
          };

          delete nextState.items[idDestroyFxrate];
        }
      }
      return nextState;

    case UPDATE_FXRATE_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idUpdateFxrate, ...otherNextUpdateFxrateProps } = action.payload;

      if (typeof idUpdateFxrate !== 'undefined') {
        if (nextState.items[idUpdateFxrate]) {
          nextState.items = {
            ...nextState.items,
            [idUpdateFxrate]: {
              ...nextState.items[idUpdateFxrate],
              ...otherNextUpdateFxrateProps,
            },
          };

          if (typeof action.payload.filters !== 'undefined') {
            nextState.items[idUpdateFxrate].filters = {
              ...state.items[idUpdateFxrate].filters,
              ...action.payload.filters,
            };
          }
        }
      }
      return nextState;

    case GET_FXRATES:
      return {
        ...state,
        status: 'pending',
      };
    case GET_FXRATES_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state, 'baseCurrency'),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_FXRATES_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case GET_FXRATE:
      return {
        ...state,
        status: 'pending',
      };
    case GET_FXRATE_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state, 'baseCurrency'),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_FXRATE_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case INSERT_FXRATES_FROM_SOCKET:
      return {
        ...state,
        status: 'pending',
      };
    case INSERT_FXRATES_FROM_SOCKET_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state, 'baseCurrency'),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case INSERT_FXRATES_FROM_SOCKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case UPDATE_FXRATES_FROM_SOCKET:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_FXRATES_FROM_SOCKET_SUCCESS:
      return {
        ...state,
        ...updateItems(action.payload, state, 'baseCurrency'),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_FXRATES_FROM_SOCKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case RESET_FXRATES:
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
