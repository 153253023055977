import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Toolbar, Select, MenuItem, AppBar } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/amber';
import teal from '@material-ui/core/colors/teal';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { agGridTheme } from '../../../commons/config/themes/ag-grid-theme';

import constants from '../constants';
import { status } from '../../../commons/config/constants';
import {
  initBlotter as actionInitBlotter,
  destroyBlotter as actionDestroyBlotter,
  updateBlotter as actionUpdateBlotter,
} from '../actions';
import {
  getBlotterColumns,
  getTheme,
  getThemeType,
  getBlotterAllOrders,
  getBlotterMyTrades,
  getBlotterTabValue,
  getBlotterColumnState,
  getBlotterFilterState,
  getBlotterSortState,
  getSavedPresets,
  getDefaultPresets,
  getUserFullInfo,
} from '../selectors';
import orders from '../../../modules/orders';
import trades from '../../../modules/trades';
import { isObject } from '../../../core/utils/functions';
import MenuBar from './MenuBar';
import ChooseSettings from './ChooseSettings';
import SaveSettings from './SaveSettings';
import ColumnPicker from './ColumnPicker';
import OrderDropDownMenu from './OrderDropDownMenu';
import { idGenerator } from '../../../commons/utils/functions';

const styles = (theme) => ({
  container: {
    justifyItems: 'stretch',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },

    marginRight: '2px',
    marginLeft: '25px',
    width: '200px',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    width: '20px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 5px',
  },
  input: {
    width: '100%',
    marginLeft: '30px',
    color: theme.palette.text.disabled,
  },
  '@global': agGridTheme(theme),
});

class Blotters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //tabValue: 0,
      anchorPosition: { top: 0, left: 0 },
      orderMenuOpen: false,
      activeOrder: null,
      columnPickerOpen: false,
      saveSettingsOpen: false,
      chooseSettingsOpen: false,
      anchorEl: null,
    };
  }

  componentDidMount = () => {
    const { id, initBlotter, widget } = this.props;

    const payload = {
      id,
      widget,
    };
    initBlotter(payload);
  };

  componentWillUnmount() {
    const { id, destroyBlotter } = this.props;

    const payload = { id };
    destroyBlotter(payload);
  }
  componentDidUpdate(prevProps) {
    if (this.gridApi !== undefined) {
      const immutableStore = this.getData(this.props.tabValue);
      this.gridApi.setRowData(immutableStore);
      if (
        prevProps.columns !== this.props.columns ||
        prevProps.columnState !== this.props.columnState ||
        prevProps.sortState !== this.props.sortState ||
        prevProps.filterState !== this.props.filterState
      ) {
        this.setBlotterState();
      }
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const immutableStore = this.getData(this.props.tabValue);
    params.api.setRowData(immutableStore);

    this.setBlotterState();
  };
  setBlotterState = () => {
    this.gridApi.setColumnDefs(this.props.columns);
    this.columnApi.setColumnState(this.props.columnState);
    this.gridApi.setSortModel(this.props.sortState);
    this.gridApi.setFilterModel(this.props.filterState);
    //this.gridApi.gridOptionsWrapper.gridOptions.floatingFilter = savedState.floatingFilter;
    //this.gridApi.gridOptionsWrapper.gridOptions.floatingFilter = true;
  };
  getData = (tab) => {
    switch (tab) {
      case 0:
        return this.props.orders.my;
      case 1:
        return this.props.orders.others;
      case 2:
      default:
        return this.props.trades;
    }
  };
  gridOptions = () => {
    return {
      suppressScrollOnNewData: true,
      rowClassRules: {
        'ag-row-suspended': function (params) {
          return params.data.status === status.SUSPENDED;
        },
        'ag-row-cancelled': function (params) {
          return params.data.status === status.CANCELLED || params.data.status === status.ENDED;
        },
        'ag-row-filled': function (params) {
          return params.data.status === status.FILLED;
        },
      },
    };
  };
  handleTabChange = (event, value) => {
    const payload = {
      eventType: 'changeTabValue',
      tabValue: this.props.tabValue,
      widget: this.props.widget,
      value,
    };
    this.props.updateBlotter(payload);
  };

  onColumnResized = (e) => {
    if (e.finished) {
      this.saveColumnStateSettings();
    }
  };

  onFilterChanged = () => {
    this.saveColumnStateSettings();
  };

  onSortChanged = () => {
    this.saveColumnStateSettings();
  };

  onDragStopped = () => {
    this.saveColumnStateSettings();
  };

  resetColumnState = (columnState) => {
    this.columnApi.setColumnState(columnState);
    this.saveColumnStateSettings(columnState);
  };

  saveColumnStateSettings = (colState = null) => {
    const columnState = colState === null ? this.columnApi.getColumnState() : colState;
    const sortState = this.gridApi.getSortModel();
    const filterState = this.gridApi.getFilterModel();
    this.saveState({ columnState, sortState, filterState });
  };

  saveState = (blotterState) => {
    const { widget } = this.props;
    const { columnState, sortState, filterState } = blotterState;
    const payload = {
      eventType: 'changeColumnState',
      tabValue: this.props.tabValue,
      widget,
      value: {
        columnState,
        sortState,
        filterState,
      },
    };
    this.props.updateBlotter(payload);
  };

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };
  printAllDisplayedRows = () => {
    const params = {
      skipHeader: false,
      columnGroups: false,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      suppressQuotes: false,
      fileName: this.labels(this.props.tabValue),
    };
    this.gridApi.exportDataAsCsv(params);
  };
  labels = (tabValue) => {
    switch (tabValue) {
      case 0:
        return 'MY_ORDERS';
      case 1:
        return 'ORDERS_SENT_TO_ME';
      case 2:
        return 'MY_TRADES';
    }
  };
  activateSavedSettings = (id) => {
    const items = this.getMenuItems(true);
    const index = items.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (items[index]) {
        const columnState = items[index].columnState
          ? items[index].columnState
          : this.props.columnState;
        const sortState = items[index].sortState ? items[index].sortState : this.props.sortState;
        const filterState = items[index].filterState
          ? items[index].filterState
          : this.props.filterState;
        this.columnApi.setColumnState(columnState);
        this.gridApi.setSortModel(sortState);
        this.gridApi.setFilterModel(filterState);
        this.saveState({ columnState, sortState, filterState });
      }
    }
    this.setState({ anchorEl: null });
  };

  getMenuItems = (showDefault) => {
    const { tabValue } = this.props;
    const { savedPresets, defaultPresets } = this.props;

    const type = tabValue === 2 ? 'TRADE' : 'ORDER';
    const items = [...savedPresets].filter((item) => item.type === type);
    if (showDefault) {
      const def = tabValue === 2 ? defaultPresets.trade : defaultPresets.order;
      items.unshift(def);
    }

    return items;
  };

  savePreset = (name) => {
    const columnState = this.columnApi.getColumnState();
    const sortState = this.gridApi.getSortModel();
    const filterState = this.gridApi.getFilterModel();
    //const { floatingFilter } = this.gridApi.gridOptionsWrapper.gridOptions;
    //const floatingFilter = true;
    const type = this.props.tabValue === 2 ? 'TRADE' : 'ORDER';
    const editable = true;
    const id = idGenerator();

    const preset = {
      type,
      name,
      id,
      editable,
      columnState,
      sortState,
      filterState,
      //floatingFilter,
    };

    if (name) {
      const payload = {
        preset,
      };

      this.props.updatePresets(payload);

      this.setState({ saveSettingsOpen: false });
    }
  };
  handleClick = (value) => {
    const { activeOrder } = this.state;

    switch (value) {
      case 'Amend':
        const payload = {
          action: 'amendOrder',
          order: activeOrder,
          open: true,
        };

        this.props.updateAmendOrderForm(payload);
        break;
      default:
        if (value) {
          const order = {
            orderId: activeOrder.id,
            status: String(value),
          };

          const payload = {
            items: [order],
          };

          this.props.updateOrders(payload);
        }
        break;
    }

    this.setState({ orderMenuOpen: false });
  };
  onRowClick = (event) => {
    const { tabValue } = this.props;
    if (tabValue === 2) {
      const id = event.data.tradeGroupId;
      const payload = { open: true, tradeIds: [id] };
      this.props.onTradeClick(payload);
    } else {
      this.setState({
        //anchorPosition: position,
        orderMenuOpen: !this.state.orderMenuOpen,
        activeOrder: event.data,
      });
      //}
    }
  };

  render() {
    const { classes, columns, tabValue } = this.props;
    const columnState = this.columnApi !== undefined ? this.columnApi.getColumnState() : [];

    const columnDefs =
      this.columnApi !== undefined ? this.columnApi.columnController.columnDefs : [];

    return (
      <div className={`${classes.container} undraggable`}>
        <MenuBar
          tabValue={tabValue}
          handleTabChange={this.handleTabChange}
          handleColumnPickerOpen={() => {
            this.setState({
              columnPickerOpen: !this.state.columnPickerOpen,
            });
          }}
          clearFilters={this.clearFilters}
          handleSaveSettingsOpen={() => {
            this.setState({
              saveSettingsOpen: !this.state.saveSettingsOpen,
            });
          }}
          handleChooseSettings={(e) => {
            this.setState({ anchorEl: e.target });
          }}
          printAllDisplayedRows={this.printAllDisplayedRows}
        />
        <div div className="ag-theme-material" style={{ width: '100%', height: '100px', flex: 1 }}>
          <AgGridReact
            gridOptions={this.gridOptions()}
            columnDefs={columns}
            //rowSelection="single"
            onRowClicked={this.onRowClick}
            onColumnResized={this.onColumnResized}
            onDragStopped={this.onDragStopped}
            //onRowDataChanged={() => void 0}
            onGridReady={this.onGridReady}
            getRowStyle={this.getRowStyle}
            //deltaRowDataMode={true}
            /* getRowNodeId={(d) => {
              return d.id;
            }} */
            onSortChanged={this.onSortChanged}
            onFilterChanged={this.onFilterChanged}
          />
        </div>
        {/*   <OrderDropDownMenu
          anchorPosition={this.state.anchorPosition}
          amend={() => this.handleClick('Amend')}
          suspend={() => this.handleClick(status.SUSPENDED)}
          cancel={() => this.handleClick(status.CANCELLED)}
          activate={() => this.handleClick(status.ACTIVE)}
          close={() => this.setState({ orderMenuOpen: false })}
          open={this.state.orderMenuOpen}
        /> */}
        {this.state.activeOrder !== null && this.state.orderMenuOpen && (
          <orders.components.OrderDetail
            open={this.state.orderMenuOpen}
            onClose={() => this.setState({ orderMenuOpen: false, activeOrder: null })}
            order={this.state.activeOrder}
          />
        )}
        <ColumnPicker
          open={this.state.columnPickerOpen}
          close={() => this.setState({ columnPickerOpen: false })}
          columnState={columnState}
          columnDefs={columns}
          resetColumnState={this.resetColumnState}
        />
        <SaveSettings
          presets={this.getMenuItems()}
          save={this.savePreset}
          close={() => this.setState({ saveSettingsOpen: false })}
          open={this.state.saveSettingsOpen}
        />
        <ChooseSettings
          anchorEl={this.state.anchorEl}
          items={this.getMenuItems(true)}
          onClick={this.activateSavedSettings}
          onClose={() => this.setState({ anchorEl: null })}
        />
      </div>
    );
  }
}

// TODO: Use flow and replace object on shape with schema fields
Blotters.propTypes = {
  id: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  chart: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  initBlotter: PropTypes.func.isRequired,
  destroyBlotter: PropTypes.func.isRequired,
  updateBlotter: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const { widget } = props;

  const cId = widget.id;

  return {
    id: cId,
    //chart: getLocalBlotter(state, cId),
    //config: getConfig(state),
    //orders: getBlotterAllOrders(state), // state.orders.allOrders,
    theme: getTheme(state),
    themeType: getThemeType(state),
    orders: getBlotterAllOrders(state), // state.orders.allOrders,
    trades: getBlotterMyTrades(state),
    columns: getBlotterColumns(state, cId),
    tabValue: getBlotterTabValue(state, cId),
    columnState: getBlotterColumnState(state, cId),
    filterState: getBlotterFilterState(state, cId),
    sortState: getBlotterSortState(state, cId),
    savedPresets: getSavedPresets(state),
    defaultPresets: getDefaultPresets(state),
    user: getUserFullInfo(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    initBlotter: (payload) => {
      dispatch(actionInitBlotter(constants.INIT_BLOTTER, payload));
    },
    destroyBlotter: (payload) => {
      dispatch(actionDestroyBlotter(constants.DESTROY_BLOTTER, payload));
    },
    updateBlotter: (payload) => {
      dispatch(actionUpdateBlotter(constants.UPDATE_BLOTTER, payload));
    },
    updatePresets: (payload) => {
      dispatch(actionUpdateBlotter(constants.UPDATE_BLOTTER_PRESETS, payload));
    },
    updateAmendOrderForm: (payload) => {
      dispatch(orders.actions.updateForm(orders.constants.UPDATE_AMEND_ORDER_FORM, payload));
    },
    updateOrders: (payload) => {
      dispatch(orders.actions.updateOrders(orders.constants.UPDATE_ORDERS, payload));
    },
    onTradeClick: (payload) => {
      dispatch(trades.actions.tradeDetailsOpen(trades.constants.TRADE_DETAILS_OPEN, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Blotters));
