import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { directions, status, visibilities } from '../../../commons/models/constants';
import { getOrganisationShortNameFromId } from '../../../commons/utils/functions';

const getRecipients = (recipients, organisations, myOrg, visibility) => {
  const list = [];
  const orgs = [...organisations, myOrg];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    recipients.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationShortNameFromId(r, orgs);
      }

      list.push(shortName);
    });
  } else {
    return visibility;
  }

  list.sort();
  const listString = list.join(', ');
  return listString;
};

const RecipientsBar = (props) => {
  const recipients = getRecipients(
    props.recipientList,
    props.organisations,
    props.organisation,
    props.visibility,
  );

  const { totals, classes } = props;
  let containerStyle =
    props.totals.direction === directions.BUY
      ? `${classes.containerBuy} ${classes.container}`
      : `${classes.containerSell} ${classes.container}`;
  if (totals.status === status.SUSPENDED) {
    containerStyle = `${classes.containerSuspended} ${classes.container}`;
  }
  if (totals.status === status.CANCELLED) {
    containerStyle = `${classes.containerCancelled} ${classes.container}`;
  }
  if (totals.status === status.FILLED || props.noColor) {
    containerStyle = `${classes.containerFilled} ${classes.container}`;
  }

  return (
    <div className={`${containerStyle} ${classes.container}`}>
      <div className={classes.subContainer}>
        <div className={classes.list}>{recipients}</div>
        <div className={classes.timestamp}>{props.timeStamp}</div>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    height: '32px',
    color: '#fff',
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerBuy: {
    backgroundColor: darken(lightBlue[500], 0.2),
    borderColor: lightBlue[300],
  },
  containerSell: {
    backgroundColor: darken(teal[500], 0.2),
    borderColor: teal[300],
  },
  containerSuspended: {
    backgroundColor: darken(amber[500], 0.2),

    borderColor: amber[300],
  },
  containerCancelled: {
    backgroundColor: darken(red[500], 0.2),

    borderColor: red[300],
  },
  containerFilled: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.divider,
  },
  list: {
    fontSize: theme.fontSize * 1.2,
    paddingLeft: theme.spacing.unit * 1.1,
    color: theme.palette.text.primary,
  },
  timestamp: {
    fontSize: '12px',
    paddingRight: theme.spacing.unit * 1.1,
    color: theme.palette.text.primary,
  },
});

RecipientsBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipientsBar);
