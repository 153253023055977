import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { withStyles } from '@material-ui/core/styles';
import Row from './Row';
import UserAvatar from '../../../commons/components/userAvatar';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';

import { formatFxPrice } from '../../../commons/config/formatters';
import { agGridTheme } from '../../../commons/config/themes/ag-grid-theme';

import { getOrganisations, getUsers } from '../selectors';
import { INIT_ADMIN, UPDATE_ADMIN, DESTROY_ADMIN } from '../constants';
import { destroyAdmin, initAdmin } from '../actions';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    listItem: {
        flex: 1
    },
    listText: {
        color: theme.palette.text.hint
    },
    orgsListContainer: {
        padding: theme.spacing(2),
        backgroundColor: darken(theme.palette.background.default, .08)
    }
});

class OrganisationRow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }
    tradingPartners = () => {
        const ids = this.props.organisation.tradingPartnersIds || []
        const tradingPartners = ids.map(id => this.props.organisationsObject[id])
        tradingPartners.sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        return tradingPartners
    }



    render() {
        const { classes, organisation } = this.props;
        const tradingPartners = this.tradingPartners()

        return (
            <Row
                onClick={() => this.setState({ expanded: !this.state.expanded })}
                expanded={this.state.expanded}
                title={organisation.name}
                edit={() => this.props.editOrganisation(organisation)}
            >
                <React.Fragment>


                    <ListItem button classes={{ root: classes.listItem }}>

                        <ListItemText primary={"Short Name"} classes={{ root: classes.listText }} />
                        <ListItemText primary={organisation.shortName} align="right" />
                    </ListItem>

                    <ListItem button classes={{ root: classes.listItem }}>

                        <ListItemText primary={"Trading Partners"} classes={{ root: classes.listText }} />

                    </ListItem>
                    <div className={classes.orgsListContainer}>
                        {tradingPartners.map(org => {

                            if (org !== undefined) {
                                return (
                                    <ListItem key={org.id} button classes={{ root: classes.listItem }}>
                                        <ListItemIcon>
                                            <UserAvatar organisationId={org.id} />
                                        </ListItemIcon>
                                        <ListItemText primary={org.name} />
                                        <ListItemText primary={org.shortName} align="right" />
                                    </ListItem>
                                )
                            }
                        })}
                    </div>
                    <ListItem button classes={{ root: classes.listItem }}>

                        <ListItemText primary={"Time Created"} classes={{ root: classes.listText }} />
                        <ListItemText primary={organisation.createdTime} align="right" />
                    </ListItem>

                    <ListItem button classes={{ root: classes.listItem }}>

                        <ListItemText primary={"Time Updated"} classes={{ root: classes.listText }} />
                        <ListItemText primary={organisation.updateTime} align="right" />
                    </ListItem>

                    <ListItem button classes={{ root: classes.listItem }}>

                        <ListItemText primary={"Id"} classes={{ root: classes.listText }} />
                        <ListItemText primary={organisation.id} align="right" />
                    </ListItem>
                </React.Fragment>
            </Row>
        );
    }
}

OrganisationRow.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(OrganisationRow);