import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Button, Typography, Icon, InputBase } from '@material-ui/core';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import { directions, visibilities, status } from '../../../../commons/models/constants';
import { getOrganisationObjectFromId, isEmpty } from '../../../../commons/config/functions';

import ResponseFormRow from './ResponseFormRow';
import TotalsRow from '../TotalsRow';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';
import DirectionField from '../../../../commons/components/formFields/DirectionField';
import SelectField from '../../../../commons/components/formFields/SelectField';
import MultipleSelectFieldOrgs from '../../../../commons/components/formFields/multipleSelectFieldOrgs';
import DateTimeField from '../../../../commons/components/formFields/dateTimeField';
import {
  isOpenFormResponse,
  getOrders,
  getOrganisations,
  getRfqResponseForm,
  getActiveUser,
  getOrganisationsObject,
  getOrdersResponseForm,
  getDefaultOrdersResponseForm,
  getActiveUserOrganisation,
  getOrdersResponseFormEnumerations,
  getResponseFormFields,
} from '../../selectors';

import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';

import orders from '../../../orders';

const ERROR_RECIPIENT = 'At least one organisation must be selected.';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DEFAULT_PRICE = 5.6;

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
  },
  section: {
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    //flex: 1,
    //display: "flex",
    //flexDirection: "column",
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    overflowY: 'scroll',
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
  },
  title: { fontSize: theme.fontSize * 1.5 },
});

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class ResponseForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultCalculatorId: null,
      expandedDefault: false,
      expandedOrders: false,
      textMessage: '',
      timeInForce: null,
    };
  }

  expandSection = (section) => {
    if (section === 'defaultOrders') {
      this.setState({ expandedDefault: !this.state.expandedDefault });
    }
    if (section === 'orders') {
      this.setState({ expandedOrders: !this.state.expandedOrders });
    }
  };
  changeCalculatorOpen = (section) => (open) => {
    /*  if (section === "defaultOrders") {
       this.setState({ expandedDefault: open,  })
     }
     if (section === "orders") {
       this.setState({ expandedOrders: open })
     } */
  };

  getDirection = () => {
    const isRfqRequestor = this.props.activeRfq.organisationId === this.props.user.organisationId;
    const direction =
      isRfqRequestor === true
        ? this.props.activeRfq.direction === directions.BUY
          ? directions.BUY
          : directions.SELL
        : this.props.activeRfq.direction === directions.BUY
        ? directions.SELL
        : directions.BUY;
    return direction;
  };

  getOrganisations = () => {
    const { activeRfq, organisationsObject, organisations, userOrganisation } = this.props;
    let orgs = [];

    if (activeRfq) {
      if (activeRfq.visibility === 'SELECTED_ORGANISATIONS') {
        activeRfq.selectedOrganisationsIds.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else if (activeRfq.visibility === 'TRADING_PARTNERS') {
        const tradingPartners = userOrganisation.tradingPartnersIds || [];
        tradingPartners.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else {
        orgs = organisations;
      }
    }

    return orgs;
  };

  updateDefaultCalculatorId = (id) => {
    this.setState({ defaultCalculatorId: id });
  };

  addOrderRow = (actionType = 'addRow') => {
    const payload = {
      type: 'response',
      action: actionType,
    };
    this.props.updateResponseForm(payload);
  };

  deleteOrderRow = (key, actionType = 'deleteRow') => {
    const payload = {
      type: 'response',
      action: actionType,
      key,
    };
    this.props.updateResponseForm(payload);
  };
  copyOrderRow = (key, actionType = 'copyRow') => {
    const payload = {
      type: 'response',
      action: actionType,
      key,
    };
    this.props.updateResponseForm(payload);
  };

  handleTextMessage = (textMessage) => {
    this.setState({ textMessage });
  };
  handleTimeInForce = (name) => (event) => {
    if (name === 'validity') {
      const val = this.state.timeInForce === null ? moment().format('YYYY-MM-DDTHH:mm') : null;
      this.setState({ timeInForce: val });
    } else {
      this.setState({ timeInForce: event.target.value });
    }
  };

  handleChange = (name) => (event) => {
    const payload = {
      type: 'response',
      action: 'updateAll',
      field: name,
      value: event.target.value,
    };
    this.props.updateResponseForm(payload);
  };

  buildOrders = () => {
    const payload = {
      type: 'response',
      action: 'buildOrders',
    };
    this.props.updateResponseForm(payload);
  };

  updatePrices = () => {
    const payload = {
      type: 'response',
      action: 'updatePrices',
    };
    this.props.updateResponseForm(payload);
  };

  updateOrderRow = (actionType) => (payload) => {
    if (actionType === 'defaultOrder') {
      payload.action = 'updateDefaultOrderRow';
    }
    this.props.updateResponseForm(payload);
  };

  getErrors = () => {
    const orders = this.props.ordersRFQ || {};
    let error = false;
    orders.forEach((order) => {
      if (!checkPrice(order.price)) {
        error = true;
      }
      if (!checkVolume(order.volume)) {
        error = true;
      }
    });

    return error;
  };
  sendOrder = () => {
    const hasRowErrors = this.getErrors();
    const hasRecipientErrors = this.getErrorSelectedOrganisationsIds();
    let orderRows = this.props.ordersRFQ;
    const defaultOrders = this.props.defaultOrders;
    if (!hasRowErrors && !hasRecipientErrors.value) {
      orderRows.forEach((row) => {
        row.textMessage = this.state.textMessage;
      });
      const textMessage = this.state.textMessage || '';
      const createOrderPayload = {
        orderRows,
        textMessage,
        defaultOrders,
        timeInForce: this.state.timeInForce,
      };

      this.props.createOrder(createOrderPayload);
      this.setState({ textMessage: '' });
      this.closeForm();
    }
  };

  getOrgansisations = () => {
    const { activeRfq, organisationsObject, organisations, userOrganisation } = this.props;
    let orgs = [];

    if (activeRfq) {
      if (activeRfq.visibility === 'SELECTED_ORGANISATIONS') {
        activeRfq.selectedOrganisationsIds.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else if (activeRfq.visibility === 'TRADING_PARTNERS') {
        const tradingPartners = userOrganisation.tradingPartnersIds || [];
        tradingPartners.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else {
        orgs = organisations;
      }
    }

    return orgs;
  };

  closeForm = () => {
    const payload = {
      type: 'response',
      action: 'closeForm',
    };

    this.props.updateResponseForm(payload);
  };

  getVisibilityOptions = (activeRfq) => {
    if (activeRfq === undefined || activeRfq === null) {
      return visibilities;
    }
    if (activeRfq.visibility === visibilities.PUBLIC) {
      return visibilities;
    }
    if (activeRfq.visibility === visibilities.TRADING_PARTNERS) {
      return {
        TRADING_PARTNERS: 'TRADING_PARTNERS',
        SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS',
      };
    }
    return { SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS' };
  };
  getErrorSelectedOrganisationsIds = () => {
    const { activeRfq, user, ordersRFQ } = this.props;
    if (activeRfq.organisationId === user.organisationId) {
      if (ordersRFQ.length > 0) {
        if (ordersRFQ[0].visibility === visibilities.SELECTED_ORGANISATIONS) {
          if (ordersRFQ[0].selectedOrganisationsIds.length === 0) {
            return { message: ERROR_RECIPIENT, value: true };
          } else {
            return { message: '', value: false };
          }
        }
      }
    }
    return { message: '', value: false };
  };

  render() {
    const { classes, activeRfq, open, user, formFields } = this.props;
    const isRfqRequestor = activeRfq ? activeRfq.organisationId === user.organisationId : false;
    const orders = this.props.ordersRFQ || [];
    console.log("ordersRfq," ,orders)
    const defaultOrders = this.props.defaultOrders || [];
    const order = orders[0] || {};
    const enumerations = this.props.enumerations || [];
    const direction = order.direction;
    const directions = [direction];
    const visibility = order.visibility;
    const visibilitiesOptions = this.getVisibilityOptions(activeRfq);
    const selectedOrganisations = order.selectedOrganisationsIds;
    const organisations = this.getOrgansisations();
    const errorSelectedOrganisationsIds = this.getErrorSelectedOrganisationsIds();
    const extensions = activeRfq.extensions ? JSON.parse(activeRfq.extensions) : {};
    const rfqType = extensions.rfqType ? extensions.rfqType : 'STANDARD';
    const { timeInForce } = this.state;
    return (
      <Modal open={open} onClose={this.closeForm}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>RFQ RESPONSE</Typography>
          <div className={classes.container}>
            {!this.state.calculatorOpen &&
              !this.state.expandedDefault &&
              !this.state.expandedOrders && (
                <div className={classes.section}>
                  <Typography className={classes.banner}>ORDER TYPE</Typography>
                  <div className={classes.subsection}>
                    <DirectionField
                      accessor="direction"
                      displayName="Direction"
                      value={direction}
                      values={directions}
                      handleChange={this.handleChange}
                      fullWidth
                    />
                    {isRfqRequestor && (
                      <SelectField
                        accessor={'visibility'}
                        displayName={'Visibility'}
                        value={visibility}
                        values={Object.keys(visibilitiesOptions)}
                        handleChange={this.handleChange}
                        fullWidth
                      />
                    )}
                    {order.visibility === 'SELECTED_ORGANISATIONS' && isRfqRequestor && (
                      <MultipleSelectFieldOrgs
                        key="selectedOrganisationsIds"
                        accessor="selectedOrganisationsIds"
                        displayName="Visible To:"
                        value={selectedOrganisations}
                        values={organisations}
                        handleChange={this.handleChange}
                        errorRecipientsMessage={errorSelectedOrganisationsIds.message}
                        errorValue={errorSelectedOrganisationsIds.value}
                        fullWidth
                      />
                    )}
                  </div>
                </div>
              )}

            {!this.state.calculatorOpen &&
              !this.state.expandedDefault &&
              !this.state.expandedOrders && (
                <div className={classes.section}>
                  <Typography className={classes.banner}>{`COMMENTS & TIME IN FORCE`}</Typography>
                  <div className={classes.subsection}>
                    <InputBase
                      value={this.state.textMessage}
                      type="text"
                      label="Comments"
                      placeholder="Comments"
                      multiline
                      //fullWidth
                      style={{ flex: 1 }}
                      //onChange={this.handleChange("textMessage")}
                      onChange={(e) => this.handleTextMessage(e.target.value)}
                    />
                    <SelectField
                      accessor="validity"
                      displayName="Validity"
                      value={timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                      values={['GTC', 'TIME IN FORCE']}
                      handleChange={this.handleTimeInForce}
                    />
                    {timeInForce !== null && (
                      <DateTimeField
                        accessor="timeInForce"
                        displayName="Time In Force"
                        value={timeInForce}
                        handleChange={this.handleTimeInForce}
                      />
                    )}
                  </div>
                </div>
              )}

            {!this.state.calculatorOpen &&
              !this.state.expandedOrders &&
              (rfqType === 'CONTRACT' || rfqType === 'VAPCONTRACT') && (
                <div className={classes.sectionFlex}>
                  <div
                    className={classes.banner}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className={classes.banner}
                    >{`TEMPLATE(${defaultOrders.length} items)`}</Typography>
                    <div>
                      <Button
                        style={{ fontSize: 'inherit' }}
                        aria-label="Delete"
                        onClick={() => this.updatePrices()}
                      >
                        <Icon className={classes.extendedIcon}>euro</Icon>
                        <Typography style={{ textAlign: 'right' }}>Update Prices</Typography>
                      </Button>
                      <Button
                        style={{ fontSize: 'inherit' }}
                        aria-label="Delete"
                        onClick={() => this.buildOrders()}
                      >
                        <Icon className={classes.extendedIcon}>build</Icon>
                        <Typography style={{ textAlign: 'right' }}>Build Orders</Typography>
                      </Button>

                      <Button
                        style={{ fontSize: 'inherit' }}
                        aria-label="Delete"
                        onClick={() => this.addOrderRow('addDefaultRow')}
                      >
                        <Icon className={classes.extendedIcon}>add</Icon>
                        <Typography style={{ textAlign: 'right' }}>Add Template Row</Typography>
                      </Button>
                      <Button
                        style={{ fontSize: 'inherit' }}
                        aria-label="Expand"
                        onClick={() => this.expandSection('defaultOrders')}
                      >
                        <Icon className={classes.extendedIcon}>fullscreen</Icon>
                      </Button>
                    </div>
                  </div>
                  <div className={classes.orders}>
                    {defaultOrders.map((row, index) => {
                    
                      return (
                        <ResponseFormRow
                          key={row.key}
                          orderRow={row}
                          activeRfq={activeRfq}
                          copyOrderRow={() => this.copyOrderRow(row.key, 'copyDefaultRow')}
                          deleteOrderRow={() => this.deleteOrderRow(row.key, 'deleteDefaultRow')}
                          updateOrderRow={this.updateOrderRow('defaultOrder')}
                          updateDefaultCalculatorId={this.updateDefaultCalculatorId}
                          defaultCalculatorId={this.state.defaultCalculatorId}
                          index={index}
                          changeCalculatorOpen={this.changeCalculatorOpen('defaultOrders')}
                          enumerations={enumerations}
                          formFields={formFields.defaultRowFields}
                          defaultOrders
                        />
                      );
                    })}
                  </div>
                  <div className={classes.subsection}>
                    <TotalsRow rows={defaultOrders} direction={directions[0]} />
                  </div>
                </div>
              )}

            {!this.state.expandedDefault && (
              <div className={classes.sectionFlex}>
                <div
                  className={classes.banner}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    className={classes.banner}
                  >{`ORDER (${orders.length} items)`}</Typography>
                  <div>
                    <Button
                      style={{ fontSize: 'inherit' }}
                      aria-label="Delete"
                      onClick={() => this.addOrderRow()}
                    >
                      <Icon className={classes.extendedIcon}>add</Icon>
                      <Typography style={{ textAlign: 'right' }}>Add Order Row</Typography>
                    </Button>
                    <Button
                      style={{ fontSize: 'inherit' }}
                      aria-label="Expand"
                      onClick={() => this.expandSection('orders')}
                    >
                      <Icon className={classes.extendedIcon}>fullscreen</Icon>
                    </Button>
                  </div>
                </div>
                <div className={classes.orders}>
                  {orders.map((row, index) => (
                    <ResponseFormRow
                      key={row.key}
                      orderRow={row}
                      activeRfq={activeRfq}
                      copyOrderRow={() => this.copyOrderRow(row.key)}
                      deleteOrderRow={() => this.deleteOrderRow(row.key)}
                      updateOrderRow={this.updateOrderRow('order')}
                      updateDefaultCalculatorId={this.updateDefaultCalculatorId}
                      defaultCalculatorId={this.state.defaultCalculatorId}
                      index={index}
                      changeCalculatorOpen={this.changeCalculatorOpen('orders')}
                      enumerations={enumerations}
                      formFields={formFields.orderRowFields}
                    />
                  ))}
                </div>
                <div className={classes.subsection}>
                  <TotalsRow rows={orders} direction={directions[0]} />
                </div>
              </div>
            )}
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeForm} />
              <ConfirmButton onClick={this.sendOrder} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

ResponseForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrders(state),
    user: getActiveUser(state),
    userOrganisation: getActiveUserOrganisation(state),
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    open: isOpenFormResponse(state),
    activeRfq: getRfqResponseForm(state),
    ordersRFQ: getOrdersResponseForm(state),
    enumerations: getOrdersResponseFormEnumerations(state),
    formFields: getResponseFormFields(state),
    defaultOrders: getDefaultOrdersResponseForm(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    createOrder: (payload) => {
      dispatch(orders.actions.createOrder(orders.constants.CREATE_ORDER_FROM_RFQ, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponseForm));
