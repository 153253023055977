import { createSelector } from 'reselect';
import { NAME } from '../constants';

import contracts from '../../contracts';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));


const getOrganisationsObjectProp = (state) => {
  const items = state[NAME].organisations;
    return items
};
export const getOrganisationsObject = createSelector(getOrganisationsObjectProp, (items) => items);


const getOrganisationsProp = (state) => {
  const obj = state[NAME].organisations;
  const items = Object.keys(obj).map(id=>obj[id])
  const sorted = items.sort((a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return sorted;
};

export const getOrganisations = createSelector(getOrganisationsProp, (items) => items);

const getUsersObjectProp = (state) => {
  const items = state[NAME].users;
  return items
};
export const getUsersObject = createSelector(getUsersObjectProp, (items) => items);




const getUsersProp = (state) => {
  const obj = state[NAME].users;
  const items = Object.keys(obj).map(id=>obj[id])
  const sorted = items.sort((a, b) => {
    const textA = a.lastName.toUpperCase();
    const textB = b.lastName.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return sorted;
};
export const getUsers = createSelector(getUsersProp, (items) => items);


