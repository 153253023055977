import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';

import icon from '../../../../assets/logos/NUKKLEUS_logo.png';

import { signIn as actionSignIn } from '../../actions';
import { SIGN_IN } from '../../constants';
import { getError, getStatus } from '../../selectors';
import Login from './Login';
// import { getThemeType } from '../../../../commons/selectors';


const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  info: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    padding: '50px 10px',
  },

  button: {
    marginTop: theme.spacing.unit * 3,
  },

  infoButton: {
    color: 'rgb(79,161,202)',
    border: '1px solid',
    borderColor: theme.palette.divider,
    textDecoration: 'none',
    cursor: 'pointer',
    lineHeight: 1.5,
    padding: '16px',
  },
  logoContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
  },
  imageName: {
    height: '10vmin',
    margin: '15px 0px 35px 0px',
    flex: 1,
  },
  imageIcon: {
    flex: 1,
  },
  loginFields: {
    flex: 1,
  },
  loginField: {
    width: '100%',
    maxWidth: 500,
  },
  loginButton: {
    color: theme.palette.common.white,
    margin: 0,
  },
  backButton: {
    color: theme.palette.common.white,
    margin: 0,
    fontSize: '11px',
  },
  fieldMargin: {
    margin: '30px 0px',
    padding: '0px 8px',
  },
  buttonRowAlignment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    maxWidth: '500px',
    flex: 1,
  },
  paddingRight: {
    paddingRight: '5px',
  },

  textFieldInputProps: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  root: {
    '& label.Mui-focused': {
      color: '#ffffff',
    },
  },
});

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (values) => {
    const { redirect } = this.props;
    const { username, password } = values;
    const payload = {
      username,
      password,
      redirect,
    };

    const { signIn } = this.props;
    signIn(payload);
  };

  render() {
    const { classes, error, status } = this.props;
    return (
      <>
        <div className={classes.main}>
          <div className={classes.info}>
            <div>
              <img id="spinning-logo" className={classes.imageIcon} src={icon} alt="logo" />
            </div>
            <Typography variant="subtitle1" color="textSecondary">
              <a href="mailto:support@nukkleus.com"> support@nukkleus.com </a>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <a href="https://www.nukkleus.com/" target="_blank">
                https: //www.nukkleus.com/
              </a>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              &copy; 2019 - 2020 NUKKLEUS GROUP
            </Typography>
            <Button className={classes.button}>
              <a className={classes.infoButton} href="https://www.nukkleus.com/" target="_blank">
                More info
              </a>
            </Button>
          </div>
          <div className={classes.logoContainer}>
            <Login onSubmit={this.handleSubmit}/>
          </div>
        </div>
      </>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    themeType: state.themes.type,
    error: getError(state),
    status: getStatus(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (payload) => dispatch(actionSignIn(SIGN_IN, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm));
