import { createSelector } from 'reselect';
import { NAME } from '../constants';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (items) => items);

const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item;
};
export const getById = createSelector(getByIdProp, (items) => items);

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);

const getLastUpdatedTimeProp = (state) => state[NAME].lastUpdatedTime;
export const getLastUpdatedTime = createSelector(
  getLastUpdatedTimeProp,
  (lastUpdatedTime) => lastUpdatedTime,
);
