import moment from 'moment';

import { PRICE_DECIMALS } from '../models/constants';

import { isNumeric } from '../../core/utils/functions';

export const formatPrice = (price) => {
  if (isNumeric(price)) {
    return price.toFixed(PRICE_DECIMALS);
  }

  return '';
};

export const formatFxPrice = (price) => {
  if (isNumeric(price)) {
    return price.toFixed(4);
  }

  return '';
};

export const checkPrice = (price) => {
  const regexp = /^\d+(\.\d{0,2})?$/;
  const validFormat = regexp.test(price);
  const validPrice = validFormat ? price > 0 : false;
  return validFormat && validPrice;
};
export const checkRate = (price) => {
  const regexp = /^-?[0-9]\d*(\.\d{1,3})?$/;
  const validFormat = regexp.test(price);
  const validPrice = validFormat;
  return validFormat && validPrice;
};

export const checkFxPrice = (price) => {
  const regexp = /^\d+(\.\d{1,4})?$/;
  return regexp.test(price);
};

export const checkVolume = (price) => {
  const regexp = /^\d+(\.\d{0,0})?$/;
  const validFormat = regexp.test(price);
  const validPrice = validFormat ? price > 0 : false;
  return validFormat && validPrice;
};

export const formatDateTime = (dateString) => {
  return moment(dateString).format('DD-MMM-YY HH:mm:ss');
};

export const formatDate = (dateString) => {
  if (dateString !== null) {
    return moment(dateString).format('DD-MMM-YY');
  }

  return null;
};

export const formatDateDayMonth = (dateString) => {
  if (dateString !== null && dateString !== undefined) {
    return moment(dateString).format('DD-MMM');
  }

  return null;
};

export const formatTime = (dateString) => {
  return moment(dateString).format('HH:mm:ss');
};

export const formatDateTimeUtc = (dateString) => {
  return moment(dateString).format();
};

export const formatDateTimeToUtc = (dateString) => {
  return moment(dateString).utc().format();
};

export const formatList = (list) => {
  if (list === undefined || list === null) {
    return '';
  }

  return list.join(', ');
};
