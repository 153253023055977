import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import UserAvatar from './userAvatar';

import { avatarSize } from '../models/constants';

const styles = (theme) => ({
  grid: {
    padding: theme.spacing.unit,
  },
  organisation: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    fontSize: theme.fontSize,
    padding: theme.spacing.unit * 0.2,
  },

  user: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100px',
    color: theme.palette.text.secondary,
    fontSize: theme.fontSize.sm,
    padding: theme.spacing.unit * 0.2,
  },
  avatar: {
    padding: theme.spacing.unit,
  },
  details: {
    padding: theme.spacing.unit,
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
  },
});

const UserTab = (props) => {
  const { classes, organisation, user } = props;

  let userName = '';
  let userId = '';

  if (Object.prototype.toString.call(user) === '[object Object]') {
    userName = `${user.firstName} ${user.lastName}`;
    userId = user.id;
  }

  let organisationFullName = '';
  let organisationShortName = '';
  let organisationId = '';

  if (Object.prototype.toString.call(organisation) === '[object Object]') {
    organisationFullName = organisation.name;
    organisationShortName = organisation.shortName;
    organisationId = organisation.id;
  }

  const userData = {
    userName,
    organisationFullName,
    organisationShortName,
    userId,
    organisationId
  };

  return (
    <Grid container className={classes.grid} justify="center" alignItems="center">
      <UserAvatar {...userData} size={avatarSize.LARGE} />
      {props.showDetails === true && (
        <div className={classes.details}>
          <div className={classes.organisation} style={props.dark ? { color: '#fff' } : {}}>
            {userData.organisationShortName}
          </div>
          <div
            className={classes.user}
            style={props.dark ? { color: 'rgba(225,225,255,.50)' } : {}}
          >
            {userData.userName}
          </div>
        </div>
      )}
    </Grid>
  );
};

UserTab.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserTab);
