import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import DayHeader from './DayHeader';
import MessageItem from './MessageItem';
import auth from '../../../auth';
import { updateChats } from '../../actions';
import { CHATS_UPDATE } from '../../constants';

const styles = (theme) => ({
  messagesContainer: {
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.default,
    scrollBehavior: "smooth"
  },
});

const getDate = (isoTime) => {
  return moment(isoTime).format('YYYY-MM-DD');
};

const Messages = ({
  classes,
  messages = [],
  loadHistory,
  selectedChatId,
  user,
  showMessages = true,
  markAsRead = null,
  isOpen,
  isChatVisible
}) => {
  const emptyReceivedMessage = {};

  const [scrollableRef, setScrollableRef] = useState(null);
  const [lastMessage, setLastMessage] = useState(emptyReceivedMessage);
  const [roomSwitched, setRoomSwitched] = useState(false);
  const existingDates = {};

  const showDaySeparator = (isoTime) => {
    const date = getDate(isoTime);
    if (!existingDates[date]) {
      existingDates[date] = true;
      return true;
    }
    return false;
  };
  const readMessages = () => {
    if (selectedChatId !== null && typeof markAsRead === 'function') {
      const payload = {
        action: 'markAsRead',
        items: [{ id: selectedChatId }],
      };

      markAsRead(payload);
    }
  };
  const handleScroll = () => {
    if (scrollableRef.scrollTop === 0) {
      // loadHistory(selectedChatId); // TODO: add when update count messages
    }
  };
  const scrollToBottom = () => {
    const { scrollHeight, clientHeight } = scrollableRef;
    const maxScrollTop = scrollHeight - clientHeight;
    scrollableRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  useEffect(() => {
    if (!scrollableRef) return;
    const { scrollHeight, clientHeight, scrollTop, lastChild } = scrollableRef;
    let scrollToLast = false;
    const lastReceivedMessage = messages[messages.length - 1];
    if (lastReceivedMessage) {
      if (lastReceivedMessage.id !== lastMessage.id) {
        scrollToLast = true;
      }
    }
    if (
      (lastChild && lastChild.offsetHeight + scrollTop + clientHeight === scrollHeight) ||
      roomSwitched ||
      scrollToLast
    ) {
      scrollToBottom();
      setRoomSwitched(false);
      if (isChatVisible){
         setTimeout(readMessages, 0);
      }
     
    }
    setLastMessage(lastReceivedMessage || emptyReceivedMessage);
  }, [messages]);



  useEffect(() => {
    if (!scrollableRef) return;
    scrollableRef.onscroll = handleScroll;
  }, [selectedChatId, scrollableRef]);

  useEffect(() => {
    setRoomSwitched(true);
  }, [selectedChatId]);

  
  useEffect(() => {
    if (!scrollableRef) return;
    scrollToBottom()
  },[scrollableRef]);

  return (
    <div
      id="messages-container"
      ref={(el) => {
        setScrollableRef(el);
      }}
      className={classes.messagesContainer}
    >
      {messages.map((msg) => {
        return (
          <React.Fragment key={msg.createdTime}>
            {showDaySeparator(msg.createdTime) && <DayHeader day={getDate(msg.createdTime)} />}
            <MessageItem key={msg.createdTime} message={msg} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

Messages.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.array,
  selectedChatId: PropTypes.string,
  loadHistory: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    user: auth.selectors.getServerUser(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    markAsRead: (payload) => {
      dispatch(updateChats(CHATS_UPDATE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Messages));
