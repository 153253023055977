import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Checkbox,
  Icon,
  Button,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import CustomSelect from './CustomSelect';
import OrderRecipients from './OrderRecipients';

import UpDown from '../../../../commons/components/upDown';

import { directions, visibilities } from '../../../../commons/models/constants';

const styles = (theme) => ({
  responseHeaders: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.hint,
    padding: '14px 0px',
  },
  cellHeader: {
    padding: theme.spacing.unit / 2,
    textAlign: 'center',
    color: theme.palette.text.hint,
    fontWeight: 500,
    fontSize: theme.fontSize.sm,

    borderBottomWidth: '0px',

    borderLeft: '1px solid',
    borderLeftColor: theme.palette.tableBorder,
    flex: 1,
  },
  tableCellContents: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignContent: 'center',
    minHeight: '28px',
  },
  headerName: {
    margin: 'auto',
  },
  globalInputs: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.03),
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignContent: 'center',
    minHeight: '36px',
  },
  checkbox: { padding: '0px 12px' },

  button: {
    minWidth: '0px',
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.divider,
  },
  icon: {
    color: theme.palette.text.hint,
    fontSize: theme.fontSize.md,
  },
  textField: {
    padding: '0 2px',
  },
});

const OrderManagerHeaders = (props) => {
  const { classes, headers } = props;
  const count = props.count ? props.count : 1;
  const visibilityValues = Object.values(visibilities);
  const directionValues = Object.values(directions);

  return (
    <TableHead classes={{ root: classes.responseHeaders }}>
      <TableRow>
        {headers.map((header) => {
          return (
            <TableCell
              classes={{ root: classes.cellHeader }}
              key={header.name}
              colSpan={header.name === 'WEIGHT CLASS' ? count : 1}
            >
              <div className={classes.tableCellContents}>
                <span className={classes.headerName}> {header.label}</span>
                {header.upDown && <UpDown onClick={props.handleSpinnerClick} name={header.name} />}
              </div>
              <div className={classes.globalInputs}>
                {header.upDown && (
                  <>
                    <TextField
                      className={classes.textField}
                      onChange={props.handleGlobalsChange(header.name)}
                      value={props.globals[header.name] ? props.globals[header.name] : ''}
                    />
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={props.handleApplyGlobals(header.name)}
                    >
                      <Icon className={classes.icon}>system_update_alt</Icon>
                    </Button>
                  </>
                )}
                {(header.name === 'ANON' || header.name === 'ACTIVE') && (
                  <Checkbox
                    className={classes.checkbox}
                    onChange={props.handleGlobalsCheckedChange(header.name)}
                  />
                )}
                {header.name === 'VISIBILITY' && (
                  <>
                    <CustomSelect
                      cellData={{ id: '1' }}
                      accessor={header.name}
                      enumOptions={visibilityValues}
                      onChange={props.handleGlobalsChange}
                      value={props.globals[header.name] ? props.globals[header.name] : null}
                    />{' '}
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={props.handleApplyGlobals(header.name)}
                    >
                      <Icon className={classes.icon}>system_update_alt</Icon>
                    </Button>
                  </>
                )}
                {header.name === 'DIRECTION' && (
                  <>
                    <CustomSelect
                      cellData={{ id: '1' }}
                      accessor={header.name}
                      enumOptions={directionValues}
                      onChange={props.handleGlobalsChange}
                      value={props.globals[header.name] ? props.globals[header.name] : null}
                    />
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={props.handleApplyGlobals(header.name)}
                    >
                      <Icon className={classes.icon}>system_update_alt</Icon>
                    </Button>
                  </>
                )}

                {header.name === 'RECIPIENTS' && (
                  <>
                    <OrderRecipients
                      onChange={props.handleGlobalsChange}
                      accessor={header.name}
                      name={header.name}
                      value={props.globals[header.name] ? props.globals[header.name] : []}
                      recipients={props.tradingPartners ? props.tradingPartners : []}
                    />

                    <Button
                      size="small"
                      className={classes.button}
                      onClick={props.handleApplyGlobals(header.name)}
                    >
                      <Icon className={classes.icon}>system_update_alt</Icon>
                    </Button>
                  </>
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

OrderManagerHeaders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderManagerHeaders);
