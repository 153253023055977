import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import debounceRender from 'react-debounce-render';

import { AppBar, Button, FormControl, Icon, MenuItem, Select, Toolbar } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import SelectField from '../../../commons/components/formFields/SelectField';
import SwitchField from '../../../commons/components/formFields/switchField';
import SnackbarMessage from '../../../commons/components/snackbarMessage';
import TrafficLights from '../../../commons/components/trafficLights';
import { status } from '../../../commons/models/constants';

import MarketViewHeaders from './MarketViewHeaders';
import MarketViewRow from './MarketViewRow';

import {
  getActiveUser, getLocalMarketById, getOrganisations, getSenders, getTradingPartners
} from '../selectors';

import ordersModule from '../../orders';
import rfqs from '../../rfqs';

import { destroyMarket, initMarket, updateMarket } from '../actions';
import { DESTROY_MARKET, INIT_MARKET, UPDATE_MARKET } from '../constants';

const styles = (theme) => {
  const borderColor = theme.palette.divider;

  return {
    table: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
      width: '100%',
      overflowY: 'scroll',
    },

    title: {
      fontSize: '18.85px',
    },
    displayCurrency: {
      fontSize: theme.fontSize.lg,
      paddingLeft: theme.spacing.unit,
    },
    header: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.hint,
      fontWeight: 400,
      fontSize: theme.fontSize.sm,
      borderBottom: '1px solid',
      borderBottomColor: borderColor,
      paddingTop: theme.spacing.unit * 1.6,
      paddingBottom: theme.spacing.unit * 1.6,
    },

    tableBody: {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      overflowY: 'scroll !important',
      flex: 1,
    },
    rowGroup: {
      border: 'none !important',
      flex: '0 !important',
    },
    rowClosed: {
      backgroundColor: theme.palette.primary.row,
      '&:hover': { backgroundColor: fade(theme.palette.primary.row, 0.4) },
      height: '50px',
      maxHeight: '50px',
      borderTop: '1px solid',
      borderTopColor: theme.palette.divider,
    },
    rowExpanded: {
      backgroundColor: theme.palette.primary.row,
      height: '50px',
      maxHeight: '50px',
      fontSize: theme.fontSize,
      '&:hover': { backgroundColor: fade(theme.palette.primary.row, 0.4) },
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    borderedCell: {
      borderLeft: '1px solid',
      borderLeftColor: borderColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    userNameCell: {
      color: theme.palette.text.secondary,
      fontSize: theme.fontSize.md,
    },

    topRowCell: {
      color: theme.palette.text.disabled,
      fontSize: theme.fontSize.md,
    },
    depthCell: {
      fontSize: theme.fontSize.md,
    },

    bidCell: {
      '&:hover': {
        borderRadius: '5px',
        backgroundColor: teal[300],
        border: '1px solid',
        borderColor: teal[800],
        boxShadow: '1px 1px 1px rgba(0, 0, 0, .3)',
      },
      '&:hover $sellButton': {
        fontSize: theme.fontSize.lg,
        color: theme.palette.common.white,
      },
      '&:hover $tradablePrice': {
        fontSize: theme.fontSize.md,
        color: teal[100],
      },
      '&:hover $untradablePrice': {
        fontSize: theme.fontSize.md,
        color: teal[100],
      },
      '&:hover $tradablePriceDepth': {
        fontSize: theme.fontSize,

        color: teal[100],
      },

      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'stretch',
    },
    tradablePrice: {
      fontSize: theme.fontSize.xl,
      flex: 1,
      color: theme.palette.text.primary,
    },
    untradablePrice: {
      fontSize: theme.fontSize.xl,
      flex: 1,
      color: fade(theme.palette.text.primary, 0.1),
    },

    tradablePriceDepth: {
      fontSize: theme.fontSize.md,
      flex: 1,
    },
    untradablePriceDepth: {
      fontSize: theme.fontSize.md,
      flex: 1,
      color: fade(theme.palette.text.primary, 0.1),
    },

    sellButton: {
      fontSize: '0px',
      flex: 0,
      cursor: 'pointer',
      overflow: 'hidden',
    },

    offerCell: {
      '&:hover': {
        borderRadius: '5px',
        backgroundColor: lightBlue[300],
        border: '1px solid',
        borderColor: lightBlue[800],
        boxShadow: '1px 1px 1px rgba(0, 0, 0, .2)',
        // padding: "2px 0px"
      },
      '&:hover $buyButton': {
        fontSize: theme.fontSize.lg,
        color: 'white',
      },
      '&:hover $tradablePrice': {
        fontSize: theme.fontSize.md,

        color: lightBlue[100],
      },
      '&:hover $untradablePrice': {
        fontSize: theme.fontSize.md,

        color: lightBlue[100],
      },

      '&:hover $tradablePriceDepth': {
        fontSize: theme.fontSize.md,

        color: lightBlue[100],
      },

      '&:hover $untradablePriceDepth': {
        fontSize: theme.fontSize.md,

        color: lightBlue[100],
      },
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'stretch',
      alignItems: 'stretch',
    },
    buyButton: {
      fontSize: '0px',
      flex: 0,
      cursor: 'pointer',
    },

    buttonDiv: {
      cursor: 'pointer',
    },
    bidBorderRight: {
      borderRight: '1px solid !important;',
      borderRightColor: theme.palette.divider,
    },
    sectionHeader: {
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing.unit,

      borderBottom: '1px solid',
      borderColor: theme.palette.divider,
      color: 'rgba(93, 117, 152, .87)',
    },
    filtersContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.primary.row,
    },
    marketViewHeadersContainer: {
      display: 'flex',
      overflowY: 'scroll',
      flexDirection: 'column',
      maxWidth: '100%',
      minWidth: '10px',
    },
    switchFieldContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0 32px',
    },
    '@global': {
      '* ReactTable .rt-tbody': {
        display: 'block',
      },
    },
  };
};

class MarketView extends React.PureComponent {
  componentDidMount() {
    const { id, widget } = this.props;

    const payload = {
      id,
      widget,
    };

    this.props.initMarket(payload);
  }

  componentWillUnmount() {
    const payload = {
      id: this.props.id,
    };
    this.props.destroyMarket(payload);
  }

  addNewOrder = () => {
    const segmentString = this.props.market.defaultSegment.split(' ');
    const segmentValue = segmentString[1];
    const marketValue = segmentString[0];

    const payload = {
      type: 'create',
      action: 'open',
      //matchIds: [],
      details: {
        segmentValue,
        marketValue,
      },
    };

    this.props.openFormCreateOrder(payload);
  };

  handleChangeFilter = (name) => (e) => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'changeFilter',
      name,
      value: e.target.value,
    };

    this.props.updateMarket(payload);
  };

  handleChecked = (e) => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'changeField',
      name: 'showDisplayCurrency',
      value: e.target.checked,
    };

    this.props.updateMarket(payload);
  };

  handleInstrumentChange = (e) => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'instrumentChange',
      value: e.target.value,
    };

    this.props.updateMarket(payload);
  };

  handleDisplayCurrencyChange = (e) => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'displayCurrencyChange',
      value: e.target.value,
    };

    this.props.updateMarket(payload);
  };

  cancelAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.CANCELLED);
  };

  suspendAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.SUSPENDED);
  };

  activateAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.ACTIVE);
  };

  updateAllStatus = (status) => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'updateAllStatus',
      status,
    };

    this.props.updateMarket(payload);
  };

  createRfq = (order) => {
    const payload = { type: 'create', action: 'open', order };
    this.props.openRfqForm(payload);
  };

  clearFilters = () => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'clearFilters',
      filters: {
        currencyFilter: '',
        incoTermsFilter: '',
        sentToFilter: '',
        sentByFilter: '',
        weekFilter: '',
      },
    };

    this.props.updateMarket(payload);
  };

  closeSnackbar = () => {
    const { id } = this.props;

    const payload = {
      id,
      action: 'changeField',
      name: 'snackbarOpen',
      value: false,
    };

    this.props.updateMarket(payload);
  };

  render() {
    const { classes, user, organisations, senders, market, tradingPartners } = this.props;
    const {
      groupedOrders = [],
      displayCurrencies,
      displayCurrency,
      recipients,
      weeks,
      incoTerms,
      defaultSegment,
      currencies = [],
      sendToDisabled,
      segmentValues = [],
      filters = {},
      showDisplayCurrency,
      snackbarOpen,
      snackbarContent,
      snackbarColor,
    } = market;

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          minWidth: '10px',
          width: '100%',
        }}
        className="undraggable"
      >
        <AppBar position="static">
          <Toolbar variant="dense">
            <Select
              value={`${defaultSegment}`}
              disableUnderline
              name="defaultSegment"
              className={classes.title}
              onChange={this.handleInstrumentChange}
            >
              {segmentValues.map((seg) => {
                return (
                  <MenuItem key={seg} value={seg}>
                    {seg}
                  </MenuItem>
                );
              })}
            </Select>

            <div style={{ flexGrow: 1 }} />

            <div style={{ flexGrow: 1 }} />
            <TrafficLights
              cancelAll={this.cancelAll}
              suspendAll={this.suspendAll}
              activateAll={this.activateAll}
              orderClick={this.addNewOrder}
            />
          </Toolbar>
        </AppBar>
        <div className={classes.sectionHeader}>FILTERS</div>
        <div className={classes.filtersContainer}>
          <SelectField
            accessor="sentByFilter"
            displayName="Sent By"
            value={`${filters.sentByFilter}`}
            values={senders}
            handleChange={this.handleChangeFilter}
            width={null}
            fullWidth
          />
          <SelectField
            accessor="sentToFilter"
            displayName="Sent To"
            disabled={sendToDisabled}
            value={`${filters.sentToFilter}`}
            values={recipients}
            handleChange={this.handleChangeFilter}
            width={null}
            fullWidth
          />
          <SelectField
            accessor="currencyFilter"
            displayName="Currency"
            value={`${filters.currencyFilter}`}
            values={currencies}
            handleChange={this.handleChangeFilter}
            width={null}
            fullWidth
          />
          <SelectField
            accessor="incoTermsFilter"
            displayName="IncoTerms"
            value={`${filters.incoTermsFilter}`}
            values={incoTerms}
            handleChange={this.handleChangeFilter}
            width={null}
            fullWidth
          />
          <SelectField
            accessor="weekFilter"
            displayName="Har. Week"
            value={`${filters.weekFilter}`}
            values={weeks}
            handleChange={this.handleChangeFilter}
            width={null}
            fullWidth
          />

          <FormControl fullWidth style={{ padding: '4px' }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ height: '100%' }}
              onClick={this.clearFilters}
            >
              Clear
              <Icon style={{ paddingLeft: '4px' }}>clear</Icon>
            </Button>
          </FormControl>
        </div>
        <div className={classes.switchFieldContainer}>
          <SwitchField
            checked={showDisplayCurrency}
            value={showDisplayCurrency}
            handleChecked={this.handleChecked}
            onLabel="Set Display Currency"
            offLabel=""
          />

          {showDisplayCurrency && (
            <Select
              value={displayCurrency}
              disableUnderline
              name="displayCurrency"
              className={classes.displayCurrency}
              onChange={this.handleDisplayCurrencyChange}
            >
              {displayCurrencies.map((curr) => (
                <MenuItem key={curr} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div className={classes.marketViewHeadersContainer}>
          <MarketViewHeaders />
          {groupedOrders.map((order) => (
            <MarketViewRow
              item={order}
              key={order.subsegment}
              organisations={organisations}
              user={user}
              createRfq={this.createRfq}
              showDisplayCurrency={showDisplayCurrency}
              tradingPartners={tradingPartners}
            />
          ))}
        </div>

        <SnackbarMessage
          open={snackbarOpen}
          close={this.closeSnackbar}
          message={snackbarContent}
          color={snackbarColor}
        />
      </div>
    );
  }
}

MarketView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;

  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    user: getActiveUser(state),
    organisations: getOrganisations(state),
    senders: getSenders(state),
    market: getLocalMarketById(state, cId),
    tradingPartners: getTradingPartners(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initMarket: (payload) => {
      dispatch(initMarket(INIT_MARKET, payload));
    },
    destroyMarket: (payload) => {
      dispatch(destroyMarket(DESTROY_MARKET, payload));
    },
    updateMarket: (payload) => {
      dispatch(updateMarket(UPDATE_MARKET, payload));
    },
    openFormCreateOrder: (payload) => {
      dispatch(ordersModule.actions.updateForm(ordersModule.constants.UPDATE_FORM, payload));
    },
    openRfqForm: (payload) => {
      dispatch(rfqs.actions.updateForm(rfqs.constants.UPDATE_FORM, payload));
    },
  };
};

const debouncedMarketView = debounceRender(withStyles(styles)(MarketView), 200, { leading: true });

export default connect(mapStateToProps, mapDispatchToProps)(debouncedMarketView);
