import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Typography,
  Icon,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { widgets } from '../../../modules/workspaces/config/widgets';

const drawerWidth = 280;
const menuItems = {
  widgets,
};
const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 5 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  img: {
    width: '48%',
    padding: '20px',
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  workspaceTitle: {
    color: fade('#fff', 0.87),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    color: fade(theme.palette.text.primary, 0.25),

    margin: '10px 15px 0px 15px',
  },
  headerTitleIcon: {
    color: fade(theme.palette.text.primary, 0.25),
    opacity: 0.5,
    margin: '10px 15px 0px 15px',
  },

  item: {
    opacity: 1,
  },
  icon: {
    // color: theme.palette.text.secondary
  },
});

const DrawerHeader = (props) => {
  const { classes } = props;
  return (
    <>
      <div className={classes.toolbar}>
        <div style={{ textAlign: 'center' }} />
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    </>
  );
};

const SectionHeader = (props) => {
  const { classes } = props;
  return (
    <>
      <div
        className={classes.header}
        style={{
          display: props.open ? 'flex' : 'none',
        }}
      >
        <Typography className={classes.headerTitle}>{props.title}</Typography>
        <Icon className={classes.headerTitleIcon}>settings</Icon>
      </div>
    </>
  );
};

const SectionListItem = (props) => {
  const { classes } = props;
  return (
    <React.Fragment key={props.id}>
      <ListItem button key={props.id} onClick={props.onClick}>
        <Tooltip title={props.title}>
          <ListItemIcon className={classes.icon}>{props.icon}</ListItemIcon>
        </Tooltip>

        <ListItemText className={classes.item}>{props.title}</ListItemText>
      </ListItem>
    </React.Fragment>
  );
};

const MenuDrawer = (props) => {
  const { classes } = props;

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
      open={props.open}
    >
      <DrawerHeader {...props} />
      <Divider />

      <SectionHeader title="WINDOWS" {...props} />

      <List>
        {menuItems.widgets.map((item, index) => {
          if (item.type === 'ADMIN' && props.isAdmin === false) {
            return null;
          } else {
            return (
              <React.Fragment key={item.type}>
                <SectionListItem
                  id={item.type}
                  onClick={() => props.addLayout(item.type)}
                  title={item.title}
                  icon={item.icon}
                  {...props}
                />
              </React.Fragment>
            );
          }
        })}
      </List>
      <Divider />
      <SectionHeader title="WORKSPACES" {...props} />

      <List>
        <SectionListItem
          id="default-workspace"
          onClick={props.addDefaultLayout}
          title="Default WorkSpace"
          icon={<Icon className={classes.icon}>view_quilt</Icon>}
          {...props}
        />
        <SectionListItem
          id="clear-workspace"
          onClick={props.clearLayout}
          title="Clear Workspace"
          icon={<ViewQuiltOutlinedIcon className={classes.icon} />}
          {...props}
        />

        <SectionListItem
          id="manage-workspace"
          onClick={() => props.showManageWorkspaces({ action: 'showManage' })}
          title="Manages Workspaces"
          icon={<Icon className={classes.icon}>sort</Icon>}
          {...props}
        />

        <SectionListItem
          id="palette-workspace"
          onClick={props.togglePalette}
          title="Change Palette"
          icon={<Icon className={classes.icon}>color_lens</Icon>}
          {...props}
        />
      </List>

      <Divider />

      <Divider />
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MenuDrawer);
