import {
  UPDATE_BLOTTER_SETTINGS_SUCCESS,
  UPDATE_BLOTTER_SETTINGS_ERROR,
  UPDATE_BLOTTER_SETTINGS,
} from '../constants';
import { getFromLS } from '../../core/services/localStorage';

import { initialBlotterSettings, defaultBlotterSettings } from '../config/blotterColumns';

import auth from '../../modules/auth';

const initialState = {
  manageOrderListsVisible: false,
  manageCalculatorsVisible: false,
  // TODO: blotter :side effect: move to saga: watch order manager, how detect and save
  blotter: {
    current: getFromLS('blotterSettings') || [],
    initital: initialBlotterSettings(),
    default: defaultBlotterSettings(),
    status: null,
    error: null,
    receivedAt: null,
  },
};

// TODO: Refactor: move to saga. side effect
const settingsReducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case UPDATE_BLOTTER_SETTINGS:
      nextState = {
        ...state,
        blotter: {
          ...state.blotter,
          status: 'pending',
        },
      };
      return nextState;

    case UPDATE_BLOTTER_SETTINGS_SUCCESS:
      return {
        ...state,
        blotter: {
          ...state.blotter,
          current: [...action.payload.item],
        },
      };

    case UPDATE_BLOTTER_SETTINGS_ERROR:
      nextState = {
        ...state,
        blotter: {
          ...state.blotter,
          status: 'error',
          error: action.payload,
        },
      };
      return nextState;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default settingsReducer;
