import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { IconButton, Collapse, Icon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Color from 'color'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

const styles = (theme) => ({
  container: {
    flex: 1,
    paddingBottom: 4,
  },
  subcontainer: {
    padding: theme.spacing.unit / 2,
    backgroundColor: darken(theme.palette.background.default, .05),
    flex: 1,
  },
  collapse: {
    backgroundColor: darken(theme.palette.background.default, .15),
    padding: theme.spacing()
  },
  rowContainer: {
    color: theme.palette.text.primary,
    padding: '4px',
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const Row = (props) => {
  const { classes, children, title, expanded } = props;
  const statusColor = props.loggedIn ?teal['300']: "transparent"
  return (
    <div className={classes.container}>
      <div className={classes.subcontainer}>
        <div className={classes.row}>
          <div className={classes.rowContainer}>{title}</div>
          <div style={{alignItems:"center"}}>
            <IconButton>
              <FiberManualRecordIcon style={{ color: statusColor }}  />
            </IconButton>
          
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={props.edit}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={props.onClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        <Collapse in={expanded}><div className={classes.collapse}>{children}</div></Collapse>
      </div>
    </div>
  );
};

Row.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(Row);
