import constants from '../constants';

import auth from '../../auth';
import { initialBlotterPresets, defaultBlotterPresets } from '../utils/presets';
import { getFromLS } from '../../../core/services/localStorage';

const initialState = {
  items: {},
  presets: {
    saved: getFromLS('blotterPresets') || getFromLS('blotterSettings') || [],
    initial: initialBlotterPresets(),
    default: defaultBlotterPresets(),
    status: null,
    error: null,
    receivedAt: null,
  },
  status: null,
  error: null,
  receivedAt: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case constants.INIT_BLOTTER_SUCCESS:
    case constants.UPDATE_BLOTTER_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idInitBlotter, items, ...otherNextInitBlotterProps } = action.payload;

      if (typeof idInitBlotter !== 'undefined') {
        let newItem = null;

        if (nextState.items[idInitBlotter]) {
          newItem = {
            [idInitBlotter]: {
              ...nextState.items[idInitBlotter],
              ...otherNextInitBlotterProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        } else {
          newItem = {
            [idInitBlotter]: {
              id: idInitBlotter,
              ...otherNextInitBlotterProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        }

        nextState.items = {
          ...nextState.items,
          ...newItem,
        };

        nextState.status = 'success';
        nextState.receivedAt = action.meta.receivedAt;
      }
      return nextState;
    case constants.DESTROY_BLOTTER_SUCCESS:
      nextState = {
        ...state,
      };

      const { id } = action.payload;

      if (typeof id !== 'undefined') {
        if (nextState.items[id]) {
          nextState.items = {
            ...nextState.items,
          };

          delete nextState.items[id];
        }

        if (Object.keys(nextState.items).length === 0) {
          nextState.status = null;
          nextState.error = null;
          nextState.receivedAt = null;
        }
      }
      return nextState;
    case constants.UPDATE_BLOTTER_PRESETS_SUCCESS:
      nextState = {
        ...state,
      };
      const nextSaved = action.payload.item;
      nextState.presets.saved = nextSaved;

      return nextState;

    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
