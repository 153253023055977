import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { withStyles } from '@material-ui/core/styles';

import OrganisationRow from './OrganisationRow'

const styles = (theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    listItem: {
        flex: 1
    }
});

class Organisations extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { classes, organisations, organisationsObject } = this.props;
        return (
            <React.Fragment>
                {organisations.map(org => {
                    return (
                        <OrganisationRow organisation={org} organisationsObject={organisationsObject} editOrganisation={this.props.editOrganisation} key={org.id}/>
                    )
                })}
            </React.Fragment>
        );
    }
}

Organisations.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(Organisations);