import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Modal, Typography } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton, ResetButton } from '../../../commons/components/buttons';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';
import { formatDate, formatDateTime } from '../../../commons/config/formatters';
import TotalsRow from './TotalsRow';

import { isTradeDetailsOpen, getTradesForNotification, getTradeDetailsTitle } from '../selectors';
import { TRADE_DETAILS_OPEN, POST_TRADE_FORM_OPEN } from '../constants';
import { tradeDetailsOpen, postTradeFormOpen } from '../actions';
import { getKeyValuePairsFromTrade } from '../../contracts/utils/modelFunctions';
import { fields, fieldMap } from '../config';
import fileUpload from '../../fileUpload';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    //height: '70vh',
    //maxHeight: '70vh',
    flex: 1,
    overflowY: 'scroll',
  },
  section: {
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing.unit,
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  sectionFlexRow: {
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    alignItems: 'center',
  },
  subsectionNoFlex: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    //overFlowY: "scroll"
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: "scroll"
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.sm,
  },
  title: { fontSize: theme.fontSize.lg },
  postTradeData: {
    flex: 1,
    '&:first-child': {
      borderRight: `1px solid ${theme.palette.tableBorder}`,
    },
  },
});

class TradeDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileToShow: null,
      fileId: null,
      filesRetrieved: false,
    };
  }
  componentDidUpdate(props) {
    if (!this.state.filesRetrieved) {
      this.props.getFiles();
      this.setState({ filesRetrieved: true });
    }
    if (this.state.fileId !== null && this.state.fileToShow === null) {
      const files = [...this.props.myFiles, ...this.props.sharedFiles];
      const file = files.filter((f) => f.id === this.state.fileId)[0];
      if (file) {
        if (file.preview) {
          const newFile = Object.assign(file);
          this.setState({ fileToShow: newFile });
        }
      }
    }
  }

  getOrderRow = (row) => {
    const data = [
      { key: 'Wgt. Class', value: row.contract.underlying.weightClass },
      { key: 'Direction', value: row.direction },

      { key: 'Price', value: row.price },
      { key: 'Volume', value: row.volume },
      { key: 'Week', value: row.contract.week },
      {
        key: 'Delivery Date',
        value: formatDate(row.contract.deliveryDate),
      },
      {
        key: 'Freeze Date',
        value: row.contract.freezeDate ? formatDate(row.contract.freezeDate) : '',
      },
      {
        key: 'Harvest Date',
        value: formatDate(row.contract.harvestDate),
      },
      { key: 'Product', value: row.contract.underlying.product },
      { key: 'Location', value: row.contract.underlying.location },
      {
        key: 'Inco.',
        value: row.contract.underlying.incoTerms,
      },
      { key: 'Delivery Point', value: row.contract.underlying.deliveryPoint },
      { key: 'Region', value: row.contract.underlying.region },
      { key: 'Packing Sta.', value: row.contract.underlying.packingStation },

      { key: 'Unit', value: row.contract.underlying.unit },
      { key: 'Currency', value: row.contract.underlying.currency },

      { key: 'Cert.', value: row.contract.underlying.certification },
      { key: 'Qual.', value: row.contract.underlying.quality },
      { key: 'Color Fan', value: row.contract.underlying.colorFan },
      { key: 'Presentation', value: row.contract.underlying.presentation },
      { key: 'State.', value: row.contract.underlying.state },

      { key: 'Nominal', value: row.nominal },
      { key: 'TradeId', value: row.id },
    ];
    return data;
  };

  handlePostTradeClick = () => {
    const payload = {
      type: 'open',
      open: true,
      tradeId: this.props.trades[0].tradeGroupId,
    };
    this.props.onPostTradeClick(payload);
    this.props.onClose();
  };
  getData = (trade, own) => {
    let data = {};
    if (own) {
      data = {
        'My User': { value: trade.ownUser },
        'My Company': { value: trade.ownName },
        'My Comments': { value: trade.ownComments },
      };
      if (trade.ownData) {
        Object.keys(trade.ownData).forEach((key) => {
          if (key !== 'files') {
            const field = fields.buyer[key] ? fields.buyer[key] : fields.seller[key];
            const displayName = field.displayName;
            if (field.type === 'date') {
              data[displayName] = { value: formatDate(trade.ownData[key]) };
            } else {
              data[displayName] = { value: trade.ownData[key] };
            }
          }
        });
        const files = trade.ownData.files || [];
        files.forEach((file) => {
          const fileType = file.type;
          const key = fieldMap[fileType];
          const field = fields.buyer[key] ? fields.buyer[key] : fields.seller[key];
          const displayName = field.displayName;
          data[displayName] = { value: file.filename, fileId: file.id };
        });
      }
    } else {
      data = {
        'Counterparty User': { value: trade.counterpartyUser },
        'Counterparty Company': { value: trade.counterpartyName },
        'Cparty Comments': { value: trade.counterpartyComments },
      };
      if (trade.counterpartyData) {
        Object.keys(trade.counterpartyData).forEach((key) => {
          if (key !== 'files') {
            const field = fields.buyer[key] ? fields.buyer[key] : fields.seller[key];
            const displayName = field.displayName;

            if (field.type === 'date') {
              data[displayName] = { value: formatDate(trade.counterpartyData[key]) };
            } else {
              data[displayName] = { value: trade.counterpartyData[key] };
            }
          }
        });
        const files = trade.counterpartyData.files || [];
        files.forEach((file) => {
          const fileType = file.type;
          const key = fieldMap[fileType];
          const field = fields.buyer[key] ? fields.buyer[key] : fields.seller[key];
          const displayName = field.displayName;
          data[displayName] = { value: file.filename, fileId: file.id };
        });
      }
    }
    return data;
  };
  itemClick = (item) => {
    if (item.fileId) {
      this.props.getFileContent({ id: item.fileId });
      this.setState({ fileId: item.fileId });
    }
  };
  closeFileViewer = () => {
    this.setState({ fileId: null, fileToShow: null });
  };
  render() {
    const { classes, open, onClose } = this.props;

    let orderRows = [];
    if (this.props.trades.length > 0) {
      orderRows = getKeyValuePairsFromTrade(this.props.trades);
    }
    const trade = this.props.trades.length > 0 ? this.props.trades[0] : {};
    const tradeData = {
      Status: trade.status,
      'Trade Time': formatDateTime(trade.createdTime),
      'Rfq Type': trade.rfqType,
      RfqId: trade.rfqId,
      'Rfq Comments': trade.rfqComments,
    };
    const cpartyData = this.getData(trade, false);
    const myData = this.getData(trade, true);
    const iWeightClass =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'Weight Class') : 0;
    return (
      <Modal open={open} onClose={this.closeForm}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>TRADE DETAILS</Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <List open classes={{ root: classes.list }} disablePadding>
                {Object.keys(tradeData).map((item, index) => (
                  <ListItem key={index} button classes={{ root: classes.listItem }}>
                    <ListItemText primary={item} className={classes.key} />
                    <ListItemText
                      primary={tradeData[item]}
                      align="right"
                      style={item === 'Status' ? { color: teal[500] } : {}}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
            <div className={classes.sectionFlexRow}>
              <div className={classes.postTradeData}>
                <List open classes={{ root: classes.list }} disablePadding>
                  {Object.keys(cpartyData).map((item, index) => (
                    <ListItem
                      key={index}
                      button
                      classes={{ root: classes.listItem }}
                      onClick={() => this.itemClick(cpartyData[item])}
                    >
                      <ListItemText primary={item} className={classes.key} />
                      <ListItemText
                        primary={cpartyData[item].value}
                        align="right"
                        style={item === 'Status' ? { color: teal[500] } : {}}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
              <div className={classes.postTradeData}>
                <List open classes={{ root: classes.list }} disablePadding>
                  {Object.keys(myData).map((item, index) => (
                    <ListItem
                      key={index}
                      button
                      classes={{ root: classes.listItem }}
                      onClick={() => this.itemClick(myData[item])}
                    >
                      <ListItemText primary={item} className={classes.key} />
                      <ListItemText
                        primary={myData[item].value}
                        align="right"
                        style={item === 'Status' ? { color: teal[500] } : {}}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>

            <div className={classes.section}>
              <TotalsRow rows={this.props.trades} />
            </div>

            <div className={classes.section}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                {orderRows.map((row, index) => {
                  //const row = this.getOrderRow(orderRow);
                  console.log('row', row);
                  return (
                    <div
                      style={
                        orderRows.length < 6
                          ? {
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              padding: '0 2px',
                            }
                          : {
                              display: 'flex',
                              flexDirection: 'column',
                            }
                      }
                    >
                      <div className={classes.itemTitle}>{`Item ${index + 1}`}</div>
                      {orderRows.length < 6 ? (
                        <ReadOnlyField
                          displayName={'Weight Class'}
                          value={row[iWeightClass].value || ''}
                          fullWidth
                        />
                      ) : (
                        <ReadOnlyField
                          displayName={'Weight Class'}
                          value={row[iWeightClass].value || ''}
                        />
                      )}
                      {row.map((item) =>
                        orderRows.length < 6 && item.key !== 'Weight Class' ? (
                          <ReadOnlyField displayName={item.key} value={item.value} fullWidth />
                        ) : item.key !== 'Weight Class' ? (
                          <ReadOnlyField displayName={item.key} value={item.value} />
                        ) : null,
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <fileUpload.components.FileViewer
            file={this.state.fileToShow}
            open={this.state.fileId !== null}
            onClose={this.closeFileViewer}
          />
          <ToolbarBottom>
            <CancelButton onClick={onClose} title="CLOSE" />
            <ResetButton onClick={this.handlePostTradeClick} title="ADD POST TRADE INFORMATION" />
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

TradeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isTradeDetailsOpen(state),
    title: getTradeDetailsTitle(state),
    trades: getTradesForNotification(state),
    myFiles: fileUpload.selectors.getAllMyFiles(state),
    sharedFiles: fileUpload.selectors.getAllSharedFiles(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(tradeDetailsOpen(TRADE_DETAILS_OPEN, { open: false }));
    },
    onPostTradeClick: (payload) => {
      dispatch(postTradeFormOpen(POST_TRADE_FORM_OPEN, payload));
    },
    getFileContent: (payload) => {
      dispatch(fileUpload.actions.getFiles(fileUpload.constants.GET_FILE_CONTENT, payload));
    },
    getFiles: (payload) => {
      dispatch(fileUpload.actions.getFiles(fileUpload.constants.GET_FILES, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeDetails));
