import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RestoreIcon from '@material-ui/icons/Restore';
const styles = (theme) => ({
  tab: {
    minWidth: '150px',
  },
  appBar: {
    flex: 1,
    height: '50px',
    maxHeight: '50px',
    minHeight: '50px',
  },
});

const OrderDropDownMenu = (props) => {
  const { classes, amend, suspend, cancel, activate, close, anchorPosition, open } = props;
  return (
    <Menu
      id={1}
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={close}
    >
      <MenuItem className={classes.menuItem} onClick={amend}>
        <ListItemIcon className={classes.icon}>
          <EditIcon />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }} inset primary="Amend" />
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={suspend}>
        <ListItemIcon className={classes.icon}>
          <RemoveCircleIcon />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }} inset primary="Suspend" />
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={cancel}>
        <ListItemIcon className={classes.icon}>
          <CancelIcon />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }} inset primary="Cancel" />
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={activate}>
        <ListItemIcon className={classes.icon}>
          <RestoreIcon />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }} inset primary="Activate" />
      </MenuItem>
    </Menu>
  );
};

OrderDropDownMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderDropDownMenu);
