import { combineReducers } from 'redux';

import { reducer as reduxFormReducer } from 'redux-form';

import themeReducer from './themeReducer';
import settingsReducer from './settingsReducer';

import auth from '../../modules/auth';
import workspaces from '../../modules/workspaces';
import users from '../../modules/users';
import organisations from '../../modules/organisations';
import orders from '../../modules/orders';
import rfqs from '../../modules/rfqs';

import contracts from '../../modules/contracts';
import market from '../../modules/market';
import fxrates from '../../modules/fxrates';
import calculators from '../../modules/calculators';
import trades from '../../modules/trades';
import settings from '../../modules/settings';
import chat from '../../modules/chat';
import charts from '../../modules/charts';
import admin from '../../modules/admin';
import fileUpload from '../../modules/fileUpload';
import blotters from '../../modules/blotters';

console.log('FIleUpload', fileUpload);

console.log('admin', admin);

const rootReducer = combineReducers({
  themes: themeReducer,
  settings: settingsReducer,
  form: reduxFormReducer,
  [auth.constants.NAME]: auth.reducers.reducer,
  [workspaces.constants.NAME]: workspaces.reducers.reducer,
  [contracts.constants.NAME]: contracts.reducers.reducer,
  [market.constants.NAME]: market.reducers.reducer,
  [fxrates.constants.NAME]: fxrates.reducers.reducer,
  [calculators.constants.NAME]: calculators.reducers.reducer,
  [users.constants.NAME]: users.reducers.reducer,
  [organisations.constants.NAME]: organisations.reducers.reducer,
  [orders.constants.NAME]: orders.reducers.reducer,
  [rfqs.constants.NAME]: rfqs.reducers.reducer,
  [trades.constants.NAME]: trades.reducers.reducer,
  [settings.constants.NAME]: settings.reducers.reducer,
  [chat.constants.NAME]: chat.reducers.reducer,
  [charts.constants.NAME]: charts.reducers.reducer,
  [admin.constants.NAME]: admin.reducers.reducer,
  [fileUpload.constants.NAME]: fileUpload.reducers.reducer,
  [blotters.constants.NAME]: blotters.reducers.reducer,
});

export default rootReducer;
