import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, Icon } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import { darken } from '@material-ui/core/styles/colorManipulator';

import UserTab from '../../../commons/components/UserTab';
import CallOut from './CallOut';
import { formatDateTime } from '../../../commons/config/formatters';
import { directions, visibilities } from '../../../commons/models/constants';
import { getOrganisationShortNameFromId } from '../../../commons/utils/functions';

import { getOrganisations, getActiveUserOrganisation } from '../selectors';
import { getOrganisationsObject, getUsersObject, getActiveUser } from '../../orders/selectors';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.row,
    /* '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    }, */
  },
  containerBuy: {
    border: 'solid .1em',
    borderColor: lightBlue[300],
  },
  containerSell: {
    border: 'solid .1em',
    borderColor: teal[300],
  },
  containerOpaque: {
    opacity: 1,
    display: 'flex',
    //marginTop: theme.spacing.unit,
    //marginBottom: theme.spacing.unit,
    flexDirection: 'column',
    justifyContent: 'center',
    border: 'solid .1em',
    borderColor: theme.palette.divider,
    backgroundColor: red[500],
    '&:hover': {
      opacity: 0.85,
      cursor: 'pointer',
    },
  },
  containerContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.spacing(0.5),
  },
  containerContentBuy: {
    backgroundColor: darken(lightBlue[700], 0.6),
  },
  containerContentSell: {
    backgroundColor: darken(teal[700], 0.6),
  },
  containerContentEnded: {
    backgroundColor: darken(red[500], 0.5),
  },
  containerContentItemGrow: {
    flexGrow: 1,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  containerContentItem: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  containerRecipients: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',

    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '24px',
    color: '#fff',
  },
  recipientsBuy: {
    backgroundColor: darken(lightBlue[500], 0.2),
  },
  recipientsSell: {
    backgroundColor: darken(teal[500], 0.2),
  },
  recipientsEnded: {
    backgroundColor: darken(red[500], 0.2),
  },
  containerFooter: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',

    color: '#fff',
  },

  containerFooterBuy: {
    backgroundColor: lightBlue[500],

    color: '#fff',
  },
  containerFooterSell: {
    backgroundColor: teal[500],

    maxHeight: '36px',
    color: '#fff',
  },
  containerFooterEnded: {
    backgroundColor: red[500],

    color: '#fff',
  },

  direction: {
    color: teal[500],
    fontWeight: 'bold',
  },
  statuss: {
    padding: theme.spacing() / 2,
    paddingLeft: theme.spacing(),
    color: '#fff',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timestampContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  timestamp: {
    paddingRight: theme.spacing(),
    color: '#fff',
    fontSize: '12px',
    textAlign: 'center',
  },
});

const getRecipients = (selectedOrganisationsIds, organisations, visibility) => {
  const list = [];
  const orgs = [...organisations];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    selectedOrganisationsIds.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationShortNameFromId(r, orgs);
      }
      list.push(shortName);
    });
  } else {
    return visibility;
  }

  list.sort();
  const listString = list.join(', ');
  return listString;
};

const Tab = (props) => {
  const {
    classes,
    organisations,
    organisationsObject,
    usersObject,
    data,
    contacts,
    showDetails,
    user,
  } = props;
  const isCompanyOrder = data.organisationId === user.organisationId;
  const status = data.status === 'ACTIVE' ? 'IN PROGRESS' : 'ENDED';
  const selectedOrganisationsIds = data.selectedOrganisationsIds || [];
  const visibility = data.visibility;
  const recipients = isCompanyOrder
    ? getRecipients(selectedOrganisationsIds, organisations, visibility)
    : '';

  const containerClass =
    status === 'ENDED'
      ? classes.containerOpaque
      : data.direction === directions.BUY
      ? classes.containerBuy
      : classes.containerSell;

  const containerContentClass =
    status === 'ENDED'
      ? classes.containerContentEnded
      : data.direction === directions.BUY
      ? classes.containerContentBuy
      : classes.containerContentSell;

  const containerFooterClass =
    status === 'ENDED'
      ? classes.containerFooterEnded
      : data.direction === directions.BUY
      ? classes.containerFooterBuy
      : classes.containerFooterSell;

  const containerRecipientsClass =
    status === 'ENDED'
      ? classes.recipientsEnded
      : data.direction === directions.BUY
      ? classes.recipientsBuy
      : classes.recipientsSell;

  return (
    <div className={`${containerClass} ${classes.container}`}>
      <div className={`${containerContentClass} ${classes.containerContent}`}>
        <div className={classes.containerContentItemGrow}>
          <CallOut data={data} />
        </div>
        <div className={classes.containerContentItem}>
          <UserTab
            contacts={contacts}
            organisation={organisationsObject[data.organisationId]}
            user={usersObject[data.createdByUserId]}
            showDetails={showDetails}
            dark
          />
        </div>
      </div>

      <div className={`${containerRecipientsClass} ${classes.containerRecipients}`}>
        <div className={classes.statuss}>{isCompanyOrder ? recipients : ''}</div>
        <div className={classes.timestamp}>{formatDateTime(data.updatedTime)}</div>
      </div>

      <div className={`${containerFooterClass} ${classes.containerFooter}`}>
        {/*  <div className={classes.statuss}>{status}</div> */}
        <div className={classes.buttonContainer}>
          {Object.prototype.hasOwnProperty.call(props, 'viewable') && (
            <Button
              variant="outlined"
              startIcon={<Icon>visibility</Icon>}
              //fullWidth
              onClick={() => props.tabClick(data)}
            >
              View
            </Button>
          )}
          {Object.prototype.hasOwnProperty.call(props, 'dismissable') && !isCompanyOrder && (
            <Button
              variant="outlined"
              startIcon={<Icon>cancel</Icon>}
              //fullWidth
              onClick={() => props.updateRfqViews({ id: data.id })}
            >
              {props.markAsRead ? 'Mark As Read' : 'Dismiss'}
            </Button>
          )}
        </div>

        {/* <div className={classes.timestampContainer}>
          <div className={classes.timestamp}>{formatDateTime(data.updatedTime)}</div>
        </div> */}
      </div>
    </div>
  );
};

Tab.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state), // state.rooms.organisations,
    myOrg: getActiveUserOrganisation(state), // state.logins.organisation,
    organisationsObject: getOrganisationsObject(state),
    usersObject: getUsersObject(state),
    user: getActiveUser(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Tab));
