import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton } from '../../../commons/components/buttons';
import { tradingPartnersListOpen } from '../actions'
import { TRADING_PARTNERS_LIST_OPEN} from '../constants'
import { isTradingPartnersListOpen, getTradingPartners } from '../selectors'

import UserAvatar from '../../../commons/components/userAvatar';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
});

class TradingPartnersList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, tradingPartners } = this.props;
   
    return (
      <PopUp open={this.props.open} title="TRADING PARTNERS" onClose={this.props.onClose}>
        <div className={classes.container}>
          <div className={classes.listContainer}>
            <List open classes={{ root: classes.list }}>
              {tradingPartners.map((org) => (
                <ListItem key={org.id} button classes={{ root: classes.listItem }}>
                  <ListItemIcon>
                    <UserAvatar organisationId={org.id} />
                  </ListItemIcon>
                  <ListItemText primary={org.name} />
                  <ListItemText primary={org.shortName} align="right" />
                </ListItem>
              ))}
            </List>
          </div>
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.onClose} />
                {/* <ConfirmButton onClick={this.onSave} /> */}
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
TradingPartnersList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isTradingPartnersListOpen(state),
    tradingPartners: getTradingPartners(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => {
      dispatch(tradingPartnersListOpen(TRADING_PARTNERS_LIST_OPEN, {open:false}));
    },
  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradingPartnersList));