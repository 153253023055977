/* eslint-disable */
export default class BondValuation {
  /**
   * @param {number} period current period
   * @param {number} total total periods
   * @param {number} couponRate
   * @param {number} faceValue
   * @param {number} frequency
   * @return {number}
   */
  static calculatePV(period, total, couponRate, faceValue, frequency) {
    const interestRate = (couponRate / frequency) * faceValue + (period === total ? faceValue : 0);
    const discountFactor = 1 / Math.pow(1 + couponRate / frequency, period);

    return period * interestRate * discountFactor;
  }

  /**
   * EXCEL: DURATION()
   *
   * @param {number} couponRate
   * @param {number} yieldMaturity
   * @param {number} frequency
   * @param {number} totalPeriods
   * @return {number}
   */
  static macaulayDuration(couponRate, yieldMaturity, frequency, totalPeriods) {
    const faceValue = 1000;
    let duration = 0;

    for (let period = 1; period <= totalPeriods; period++) {
      duration += BondValuation.calculatePV(period, totalPeriods, couponRate, faceValue, frequency);
    }

    return duration / 1000 / frequency;
  }

  /**
   * EXCEL: MDURATION()
   *
   * @param {Date} settlement
   * @param {Date} maturity
   * @param {number} couponRate
   * @param {number} yieldMaturity
   * @param {number} frequency
   * @return {number}
   */
  static modifiedDuration(settlement, maturity, couponRate, yieldMaturity, frequency) {
    const totalPeriods = Math.abs(new Date(settlement - maturity).getFullYear() - 1970) * frequency;
    const cpr = couponRate / 100;
    const ytm = yieldMaturity / 100;

    const macaulayDuration = BondValuation.macaulayDuration(cpr, ytm, frequency, totalPeriods);

    return macaulayDuration / (1 + ytm / frequency);
  }

  /**
   * @param {number} a
   * @param {number} b
   * @returns {number}
   */
  static ratio(a, b) {
    return b / a;
  }
}
