import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { withStyles } from '@material-ui/core/styles';
import Organisations from './Organisations';
import AddOrganisation from './forms/AddOrganisation'
import AddUser from './forms/AddUser'
import UpdateOrganisation from './forms/UpdateOrganisation'
import UpdateUser from './forms/UpdateUser'
import Users from './Users';
import { AppBar, Tabs, Tab, Toolbar, Button, ButtonGroup, Icon } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';

import { formatFxPrice } from '../../../commons/config/formatters';
import { agGridTheme } from '../../../commons/config/themes/ag-grid-theme';

import { getOrganisations, getUsers, getOrganisationsObject, getUsersObject } from '../selectors';
import { INIT_ADMIN, UPDATE_ADMIN, DESTROY_ADMIN } from '../constants';
import { destroyAdmin, initAdmin } from '../actions';


const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }
});

class Admin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      addOrganisationOpen: false,
      addUserOpen: false,
      updateOrganisationOpen: false,
      updateUserOpen: false,
      editableUser: null,
      editableOrganisation: null
    }
  }

  componentDidMount() {
    const { id, widget } = this.props;
    const payload = {
      id,
      widget,
    };

    this.props.initAdmin(payload);
  }

  componentWillUnmount() {
    const payload = {
      id: this.props.id,
    };
    //this.props.destroyAdmin(payload);
  }
  handleTabChange = (e, value) => {
    this.setState({ tabValue: value })
  }
  editOrganisation = (org) => {
    console.log("org eidt", org)
    this.setState({
      editableOrganisation: org,
      updateOrganisationOpen: true
    })
  }
  editUser = (user) => {
    console.log("org user", user)
    this.setState({
      editableUser: user,
      updateUserOpen: true
    })
  }



  render() {
    const { classes, organisations, users, organisationsObject } = this.props;
    const { tabValue } = this.state
    //const { items } = admin;

    return (

      <div className={`${classes.container} undraggable`}>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={this.handleTabChange}>
            <Tab
              style={{}}
              label={'ORGANISATIONS'}
            />
            <Tab
              style={{}}
              label={'USERS'}
            />
          </Tabs>
        </AppBar>
        <div style={{ overflowY: "scroll", overflowX: "hidden", flex:1 }}>
          {this.state.tabValue === 0 ?
            (<Organisations organisations={organisations} organisationsObject={organisationsObject} editOrganisation={this.editOrganisation}/>) :
            (<Users users={users} organisationsObject={organisationsObject} editUser={this.editUser}/>)}

        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "rgb(41,50,65)" }} >
          <div style={{ flex: 1, padding: "8px" }}>
            <Button variant="outlined" fullWidth onClick={() => { this.setState({ addOrganisationOpen: true }) }}>
              <Icon>{'add'}</Icon>
              {'Organisation'}
            </Button>


          </div>
          <div style={{ flex: 1, padding: "8px" }}>
            <Button variant="outlined" fullWidth onClick={() => { this.setState({ addUserOpen: true }) }}>
              <Icon>{'add'}</Icon>
              {'User'}
            </Button>
          </div>
        </div>
        {this.state.addOrganisationOpen && (
          <AddOrganisation open={this.state.addOrganisationOpen} closeForm={() => this.setState({ addOrganisationOpen: false })} organisations={organisations}></AddOrganisation>
        )}

        {this.state.addUserOpen && (
          <AddUser open={this.state.addUserOpen} closeForm={() => this.setState({ addUserOpen: false })} organisations={organisationsObject}></AddUser>
        )}
        {this.state.updateOrganisationOpen && (
          <UpdateOrganisation 
          open={this.state.updateOrganisationOpen} 
          closeForm={() => this.setState({ updateOrganisationOpen: false })} 
          organisations={organisations}
          organisation={this.state.editableOrganisation}
          />
        )}

        {this.state.updateUserOpen && (
          <UpdateUser 
          open={this.state.updateUserOpen} 
          closeForm={() => this.setState({ updateUserOpen: false })} 
          organisations={organisationsObject}
          user={this.state.editableUser}
          />
        )}




      </div>


    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    users: getUsers(state),
    usersObject: getUsersObject(state)

  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    initAdmin: (payload) => {
      dispatch(initAdmin(INIT_ADMIN, payload));
    },
    destroyAdmin: (payload) => {
      dispatch(destroyAdmin(DESTROY_ADMIN, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Admin));