import { createSelector } from 'reselect';
import { NAME } from '../constants';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

export const getMappedUsers = createSelector(getAll, (items) =>
  items.map(({ firstName, lastName, id, connectionState, organisationId }) => ({
    name: `${firstName} ${lastName}`,
    id,
    loggedIn: connectionState === 'online',
    type: 'User',
    organisationId,
  })),
);

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (items) => items);

const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item;
};
export const getById = createSelector(getByIdProp, (items) => items);

export const getOrgConnectionState = createSelector(getAll, (items) =>
  items.reduce((acc, item) => {
    if (acc[item.organisationId] === undefined) {
      acc[item.organisationId] = false;
    }
    if (item.connectionState === 'online') {
      acc[item.organisationId] = true;
    }
    return acc;
  }, {}),
);

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);

const getLastUpdatedTimeProp = (state) => state[NAME].lastUpdatedTime;
export const getLastUpdatedTime = createSelector(
  getLastUpdatedTimeProp,
  (lastUpdatedTime) => lastUpdatedTime,
);
