import { create } from '../../../core/actions';

export const initWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const activateWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const resetWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const clearWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const addComponent = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const removeLayoutWidget = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const removeWidget = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateWidget = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateWorkspaces = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateWorkspaceLayouts = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const addLayoutWidget = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const workspaceManagerVisible = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const setManageWorkspacesVisibility = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const saveWorkspaces = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateManageWorkspaces = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const addManageWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const editManageWorkspaceName = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const setManageActiveWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const copyManageWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const deleteManageWorkspace = (type, payload, meta) => {
  return create(type, payload, meta);
};
