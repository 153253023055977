const all = () => {
  return {
    name: 'ALL',
    active: false,
    layouts: [
      {
        w: 15,
        h: 33,
        x: 0,
        y: 0,
        i: '1605111113428',
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 16,
        x: 23,
        y: 17,
        i: '1605111147970',
        moved: false,
        static: false,
      },
      {
        w: 27,
        h: 17,
        x: 0,
        y: 33,
        i: '1612265980327',
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 12,
        x: 12,
        y: 50,
        i: '1612367760668',
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 16,
        x: 15,
        y: 17,
        i: '1612382465960',
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 17,
        x: 15,
        y: 0,
        i: '1612382526668',
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 12,
        x: 0,
        y: 50,
        i: '1613508209444',
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 12,
        x: 22,
        y: 50,
        i: '1613508251825',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'RFQ',
        id: '1605111113428',
        title: 'RFQ',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1605111113428',
      },
      {
        type: 'FXRATES',
        id: '1605111147970',
        title: 'FX Rates',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 6,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1605111147970',
      },
      {
        type: 'CHAT',
        id: '1612382526668',
        title: 'Chat',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1612382526668',
      },
      {
        type: 'CALCULATOR',
        id: '1613508209444',
        title: 'Calculator',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 12,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613508209444',
      },
      {
        type: 'CHART',
        id: '1612367760668',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'lfex_4w',
        },
        i: '1612367760668',
      },
      {
        type: 'LFEXPRICES',
        id: '1613508251825',
        title: 'LFEX Prices',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 6,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613508251825',
      },
      {
        type: 'MARKETVIEWEXPANDED',
        id: '1612382465960',
        title: 'Market View',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          defaultSegment: 'SALMON NORWAY',
          filters: {
            sentByFilter: '',
            sentToFilter: '',
            currencyFilter: '',
            incoTermsFilter: '',
            weekFilter: '',
          },
        },
        i: '1612382465960',
      },
      {
        type: 'CENTRALLIMITORDERBOOK',
        id: '1612382465961',
        title: 'Central Limit Order Book',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 21,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1612382465961',
      },
      {
        type: 'BLOTTERS',
        id: '1612265980327',
        title: 'Order and Trade Blotters',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: true,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          tabValue: 2,
          blotterSettings: {
            0: {
              columnState: [
                {
                  colId: 'contract.underlying.colorFan',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.freezeDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 65,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 88,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'originalVolume',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 94,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint',
                  hide: true,
                  aggFunc: null,
                  width: 264,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 112,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 86,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'orderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'recipientList',
                  hide: true,
                  aggFunc: null,
                  width: 217,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'user.fullName',
                  hide: false,
                  aggFunc: null,
                  width: 102,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisation.shortName',
                  hide: false,
                  aggFunc: null,
                  width: 69,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeOfEntry',
                  hide: false,
                  aggFunc: null,
                  width: 163,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeOfUpdate',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'textMessage',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [
                {
                  colId: 'timeOfUpdate',
                  sort: 'desc',
                },
              ],
              filterState: {},
              floatingFilter: true,
            },
            1: {
              columnState: [
                {
                  colId: 'contract.underlying.colorFan',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.freezeDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 65,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 88,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'originalVolume',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 94,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint',
                  hide: true,
                  aggFunc: null,
                  width: 264,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 112,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 86,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'orderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'recipientList',
                  hide: true,
                  aggFunc: null,
                  width: 217,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'user.fullName',
                  hide: false,
                  aggFunc: null,
                  width: 102,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisation.shortName',
                  hide: false,
                  aggFunc: null,
                  width: 69,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeOfEntry',
                  hide: false,
                  aggFunc: null,
                  width: 163,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeOfUpdate',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'textMessage',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [
                {
                  colId: 'timeOfUpdate',
                  sort: 'desc',
                },
              ],
              filterState: {},
              floatingFilter: true,
            },
            2: {
              columnState: [
                {
                  colId: 'contract.underlying.colorFan_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeStamp',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate_1',
                  hide: false,
                  aggFunc: null,
                  width: 117,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location_1',
                  hide: false,
                  aggFunc: null,
                  width: 104,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms_1',
                  hide: false,
                  aggFunc: null,
                  width: 77,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint_1',
                  hide: false,
                  aggFunc: null,
                  width: 121,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packingStation_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass_1',
                  hide: false,
                  aggFunc: null,
                  width: 77,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume_1',
                  hide: false,
                  aggFunc: null,
                  width: 80,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction_1',
                  hide: false,
                  aggFunc: null,
                  width: 71,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit_1',
                  hide: false,
                  aggFunc: null,
                  width: 60,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price_1',
                  hide: false,
                  aggFunc: null,
                  width: 70,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency_1',
                  hide: false,
                  aggFunc: null,
                  width: 71,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'nominal',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownCompany',
                  hide: false,
                  aggFunc: null,
                  width: 96,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.quality_1',
                  hide: false,
                  aggFunc: null,
                  width: 129,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.state_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation_1',
                  hide: false,
                  aggFunc: null,
                  width: 84,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.instrument_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.harvestDate_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status_1',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownUser',
                  hide: false,
                  aggFunc: null,
                  width: 122,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification_1',
                  hide: false,
                  aggFunc: null,
                  width: 110,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.freezeDate_1',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType_1',
                  hide: false,
                  aggFunc: null,
                  width: 128,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.week_1',
                  hide: false,
                  aggFunc: null,
                  width: 108,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyUser',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownComments',
                  hide: false,
                  aggFunc: null,
                  width: 96,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyCompany',
                  hide: false,
                  aggFunc: null,
                  width: 90,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyComments',
                  hide: false,
                  aggFunc: null,
                  width: 90,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqComments',
                  hide: false,
                  aggFunc: null,
                  width: 96,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'bidId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'offerId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'tradeId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [
                {
                  colId: 'timeStamp',
                  sort: 'desc',
                },
              ],
              filterState: {},
              floatingFilter: true,
            },
          },
        },
        i: '1612265980327',
      },
    ],
    id: '1599728844326',
  };
};

export default all;
