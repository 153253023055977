import { createSelector } from 'reselect';

import { Auth } from 'aws-amplify';

import { NAME } from '../constants';

import { getSettingsFromLS } from '../../../core/services/localStorage';

import { isObject } from '../../../core/utils/functions';

const getAWSUserProp = (state) => state[NAME].aws.user;
export const getAWSUser = createSelector(getAWSUserProp, (user) => user);

const getServerUserProp = (state) => state[NAME].server.user;
export const getServerUser = createSelector(getServerUserProp, (user) => {
  if (user) {
    return {
      ...user,
    };
  }
  return user;
});

const isAdminProp = (state) => {
  let isAdmin = false;
  let user = state[NAME].aws.user;
  if (user.signInUserSession) {
    if (user.signInUserSession.idToken) {
      if (user.signInUserSession.idToken.payload) {
        if (user.signInUserSession.idToken.payload) {
          if (user.signInUserSession.idToken.payload['cognito:groups']) {
            const groups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
            if (groups.includes('Admin')) {
              isAdmin = true;
            }
          }
        }
      }
    }
  }
  return isAdmin;
};
export const isAdmin = createSelector(isAdminProp, (isAdmin) => isAdmin);

const isAuthedProp = (state) => state[NAME].authed;
export const isAuthed = createSelector(isAuthedProp, (authed) => authed);

export const getUserId = createSelector(getServerUserProp, (user) => user.id);
export const getUsername = createSelector(
  getServerUserProp,
  (user) => `${user.firstName} ${user.lastName}`,
);
export const getOrgId = createSelector(getServerUserProp, (user) => user.organisationId);

// TODO: Don't use in components. CRITICAL!!!! REFACTOR. CREATE service api and interceptor. Move the token to the interceptor. Remove aws from store.
export async function getToken() {
  const awsAuth = await Auth.currentAuthenticatedUser();

  if (awsAuth.signInUserSession) {
    return awsAuth.signInUserSession.accessToken.jwtToken || null;
  }

  return null;
}

export const getUserNotificationSettings = createSelector(
  getServerUserProp,
  (user) => user.notificationsSettings,
);
// TODO: CRITICAL!!!! REFACTOR. Move get aws user in saga. Remove aws from store.
const getUserRequiredAttributes = (state) => {
  const { requiredAttributes } = state[NAME].aws.user.challengeParam;

  requiredAttributes.sort((x, y) => {
    const secondCheck = y === 'family_name' ? 1 : 0;
    return x === 'family_name' ? -1 : secondCheck;
  });
  requiredAttributes.sort((x, y) => {
    const secondCheck = y === 'name' ? 1 : 0;
    return x === 'name' ? -1 : secondCheck;
  });

  return requiredAttributes;
};
export const getRequiredAttributes = createSelector(
  getUserRequiredAttributes,
  (attributes) => attributes,
);

const getStatusProp = (state) => state[NAME].status;
export const getStatus = createSelector(getStatusProp, (status) => status);

const getErrorProp = (state) => state[NAME].error;
export const getError = createSelector(getErrorProp, (error) => error);

const getReceivedAtProp = (state) => state[NAME].receivedAt;
export const getReceivedAt = createSelector(getReceivedAtProp, (receivedAt) => receivedAt);

const isConnectedProp = (state) => state[NAME].connected;
export const isConnected = createSelector(isConnectedProp, (connected) => connected);

// TODO: REFACTOR! CREATE MODULE THEME
const getThemeTypeProp = (state) => state.themes.type;
export const getThemeType = createSelector(getThemeTypeProp, (type) => type);

const getAppSettingsProp = (state) => {
  let settings = {};

  if (isObject(state[NAME].server.user)) {
    const { appSettings } = state[NAME].server.user;

    if (isObject(appSettings)) {
      settings = { ...settings, ...appSettings };
    }
  }

  const settingsFromLS = getSettingsFromLS();

  if (isObject(settingsFromLS)) {
    settings = { ...settings, ...settingsFromLS };
  }

  return settings;
};
export const getAppSettings = createSelector(getAppSettingsProp, (settings) => settings);
