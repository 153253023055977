import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import auth from '../../../modules/auth';
import { getThemes, getAuthStatus } from '../../selectors';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  info: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    padding: '50px 10px',
  },

  button: {
    marginTop: theme.spacing.unit * 3,
  },

  infoButton: {
    color: 'rgb(79,161,202)',
    border: '1px solid',
    borderColor: theme.palette.divider,
    textDecoration: 'none',
    cursor: 'pointer',
    lineHeight: 1.5,
    padding: '16px',
  },
  logoContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
  },
  imageName: {
    height: '10vmin',
    margin: '15px 0px 35px 0px',
    flex: 1,
  },
  imageIcon: {
    flex: 1,
  },
  loginFields: {
    flex: 1,
  },
  loginField: {
    width: '100%',
    maxWidth: 500,
  },
  loginButton: {
    width: '100%',
    maxWidth: 500,
    marginTop: '30px',
    color: theme.palette.common.white,
  },
  fieldMargin: {
    margin: '30px 0px',
    padding: '0px 8px',
  },
  paddingRight: { paddingRight: '5px' },

  textFieldInputProps: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
});

class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.redirect = {
      whenAuthed: true,
      url: auth.constants.CLIENT_PAGE_URL,
    };
  }

  render() {
    const { LoginForm, RequireNewPassword } = auth.components;
    const { status } = this.props;
    if (status === auth.constants.SIGN_IN_NEW_PASSWORD_REQUIRED) {
      return <RequireNewPassword />;
    }

    return <LoginForm redirect={this.redirect} />;
  }
}

const mapStateToProps = (state) => ({
  theme: getThemes(state),
  status: getAuthStatus(state),
});

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string
};

export default connect(mapStateToProps)(withStyles(styles)(LoginPage));
