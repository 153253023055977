import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Grid, Switch, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
});

const SwitchField = (props) => {
  const { value } = props;

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={0}>
        <Grid item>{props.offLabel}</Grid>
        <Grid item>
          <Switch checked={!!props.checked} onChange={props.handleChecked} value={value} />
        </Grid>
        <Grid item>{props.onLabel}</Grid>
      </Grid>
    </Typography>
  );
};

SwitchField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SwitchField);
