const spacing = 0.3;
export const textField = (theme) => {
  return {
    marginLeft: theme.spacing(spacing),
    marginRight: theme.spacing(spacing),
    marginTop: theme.spacing(spacing),
    marginBottom: theme.spacing(spacing),
    color: 'white',
    fontSize: '17px',
  };
};
export const textFieldLarge = (theme) => {
  return {
    marginLeft: theme.spacing(spacing),
    marginRight: theme.spacing(spacing),
    marginTop: theme.spacing(spacing),
    marginBottom: theme.spacing(spacing),
    color: 'white',
    fontSize: '20px',
  };
};

export const fieldFontSize = (theme) => {
  return {
    fontSize: '17px',
  };
};

export const fieldFontSizeLarge = (theme) => {
  return {
    fontSize: '20px',
  };
};
