import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';

import Toast from '../Toast';
import { required } from '../validators';
import FormTextField from '../FormTextField';

import { getStatus, getError } from '../../selectors';

const styles = (theme) => ({
  loginFields: {
    flex: 1,
  },

  submit: {
    color: theme.palette.common.white,

    margin: 0,
    backgroundColor: lightBlue[500],
    '&:hover': {
      backgroundColor: lightBlue[700],
    },
  },

  fieldMargin: {
    margin: '30px 0px',
    padding: '0px 8px',
  },
  buttonRowAlignment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    maxWidth: '500px',
    flex: 1,
  },
  paddingRight: {
    paddingRight: '5px',
  },
});

const Login = (props) => {
  const { handleSubmit, classes, pristine, reset, submitting, error, status } = props;
  const showError = status === 'error' && (error !== null && error !==undefined ? (error.error ? true : false) : false);
  const errorMessage = (error === null || error ===undefined) ? '' : error.error;

  return (
    <form className={classes.loginFields} onSubmit={handleSubmit}>
      <div className={classes.fieldMargin}>
        <Field
          name="username"
          type="email"
          component={FormTextField}
          label="User Name"
          validate={required}
        />
      </div>
      <div className={classes.fieldMargin}>
        <Field
          name="password"
          type="password"
          component={FormTextField}
          label="Password"
          validate={required}
        />
      </div>
      <div className={classes.fieldMargin}>
        <div className={classes.buttonRowAlignment}>
          <div className={classes.buttonRow}>
            <Button
              classes={{ root: classes.submit }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      {showError && <Toast message={errorMessage} />}
    </form>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    error: getError(state),
    status: getStatus(state),
  };
}

export default reduxForm({
  form: 'login',
})(connect(mapStateToProps)(withStyles(styles)(Login)));
