import { formatDateTimeToUtc } from '../../../commons/config/formatters';
import { orderFields } from '../../market/models/order';
import { getAllContractWeeks } from './deliveryWeeks';

import { isObject, filterObject } from '../../../core/utils/functions';

export const getSegmentValues = (contract) => {
  if (
    isObject(contract) &&
    isObject(contract.orderFields) &&
    isObject(contract.orderFields[contract.subSegment]) &&
    isObject(contract.orderFields[contract.subSegment].values)
  ) {
    let allSegments = Object.values(contract.orderFields[contract.segment].values);
    let values1 = allSegments.filter((s) => s.market === 'SALMON');
    let values2 = allSegments.filter((s) => s.market === 'TROUT');
    let values = [...values1, ...values2];

    return values;
  }

  return [];
};

export const getSubSegmentValues = (contract) => {
  if (
    isObject(contract) &&
    isObject(contract.orderFields) &&
    isObject(contract.orderFields[contract.subSegment]) &&
    isObject(contract.orderFields[contract.subSegment].values)
  ) {
    let values = Object.keys(contract.orderFields[contract.subSegment].values);
    return [...new Set(values)];
  }

  return [];
};

export const getDefaultFieldStatesPerSegment = (contract, segmentValue, marketValue = 'SALMON') => {
  if (isObject(contract) && typeof segmentValue !== 'undefined') {
    const segmentKey = contract.segment;

    if (contract.orderFields) {
      const segmentValues = contract.orderFields[segmentKey].values;
      const segmentDefault = Object.keys(segmentValues).find(
        (val) =>
          segmentValues[val].name === segmentValue && segmentValues[val].market === marketValue,
      );
      const values = segmentValues[segmentDefault].defaultValues;
      return values;
    }
  }

  return [];
};

/* export const getWeekNumberFromWeek = (week) => {
  const weekparts = week.split('_');
  const weekNumber = Number(weekparts[0]);
  return weekNumber;
}; */

export const socketOrderDestructure = (order, contract) => {
  const allFields = { ...contract.orderFields, ...orderFields };

  const fields = filterObject(allFields, 'orderBlotter', 'visible', true);

  const obj = {};

  Object.keys(fields).forEach((key) => {
    if (fields[key].parentAccessor === 'underlying') {
      obj[key] = order.orderData[0].contract.underlying[key];
    } else if (fields[key].parentAccessor === 'contract') {
      obj[key] = order.orderData[0].contract[key];
    } else {
      obj[key] = order[key] || order.orderData[0][key] || null;
    }
  });
  obj.id = order.id;

  return obj;
};

export const socketOrdertoClientOrder = (order, contract) => {
  const allFields = { ...contract.orderFields, ...orderFields };

  const fields = filterObject(allFields, 'orderForm', 'output', true);

  const obj = {};

  Object.keys(fields).forEach((key) => {
    if (fields[key].parentAccessor === 'underlying') {
      obj[key] = order.orderData[0].contract.underlying[key];
    } else if (fields[key].parentAccessor === 'contract') {
      obj[key] = order.orderData[0].contract[key];
    } else {
      obj[key] = order[key] || order.orderData[0][key] || null;
    }
  });
  obj.id = order.id;
  if (order.orderData[0].contract.underlying.deliveryPoint) {
    obj.deliveryPoint = order.orderData[0].contract.underlying.deliveryPoint;
  }

  return obj;
};

export const orderState = (contract, segmentValue, marketValue = 'SALMON') => {
  const allFields = { ...contract.orderFields, ...orderFields };
  const fields = filterObject(allFields, 'orderForm', 'output', true);

  const obj = {};
  Object.keys(fields).forEach((key) => {
    if (fields[key].orderForm.component === 'date') {
      obj[key] = null;
      // obj[key] = moment(new Date())
      //   .add("days", fields[key].default)
      //   .format("YYYY-MM-DD");
    } else if (fields[key].orderForm.component === 'multi-select') {
      obj[key] = [fields[key].default];
    } else {
      obj[key] = fields[key].default;
    }
  });

  const underlyingValues = getDefaultFieldStatesPerSegment(contract, segmentValue, marketValue);

  Object.keys(underlyingValues).map((key) => (obj[key] = underlyingValues[key]));
  obj[contract.segment] = segmentValue;
  obj[contract.market] = marketValue;
  const weeks = getAllContractWeeks();

  if (Object.prototype.hasOwnProperty.call(obj, 'week')) {
    obj.week = weeks.value;
  }

  return obj;
};

export const orderDefaultState = (contract) => {
  if (isObject(contract) && isObject(contract.orderFields)) {
    const segmentValue = contract.orderFields[contract.segment].default;
    const marketValue = contract.orderFields[contract.market].default;
    const obj = orderState(contract, segmentValue, marketValue);
    return obj;
  }

  return {};
};

export const orderFormOrderFields = (contract) => {
  const allFields = { ...contract.orderFields, ...orderFields };
  const obj = filterObject(allFields, 'orderForm', 'output', true);
  return obj;
};

export const orderFormStateFields = (contract) => {
  const allFields = { ...contract.orderFields, ...orderFields };
  const obj = filterObject(allFields, 'orderForm', 'visible', true);

  const weeks = getAllContractWeeks();

  if (Object.prototype.hasOwnProperty.call(obj, 'week')) {
    obj.week.values = weeks.values;
  }

  return obj;
};

export const allFields = (contract) => {
  const obj = { ...contract.orderFields, ...orderFields };
  return obj;
};

export const getOrderFormFields = (contract) => {
  const obj = orderFormStateFields(contract);
  const fields = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key].orderForm.visible === true && obj[key].orderForm.section === 'contract') {
      fields.push(obj[key]);
    }
  });

  return fields;
};

export const createOrderFormOrder = (_contract, state) => {
  const fields = orderFormOrderFields(_contract);

  const order = {};
  const contract = {};
  const underlying = {};
  Object.keys(fields).forEach((key) => {
    let val;

    if (
      fields[key].orderForm.component === 'select' &&
      fields[key].orderForm.outputType === 'array'
    ) {
      val = [state[key]];
    } else if (fields[key].orderForm.outputType === 'date') {
      val = state[key];
    } else if (fields[key].orderForm.outputType === 'date-time') {
      const val1 = state[key];

      val = formatDateTimeToUtc(val1);
    } else {
      val = state[key];
    }

    if (fields[key].parentAccessor === 'underlying') {
      underlying[key] = val;
    } else if (fields[key].parentAccessor === 'contract') {
      contract[key] = val;
    } else {
      order[key] = val;
    }
  });
  if (Object.prototype.hasOwnProperty.call(order, 'autoClose')) {
    if (order.autoClose === false || order.autoClose === 'false') order.timeInForce = null;
  }
  if (state.textMessage) {
    order.textMessage = state.textMessage;
  }
  if (underlying.incoTerms !== 'FCA' || underlying.incoTerms === 'FOB') {
    underlying.deliveryPointFob = '';
  }
  contract.underlying = underlying;
  order.contract = contract; // TODO: review

  return order;
};

export const getDefaultSegmentValues = (contract, segmentName) => {
  if (
    isObject(contract) &&
    isObject(contract.orderFields) &&
    isObject(contract.orderFields[contract.segment]) &&
    isObject(contract.orderFields[contract.segment].values)
  ) {
    const segmentValues = Object.values(contract.orderFields[contract.segment].values);

    const segmentObject = segmentValues.find((seg) => seg.segmentName === segmentName);

    return isObject(segmentObject) ? segmentObject.defaultValues : [];
  }

  return [];
};

export const getContractFieldMenuItems = (
  contract,
  segmentValue,
  standard,
  marketValue = 'SALMON',
) => {
  const underlying = {};

  if (
    isObject(contract) &&
    typeof segmentValue !== 'undefined' &&
    typeof standard !== 'undefined'
  ) {
    const segmentValues = Object.values(contract.orderFields[contract.segment].values);
    const segmentObject = segmentValues.find(
      (seg) => seg.name === segmentValue && seg.market === marketValue,
    );
    Object.keys(contract.orderFields).forEach((key) => {
      const underlyingValues = [];

      Object.values(contract.orderFields[key].values || []).forEach((val) => {
        underlyingValues.push(val.name);
      });

      underlying[key] = [...new Set(underlyingValues)];
    });

    Object.keys(segmentObject.defaultValues).forEach((key) => {
      if (standard) {
        underlying[key] = segmentObject.defaultValues[key];
      } else {
        let values = [...underlying[key]];
        if (values.indexOf(segmentObject.defaultValues[key]) === -1) {
          values.unshift(segmentObject.defaultValues[key]);
        } else if (segmentObject.enumValues[key]) {
          values = Object.keys(segmentObject.enumValues[key]);
        }
        underlying[key] = [...new Set(values)];
      }
    });
    if (segmentObject.enumValues.weightClass) {
      underlying.weightClass = Object.keys(segmentObject.enumValues.weightClass);
    }
    if (segmentObject.enumValues.location) {
      underlying.location = Object.keys(segmentObject.enumValues.location);
    } else {
    }

    const weeks = getAllContractWeeks();

    if (Object.prototype.hasOwnProperty.call(underlying, 'week')) {
      underlying.week = weeks.values;
    }
  }
  return underlying;
};

export const getFieldEnums = (contract, segmentValue, standard, marketValue = 'SALMON') => {
  const underlying = {};

  let segmentValues = null;

  if (isObject(contract) && isObject(contract.orderFields)) {
    if (isObject(contract.orderFields[contract.segment])) {
      segmentValues = Object.values(contract.orderFields[contract.segment].values);
    } else {
      segmentValues = [];
    }

    const segmentObject = segmentValues.find(
      (seg) => seg.name === segmentValue && seg.market === marketValue,
    );

    Object.keys(contract.orderFields).forEach((key) => {
      const underlyingValues = [];

      Object.values(contract.orderFields[key].values || []).forEach((val) => {
        underlyingValues.push(val.name);
      });

      underlying[key] = [...new Set(underlyingValues)];
    });

    if (isObject(segmentObject) && isObject(segmentObject.defaultValues)) {
      Object.keys(segmentObject.defaultValues).forEach((key) => {
        if (standard) {
          // underlying[key] = [segment.defaultValues[key].name];
          underlying[key] = [segmentObject.defaultValues[key]];
        } else if (Object.prototype.hasOwnProperty.call(segmentObject, 'enumValues')) {
          if (Object.prototype.hasOwnProperty.call(segmentObject.enumValues, key)) {
            underlying[key] = Object.keys(segmentObject.enumValues[key]);
          }
        }
      });
    }

    if (segmentObject.enumValues.weightClass) {
      underlying.weightClass = Object.keys(segmentObject.enumValues.weightClass);
    }
    if (segmentObject.enumValues.location) {
      underlying.location = Object.keys(segmentObject.enumValues.location);
    } else {
    }

    const weeks = getAllContractWeeks();

    if (Object.prototype.hasOwnProperty.call(underlying, 'week')) {
      underlying.week = weeks.values;
    }
  }

  return underlying;
};

export const getUnderlyingOptions = (contract, segmentValue, standard) => {
  const underlying = {};

  const segmentValues = Object.values(contract.orderFields[contract.segment].values);

  const segmentObject = segmentValues.find((seg) => seg.name === segmentValue);

  Object.keys(contract.orderFields).forEach((key) => {
    const underlyingValues = [];

    Object.values(contract.orderFields[key].values || []).forEach((val) => {
      underlyingValues.push(val.name);
    });

    underlying[key] = underlyingValues;
  });

  Object.keys(segmentObject.defaultValues).forEach((key) => {
    if (standard) {
      // underlying[key] = [segment.defaultValues[key].name];
      underlying[key] = [segmentObject.defaultValues[key]];
    } else {
      const values = [...underlying[key]];
      if (values.indexOf(segmentObject.defaultValues[key]) === -1) {
        values.unshift(segmentObject.defaultValues[key]);
      }
      underlying[key] = [...values];
    }
  });

  const weeks = getAllContractWeeks();

  if (Object.prototype.hasOwnProperty.call.hasOwnProperty(underlying, 'week')) {
    underlying.week = weeks.values;
  }

  return underlying;
};

export const getBlotterFields = (contract, blotterType) => {
  const fields = [];

  if (isObject(contract) && isObject(contract.orderFields)) {
    Object.keys(contract.orderFields).forEach((key) => {
      if (contract.orderFields[key][blotterType].visible === true)
        fields.push(contract.orderFields[key]);
    });
  }

  Object.keys(orderFields).forEach((key) => {
    if (orderFields[key][blotterType].visible === true) fields.push(orderFields[key]);
  });

  return fields;
};

export const getFieldDisplayName = (field, contract) => {
  let displayName = '';
  Object.keys(contract.orderFields).forEach((orderField) => {
    if (contract.orderFields[orderField].accessor === field) {
      displayName = contract.orderFields[orderField].displayName;
    }
  });

  Object.keys(orderFields).forEach((orderField) => {
    if (orderFields[orderField].accessor === field) {
      displayName = orderFields[orderField].displayName;
    }
  });

  return displayName;
};

export const getCurrencyEnums = (contract) => {
  if (
    isObject(contract) &&
    isObject(contract.orderFields) &&
    isObject(contract.orderFields.currency) &&
    isObject(contract.orderFields.currency.values)
  ) {
    const currencies = Object.keys(contract.orderFields.currency.values);
    return currencies;
  }
  return [];
};
