// import teal from "@material-ui/core/colors/teal";
import red from '@material-ui/core/colors/red';
// import lightBlue from "@material-ui/core/colors/lightBlue";

import grey from '@material-ui/core/colors/grey';
import leroy from '../../assets/clientLogos/Leroy.png';
import wholeFoods from '../../assets/clientLogos/WholeFoods.png';
import seaborne from '../../assets/clientLogos/Seaborne.png';
import severn from '../../assets/clientLogos/SevernAndWye.png';
import bidCorp from '../../assets/clientLogos/BidCorp.jpg';
import bidFresh from '../../assets/clientLogos/BidFresh.jpg';
import coast from '../../assets/clientLogos/Coast.png';
import lfex from '../../assets/logos/LFEX-icon.png';
// import steinvik from "../../assets/clientLogos/Steinvik.png";

import iceland from '../../assets/clientLogos/IcelandIcon.png';
import scottish from '../../assets/clientLogos/ScottishIcon.png';
import morpol from '../../assets/clientLogos/morpolIcon.png';
import lelien from '../../assets/clientLogos/LeLienIcon.png';

import arnarlax from '../../assets/clientLogos/Arnalax.png';
import mandj from '../../assets/clientLogos/MandJ.png';
import morrisons from '../../assets/clientLogos/Morrisons.png';
import newEngland from '../../assets/clientLogos/NewEnglandSeafood.png';
import babord from '../../assets/clientLogos/Babord.png';
import youngs from '../../assets/clientLogos/Youngs.png';

import het from '../../assets/clientLogos/HetUrker.png';
import rode from '../../assets/clientLogos/RodeVis.jpg';
import visscher from '../../assets/clientLogos/Visscher.png';
import norwayRoyal from '../../assets/clientLogos/NorwayRoyalSalmon.png';
import scottishSalmon from '../../assets/clientLogos/ScottishSalmon.png';
import star from '../../assets/clientLogos/Star.png';
import sekkingstad from '../../assets/clientLogos/Sekkingstad.png';

export const getColor = (initial) => {
  switch (initial) {
    case 'A':
      return { color: '#E57373', image: 'none', initial };
    case 'B':
      return { color: '#D32F2F', image: 'none', initial };
    case 'C':
      return { color: '#EC407A', image: 'none', initial };
    case 'D':
      return { color: '#AB47BC', image: 'none', initial };
    case 'E':
      return { color: '#673AB7', image: 'none', initial };
    case 'F':
      return { color: '#5C6BC0', image: 'none', initial };
    case 'G':
      return { color: '#EF5350', image: 'none', initial };
    case 'H':
      return { color: '#3949AB', image: 'none', initial };
    case 'I':
      return { color: '#64B5F6', image: 'none', initial };
    case 'J':
      return { color: '#1E88E5', image: 'none', initial };
    case 'K':
      return { color: '#29B6F6', image: 'none', initial };
    case 'L':
      return { color: '#0288D1', image: 'none', initial };
    case 'M':
      return { color: '#00ACC1', image: 'none', initial };
    case 'N':
      return { color: '#4DD0E1', image: 'none', initial };
    case 'O':
      return { color: '#4DB6AC', image: 'none', initial };
    case 'P':
      return { color: '#00796B', image: 'none', initial };
    case 'Q':
      return { color: '#4CAF50', image: 'none', initial };
    case 'R':
      return { color: '#FFF176', image: 'none', initial };
    case 'S':
      return { color: '#FBC02D', image: 'none', initial };
    case 'T':
      return { color: '#FFD54F', image: 'none', initial };
    case 'U':
      return { color: '#FFA000', image: 'none', initial };
    case 'V':
      return { color: '#FFB74D', image: 'none', initial };
    case 'W':
      return { color: '#FF5722', image: 'none', initial };
    case 'X':
      return { color: '#795548', image: 'none', initial };
    case 'Y':
      return { color: '#90A4AE', image: 'none', initial };
    case 'Z':
      return { color: '#455A64', image: 'none', initial };

    default:
      return { color: grey, image: 'none', initial };
  }
};
export const getAvatarBackground = (organisation) => {
  const initial = organisation.length !== 0 ? organisation.charAt(0) : '';
  switch (organisation) {
    case 'ABJ':
      return { color: red[500], image: 'none', initial };

    case 'ARN':
      return { color: 'none', image: arnarlax, initial: '' };
    case 'BAB':
      return { color: 'none', image: babord, initial: '' };
    case 'BID':
      return { color: 'none', image: bidFresh, initial: '' };
    case 'BIDE':
      return { color: 'none', image: bidCorp, initial: '' };
    case 'COA':
      return { color: 'none', image: coast, initial: '' };
    case 'HET':
      return { color: 'none', image: het, initial: '' };
    case 'ICE':
      return { color: 'none', image: iceland, initial: '' };

    case 'LEL':
      return { color: 'none', image: lelien, initial: '' };
    case 'LER':
      return { color: 'none', image: leroy, initial: '' };
    case 'LFEX':
      return { color: 'none', image: lfex, initial };
    case 'MJS':
      return { color: 'none', image: mandj, initial: '' };
    case 'MOR':
      return { color: 'none', image: morpol, initial: '' };
    case 'MRR':
      return { color: 'none', image: morrisons, initial: '' };
    case 'NES':
      return { color: 'none', image: newEngland, initial: '' };

    case 'NRS':
      return { color: 'none', image: norwayRoyal, initial: '' };
    case 'ROD':
      return { color: 'none', image: rode, initial: '' };
    case 'SCO':
      return { color: 'none', image: scottish, initial: '' };

    case 'SEA':
      return { color: 'none', image: seaborne, initial: '' };
    case 'SEV':
      return { color: 'none', image: severn, initial: '' };

    case 'SCC':
      return { color: 'none', image: scottishSalmon, initial: '' };

    case 'SEK':
      return { color: 'none', image: sekkingstad, initial: '' };
    case 'STA':
      return { color: 'none', image: star, initial: '' };

    case 'VIS':
      return { color: 'none', image: visscher, initial: '' };
    case 'YNG':
      return { color: 'none', image: youngs, initial: '' };

    case 'WHO':
      return { color: 'none', image: wholeFoods, initial: '' };

    case 'LOGO':
      return { color: 'none', image: lfex, initial: '' };

    case 'SFF':
      return { color: '#1DE9B6', image: 'none', initial: 'S' };
    case 'BKB':
      return { color: '#01579B', image: 'none', initial: 'B' };
    case 'PUBLIC':
      return { color: '#212121', image: 'none', initial: 'Pu' };
    case 'ANON':
      return { color: 'none', image: 'none', initial: '', icon: 'person' };

    default:
      const c = getColor(initial);
      return c;
  }
};
