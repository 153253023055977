import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, Avatar } from '@material-ui/core';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';

const styles = (theme) => ({
  tableCellCalculator: {
    padding: 0,
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(0.5),

    //alignItems: "center",
    //backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    //alignItems: "center",
    //backgroundColor: 'rgba(0,0,0,.2)',
  },
  containerItems: {
    //padding: theme.spacing(1),
  },
  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
});

// TODO: Extract all functionality to saga ans store
class DetailsRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    const orderRow = this.props.orderRow || {};
    //const FIELD_WIDTH = '152px';
    const isOdd = this.props.index % 2 ? classes.containerOdd : classes.container;

    return (
      <div className={isOdd}>
        <div className={classes.containerItems}>
          <IconButton>
            <Avatar className={classes.small}>{this.props.index + 1}</Avatar>
          </IconButton>
        </div>
        <div className={classes.containerItems} style={{ flex: 1 }}>
          {Object.keys(orderRow).map((item) => {
            return item !== 'key' ? (
              <ReadOnlyField displayName={item} value={orderRow[item]} key={item} />
            ) : null;
          })}
        </div>
      </div>
    );
  }
}

DetailsRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailsRow);
