export const NAME = 'auth';

export const CHECK_AUTH = 'auth/check';
export const CHECK_AUTH_SUCCESS = 'auth/check/success';
export const CHECK_AUTH_ERROR = 'auth/check/error';

export const SIGN_IN = 'auth/signIn';
export const SIGN_IN_SUCCESS = 'auth/signIn/success';
export const SIGN_IN_ERROR = 'auth/signIn/error';

export const SIGN_IN_NEW_PASSWORD_SENT = 'auth/signIn/newPassword/sent';
export const SIGN_IN_NEW_PASSWORD_REQUIRED = 'auth/signIn/newPassword/required';

export const BACK_TO_SIGN_IN = 'auth/back/to/signIn';

export const SIGN_OUT = 'auth/signOut';
export const SIGN_OUT_SUCCESS = 'auth/signOut/success';
export const SIGN_OUT_ERROR = 'auth/signOut/error';

export const AUTH_UPDATE_DATA = 'auth/data/update';
export const AUTH_UPDATE_DATA_SUCCESS = 'auth/data/update/success';
export const AUTH_UPDATE_DATA_ERROR = 'auth/data/update/error';

export const CLIENT_PAGE_URL = '/client';
export const LOGIN_PAGE_URL = '/login';

export const UPDATE_SETTINGS = 'auth/settings/update';
export const UPDATE_SETTINGS_SUCCESS = 'auth/settings/update/success';
export const UPDATE_SETTINGS_ERROR = 'auth/settings/update/error';
