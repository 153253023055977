import all from './defaultWorkspaces/all';
import chartsAndData from './defaultWorkspaces/chartsAndData.js';
import orderManager from './defaultWorkspaces/orderManager.js';
import rfq from './defaultWorkspaces/rfq.js';

const getId = () => {
  return new Date().getTime().toString();
};

export const getDefaultWorkspaces = () => {
  return [all(), rfq(), chartsAndData(), orderManager(), blankWorkspace('', 'My Workspace')];
};
export const defaultWorkspace = (num) => {
  return all();
};

export const defaultWorkspaceOld = (num) => {
  return {
    name: 'Workspace' & num,
    active: true,
    layouts: [
      {
        w: 14,
        h: 16,
        x: 0,
        y: 0,
        i: '1585568190563',
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 16,
        x: 14,
        y: 0,
        i: '1585568194969',
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 16,
        x: 24,
        y: 0,
        i: '1585568205483',
        moved: false,
        static: false,
      },
      {
        w: 17,
        h: 32,
        x: 0,
        y: 16,
        i: '1585568239654',
        moved: false,
        static: false,
      },
      {
        w: 13,
        h: 16,
        x: 17,
        y: 16,
        i: '1585568259966',
        moved: false,
        static: false,
      },
      {
        w: 13,
        h: 16,
        x: 17,
        y: 32,
        i: '1585568281902',
        moved: false,
        static: false,
      },
      {
        w: 30,
        h: 13,
        x: 0,
        y: 48,
        i: '1585568296236',
        moved: false,
        static: false,
      },
      {
        w: 30,
        h: 12,
        x: 0,
        y: 61,
        i: '1585568305034',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'MARKETVIEWEXPANDED',
        id: 'market-view-expanded',
        title: 'Market View',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'TabIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1585568190563',
      },
      {
        type: 'CENTRALLIMITORDERBOOK',
        id: 'central-limit-order-book',
        title: 'Central Limit Order Book',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'TabIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 23,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1612382465961',
      },
      {
        type: 'CHAT',
        id: 'chat',
        title: 'Chat',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'ChatBubbleIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1585568194969',
      },
      {
        type: 'FXRATES',
        id: 'fx',
        title: 'FX Rates',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'EuroSymbolIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 6,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1585568205483',
      },
      {
        type: 'RFQ',
        id: 'rfq',
        title: 'RFQ',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'FormatQuoteIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1585568239654',
      },
      {
        type: 'CALCULATOR',
        id: 'calculator',
        title: 'Calculator',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'TableChartIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 12,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1585568259966',
      },
      {
        type: 'CHART',
        id: 'chart',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'BarChartIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {},
        i: '1585568281902',
      },
      {
        type: 'ORDERMANAGER',
        id: 'ordermanager',
        title: 'Order Manager',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'ViewListIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 16,
        h: 16,
        hasSearch: true,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {
          tabValue: 2,
          blotterSettings: {
            0: {
              columnState: [
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 65,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 88,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'originalVolume',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 94,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointFob',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointDdp',
                  hide: true,
                  aggFunc: null,
                  width: 264,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 112,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 86,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'orderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'anonymous',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'recipientList',
                  hide: true,
                  aggFunc: null,
                  width: 217,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'autoClose',
                  hide: true,
                  aggFunc: null,
                  width: 216,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'user.fullName',
                  hide: false,
                  aggFunc: null,
                  width: 102,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'organisation.shortName',
                  hide: false,
                  aggFunc: null,
                  width: 69,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeOfEntry',
                  hide: false,
                  aggFunc: null,
                  width: 163,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeOfUpdate',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'textMessage',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
              ],
              sortState: [],
              filterState: {},
              floatingFilter: false,
            },
            2: {
              columnState: [
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 104,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointFob',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointDdp',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 76,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 84,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 77,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'nominal',
                  hide: false,
                  aggFunc: null,
                  width: 128,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'status',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'rfqId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'ownUser',
                  hide: false,
                  aggFunc: null,
                  width: 122,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'ownCompany',
                  hide: false,
                  aggFunc: null,
                  width: 96,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'counterpartyUser',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'counterpartyCompany',
                  hide: false,
                  aggFunc: null,
                  width: 90,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'bidId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'offerId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'bidOrderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'offerOrderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'tradeId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeStamp',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
              ],
              sortState: [],
              filterState: {},
              floatingFilter: false,
            },
          },
        },
        i: '1585568305034',
      },
      {
        type: 'BLOTTERS',
        id: 'blotter',
        title: 'Order and Trade Blotters',
        icon: {
          type: {
            type: {},
            compare: null,
            displayName: 'ViewListIcon',
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        w: 16,
        h: 16,
        hasSearch: true,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
          _store: {},
        },
        savedState: {
          tabValue: 0,
          blotterSettings: {
            0: {
              columnState: [
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 65,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 88,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'originalVolume',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 94,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 93,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointFob',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointDdp',
                  hide: true,
                  aggFunc: null,
                  width: 264,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 112,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 86,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'orderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'anonymous',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'recipientList',
                  hide: true,
                  aggFunc: null,
                  width: 217,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'autoClose',
                  hide: true,
                  aggFunc: null,
                  width: 216,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'user.fullName',
                  hide: false,
                  aggFunc: null,
                  width: 102,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'organisation.shortName',
                  hide: false,
                  aggFunc: null,
                  width: 69,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeOfEntry',
                  hide: false,
                  aggFunc: null,
                  width: 163,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeOfUpdate',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'textMessage',
                  hide: false,
                  aggFunc: null,
                  width: 177,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
              ],
              sortState: [],
              filterState: {},
              floatingFilter: false,
            },
            2: {
              columnState: [
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 104,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointFob',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.deliveryPointDdp',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.packingStation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 76,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 84,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 82,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 78,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 77,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 92,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'nominal',
                  hide: false,
                  aggFunc: null,
                  width: 128,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.quality',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.underlying.state',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.instrument',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.week',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.harvestDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'status',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'rfqId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'ownUser',
                  hide: false,
                  aggFunc: null,
                  width: 122,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'ownCompany',
                  hide: false,
                  aggFunc: null,
                  width: 96,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'counterpartyUser',
                  hide: false,
                  aggFunc: null,
                  width: 99,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'counterpartyCompany',
                  hide: false,
                  aggFunc: null,
                  width: 90,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'bidId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'offerId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'bidOrderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'offerOrderGroupId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'tradeId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
                {
                  colId: 'timeStamp',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                },
              ],
              sortState: [],
              filterState: {},
              floatingFilter: false,
            },
          },
        },
        i: '1585568296236',
      },
    ],
    id: '1584791276557',
  };
};

export const defaultWorkspace2 = (num, title = 'Workspace') => {
  return {
    name: `Workspace${num}`,
    active: true,
    layouts: [
      {
        w: 24,
        h: 12,
        x: 0,
        y: 16,
        i: '1547560084709',
        moved: false,
        static: false,
      },
      {
        w: 14,
        h: 16,
        x: 0,
        y: 0,
        i: '1547560620194',
        moved: false,
        static: false,
      },

      {
        w: 10,
        h: 16,
        x: 14,
        y: 0,
        i: '1549294370353',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'BLOTTERS',
        id: 'blotters',
        title: 'Order and Trade Blotters',
        icon: { key: null, ref: null, props: {}, _owner: null, _store: {} },
        w: 24,
        h: 9,
        hasSearch: true,
        i: '1547560084709',
      },
      {
        type: 'MARKETVIEWEXPANDED',
        id: 'market-view-expanded',
        title: 'Market View Expanded',
        icon: { key: null, ref: null, props: {}, _owner: null, _store: {} },
        w: 8,
        h: 12,
        hasSearch: false,
        i: '1547560620194',
      },

      {
        type: 'CHAT',
        id: 'chat',
        title: 'Chat',
        icon: { key: null, ref: null, props: {}, _owner: null, _store: {} },
        w: 6,
        h: 18,
        hasSearch: false,
        component: {
          key: null,
          ref: null,
          props: {
            children: {
              key: null,
              ref: null,
              props: {},
              _owner: null,
              _store: {},
            },
          },
          _owner: null,
          _store: {},
        },
        i: '1549294370353',
      },
    ],
    id: getId(),
  };
};

export const blankWorkspace = (num, title = 'Workspace') => {
  return {
    name: `${title}${num}`,
    active: false,
    layouts: [],
    widgets: [],
    id: getId(),
  };
};
