import coreApi from '../../../core/services/api';

class Api {
  constructor() {
    this.baseUrl = `/market`;

    this.get = this.get.bind(this);
  }

  async get(marketId = null, data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(marketId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
