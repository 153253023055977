import { create } from '../../../core/actions';

export const getUsers = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getUser = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getProfile = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateUser = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const resetUsers = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const insertUsers = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateUsers = (type, payload, meta) => {
  return create(type, payload, meta);
};
