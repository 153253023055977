const chartsAndData = () => {
  return {
    name: 'Charts & Data',
    active: false,
    layouts: [
      {
        w: 9,
        h: 12,
        x: 9,
        y: 0,
        i: '1612380570818',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 12,
        x: 9,
        y: 12,
        i: '1612380572268',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 12,
        x: 0,
        y: 12,
        i: '1612380573009',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 15,
        x: 9,
        y: 24,
        i: '1612380573360',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 12,
        x: 18,
        y: 0,
        i: '1612380624326',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 12,
        x: 18,
        y: 12,
        i: '1612380624998',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 15,
        x: 18,
        y: 24,
        i: '1612777920982',
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 12,
        x: 5,
        y: 0,
        i: '1612777936837',
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 12,
        x: 0,
        y: 0,
        i: '1613495125029',
        moved: false,
        static: false,
      },
      {
        w: 9,
        h: 15,
        x: 0,
        y: 24,
        i: '1613506285941',
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 15,
        x: 0,
        y: 39,
        i: '1613507902556',
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 15,
        x: 12,
        y: 39,
        i: '1613509092265',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'CHART',
        id: '1612380570818',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'lfex_4w',
        },
        i: '1612380570818',
      },
      {
        type: 'CHART',
        id: '1612380572268',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'lfex',
        },
        i: '1612380572268',
      },
      {
        type: 'FXRATES',
        id: '1612777936837',
        title: 'FX Rates',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 6,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1612777936837',
      },
      {
        type: 'CHART',
        id: '1612777920982',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'fresh_frozen',
        },
        i: '1612777920982',
      },
      {
        type: 'LFEXPRICES',
        id: '1613495125029',
        title: 'LFEX Prices',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 6,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613495125029',
      },
      {
        type: 'CHART',
        id: '1613506285941',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'eurnok',
        },
        i: '1613506285941',
      },
      {
        type: 'CALCULATOR',
        id: '1613507902556',
        title: 'Calculator',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 12,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          calculatorId: 'kh0w3l3t',
          localPrice: '50',
          finalPrice: '4.87',
          quantity: '18500',
        },
        i: '1613507902556',
      },
      {
        type: 'CHART',
        id: '1612380573360',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'nasdaq',
        },
        i: '1612380573360',
      },
      {
        type: 'MARKETVIEWEXPANDED',
        id: '1613509092265',
        title: 'Market View',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 8,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1613509092265',
      },
      {
        type: 'CENTRALLIMITORDERBOOK',
        id: '1612382465961',
        title: 'Central Limit Order Book',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 21,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1612382465961',
      },
      {
        type: 'CHART',
        id: '1612380624998',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'fishpool',
        },
        i: '1612380624998',
      },
      {
        type: 'CHART',
        id: '1612380624326',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'fishpool_forwards',
        },
        i: '1612380624326',
      },
      {
        type: 'CHART',
        id: '1612380573009',
        title: 'Chart',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'eurnok_4w',
        },
        i: '1612380573009',
      },
    ],
    id: '1612380560223',
  };
};

export default chartsAndData;
