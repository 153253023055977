import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Avatar } from '@material-ui/core';

import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { directions } from '../../../../commons/config/constants';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.2)',
  },
  containerItems: {
    padding: theme.spacing(1),
  },

  rowMargin: {
    marginBottom: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
});

class ExecuteTradeFormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      showCalculator: false,
    };
  }

  getDirection = (direction) => {
    let value = directions.BUY;
    if (direction === directions.BUY) {
      value = directions.SELL;
    }
    return value;
  };

  render() {
    const { classes, orderRow } = this.props;
    const FIELD_WIDTH = '150px';
    const subSegment = { displayName: 'Weight Class', accessor: 'weightClass' };
    const formFields = this.props.formFields;
    const isOdd = this.props.index % 2 ? classes.containerOdd : classes.container;
    return (
      <div className={isOdd}>
        <div className={classes.containerItems}>
          <IconButton>
            <Avatar className={classes.small}>{this.props.index + 1}</Avatar>
          </IconButton>
        </div>
        <div className={classes.containerItems} style={{ flex: 1 }}>
          <ReadOnlyField
            displayName={subSegment.displayName}
            value={orderRow[subSegment.accessor]}
            width={FIELD_WIDTH}
          />

          {Object.keys(formFields).map((field) => {
            if (formFields[field].amendable) {
              return (
                <ReadOnlyField
                  displayName={formFields[field].displayName}
                  value={orderRow[field]}
                  width={FIELD_WIDTH}
                />
              );
            } else {
              return null;
            }
          })}
          {Object.keys(formFields).map((field) => {
            if (!formFields[field].amendable) {
              return (
                <ReadOnlyField
                  displayName={formFields[field].displayName}
                  value={orderRow[field]}
                  width={FIELD_WIDTH}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  }
}

ExecuteTradeFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExecuteTradeFormRow);
