export function filterObject(obj, filter, filter2, filterValue) {
  const filtered = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key][filter][filter2] === filterValue) {
      filtered[key] = obj[key];
    }
  });
  return filtered;
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isBoolean(obj) {
  return Object.prototype.toString.call(obj) === '[object Boolean]';
}

export function existId(objectItems, id) {
  return Object.prototype.hasOwnProperty.call(objectItems, id);
}

export function getId(item) {
  let id = null;

  if (typeof item.id !== 'undefined' && item.id !== null) {
    id = item.id;
  } else if (
    Array.isArray(item.orderData) &&
    item.orderData.length === 1 &&
    isObject(item.orderData[0]) &&
    typeof item.orderData[0].id !== 'undefined' &&
    item.orderData[0].id !== null
  ) {
    id = item.orderData[0].id;
  }

  return id;
}

export function insertItems(
  { items },
  {
    byId = {},
    allIds = [],
    lastCreatedTime: createdTime = null,
    lastUpdatedTime: updatedTime = null,
  },
  idKey = 'id',
) {
  const insertedById = { ...byId };
  const insertedAllIds = [...allIds];

  let lastCreatedTime = createdTime;
  let lastUpdatedTime = updatedTime;

  items.forEach((item) => {
    item.id = item[idKey]; // TODO! REFACTOR! TEMP SOLUTION!

    const id = getId(item);

    if (id !== null) {
      if (!existId(byId, id)) {
        insertedAllIds.push(id);
      }

      insertedById[id] = item;

      if (
        item.createdTime &&
        (lastCreatedTime === null || new Date(lastCreatedTime) < new Date(item.createdTime))
      ) {
        lastCreatedTime = item.createdTime;
      }

      if (
        item.updatedTime &&
        (lastUpdatedTime === null || new Date(lastUpdatedTime) < new Date(item.updatedTime))
      ) {
        lastUpdatedTime = item.updatedTime;
      }
    }
  });

  return {
    byId: insertedById,
    allIds: insertedAllIds,
    lastCreatedTime,
    lastUpdatedTime,
  };
}

export function updateItems({ items }, { byId, allIds }, idKey = 'id') {
  const updatedById = { ...byId };
  const updatedAllIds = [...allIds];

  items.forEach((item) => {
    item.id = item[idKey]; // TODO! REFACTOR! TEMP SOLUTION!

    const id = getId(item);

    if (id !== null) {
      if (existId(byId, id)) {
        updatedById[id] = { ...byId[id], ...item };
      }
    }
  });

  return {
    byId: updatedById,
    allIds: updatedAllIds,
  };
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const createId = (length=10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
