import { takeEvery } from 'redux-saga/effects';

import auth from '../../auth';

export function* watchGetMarket() {}
export function* watchResetMarket() {}
export function* watchInitMarket() {}
export function* watchDestroyMarket() {}
export function* watchChangeStateOrders() {}
export function* watchChangeStateContract() {}
function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
