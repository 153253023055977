import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Toolbar, AppBar, Tab, Tabs, Tooltip, IconButton, Icon } from '@material-ui/core';
const styles = (theme) => ({
  tab: {
    minWidth: '150px',
  },
  appBar: {
    flex: 1,
    height: '50px',
    maxHeight: '50px',
    minHeight: '50px',
  },
});

const MenuBar = (props) => {
  const { classes } = props;
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar style={{ minHeight: '0px' }}>
        <Tabs value={props.tabValue} onChange={props.handleTabChange} style={{ flexGrow: 1 }}>
          <Tab className={classes.tab} label="My Orders" />
          <Tab className={classes.tab} label="Orders Sent to Me" />
          <Tab className={classes.tab} label="My Trades" />
        </Tabs>
        <Tooltip title="Choose Columns" aria-label="Choose Columns">
          <IconButton onClick={props.handleColumnPickerOpen}>
            <Icon>view_column</Icon>
          </IconButton>
        </Tooltip>

        {/* <Tooltip title="Show Filters" aria-label="ShowFilters">
              <IconButton onClick={this.showFloatingFilter}>
                <Icon>filter_list</Icon>
              </IconButton>
            </Tooltip> */}
        <Tooltip title="Clear Filters" aria-label="ClearFilters">
          <IconButton onClick={props.clearFilters}>
            <Icon>clear_all</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Save Current Settings" aria-label="Save Current Settings">
          <IconButton onClick={props.handleSaveSettingsOpen}>
            <Icon>save</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip title="Choose Setting" aria-label="Choose Setting">
          <IconButton
            onClick={(e) => {
              props.handleChooseSettings(e);
            }}
          >
            <Icon>list</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Download" aria-label="Download">
          <IconButton onClick={props.printAllDisplayedRows}>
            <Icon>get_app</Icon>
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuBar);
