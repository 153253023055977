import coreApi from '../../../core/services/api';

class Api {
  constructor() {
    this.getNotificationTypes = this.getNotificationTypes.bind(this);
  }

  async getNotificationTypes() {
    const url = `/dictionaries/notificationTypes`;

    const params = {};

    const options = {};

    const response = await coreApi.create(url, params, options);

    if (response.ok) {
      const order = await response.json();
      return order;
    }

    throw new Error(`HTTP status ${response.status}`);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
