import React from 'react';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import BusinessIcon from '@material-ui/icons/FormatQuote';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import TabIcon from '@material-ui/icons/Tab';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewListIcon from '@material-ui/icons/ViewList';
import WavesIcon from '@material-ui/icons/Waves';

const MARKETVIEWEXPANDED = 'MARKETVIEWEXPANDED';
const CENTRALLIMITORDERBOOK = 'CENTRALLIMITORDERBOOK';
const BLOTTERS = 'BLOTTERS';
const CHAT = 'CHAT';
const ORDERMANAGER = 'ORDERMANAGER';
const CHART = 'CHART';
const RFQ = 'RFQ';
const FXRATES = 'FXRATES';
const LFEXPRICES = 'LFEXPRICES';
const CALCULATOR = 'CALCULATOR';
const ADMIN = 'ADMIN';
const FILE_UPLOAD = 'FILEUPLOAD';

export const widgets = [
  {
    type: RFQ,
    id: 'rfq',
    title: 'Request Quote',
    icon: <BusinessIcon />,
    w: 16,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: BLOTTERS,
    id: 'blotter',
    title: 'Order and Trade Blotters',
    icon: <ViewListIcon />,
    w: 16,
    h: 16,
    hasSearch: true,
    component: <div />,
    savedState: {},
  },
  {
    type: CHAT,
    id: 'chat',
    title: 'Chat',
    icon: <ChatBubbleIcon />,
    w: 8,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: MARKETVIEWEXPANDED,
    id: 'market-view-expanded',
    title: 'Market View',
    icon: <TabIcon />,
    w: 8,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: CENTRALLIMITORDERBOOK,
    id: 'central-limit-order-book',
    title: 'Central Limit Order Book',
    icon: <TabIcon />,
    w: 21,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: ORDERMANAGER,
    id: 'orderManager',
    title: 'Order Manager',
    icon: <PlaylistAddIcon />,
    w: 16,
    h: 24,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: CHART,
    id: 'chart',
    title: 'Chart',
    icon: <BarChartIcon />,
    w: 16,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },

  {
    type: FXRATES,
    id: 'fx',
    title: 'FX Rates',
    icon: <EuroSymbolIcon />,
    w: 6,
    h: 12,

    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: LFEXPRICES,
    id: 'lfex',
    title: 'LFEX Prices',
    icon: <WavesIcon />,
    w: 6,
    h: 12,

    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: CALCULATOR,
    id: 'calculator',
    title: 'Calculator',
    icon: <TableChartIcon />,
    w: 12,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: FILE_UPLOAD,
    id: 'file_upload',
    title: 'Files',
    icon: <CloudUploadIcon />,
    w: 12,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: ADMIN,
    id: 'admin',
    title: 'Admin',
    icon: <AccountTreeIcon />,
    w: 12,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
];

export const widgetComponent = (widgetType) => {
  const widget = widgets.find((item) => item.type === widgetType);
  return widget.component;
};

export const widgetTypes = {
  // MARKETVIEW,
  MARKETVIEWEXPANDED,
  CENTRALLIMITORDERBOOK,
  // SINGLEMARKET,
  BLOTTERS,
  CHAT,
  ORDERMANAGER,
  CHART,
  RFQ,
  FXRATES,
  LFEXPRICES,
  CALCULATOR,
  ADMIN,
  FILE_UPLOAD,
};
