import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import LoginPage from '../LoginPage';

import { getTheme } from '../../selectors';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  info: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    padding: '50px 10px',
  },

  button: {
    marginTop: theme.spacing.unit * 3,
  },

  infoButton: {
    color: 'rgb(79,161,202)',
    border: '1px solid',
    borderColor: theme.palette.divider,
    textDecoration: 'none',
    cursor: 'pointer',
    lineHeight: 1.5,
    padding: '16px',
  },
  logoContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
  },
  imageName: {
    height: '10vmin',
    margin: '15px 0px 35px 0px',
    flex: 1,
  },
  imageIcon: {
    flex: 1,
  },
  loginFields: {
    flex: 1,
  },
  loginField: {
    width: '100%',
    maxWidth: 500,
  },
  loginButton: {
    width: '100%',
    maxWidth: 500,
    marginTop: '30px',
    color: theme.palette.common.white,
  },
  fieldMargin: {
    margin: '30px 0px',
    padding: '0px 8px',
  },
  paddingRight: { paddingRight: '5px' },

  textFieldInputProps: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
});

class HomePage extends React.PureComponent {
  render() {
    return <LoginPage />;
  }
}

function mapStateToProps(state) {
  return {
    theme: getTheme(state),
  };
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(HomePage));
