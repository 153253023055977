import { create } from '../core/actions';

export const initProcess = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const initTheme = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const changeTheme = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const blotterSettings = (type, payload, meta) => {
  return create(type, payload, meta);
};
