import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Popover,
  Typography,
  Icon,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import UserAvatar from '../../../commons/components/userAvatar';
import { getOrganisationsObject } from '../selectors';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column', width: '640px' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
  },
  list: { flex: 1 },
  title: {
    fontSize: '16px',
    padding: theme.spacing(),
  },
});

const DistributionList = (props) => {
  const { classes, anchorEl, open, selectedOrganisationsIds, visibility } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={props.onClose}
    >
      <div className={classes.container}>
        <div className={classes.listContainer}>
          <Typography className={classes.title}>{'FILES SHARED WITH:'}</Typography>
          <List open classes={{ root: classes.list }}>
            {selectedOrganisationsIds.map((id) => {
              const org = props.organisations[id] || {};
              return (
                <ListItem key={org.id} button classes={{ root: classes.listItem }}>
                  <ListItemIcon>
                    <UserAvatar organisationId={org.id} />
                  </ListItemIcon>
                  <ListItemText primary={org.name} />
                  <ListItemText primary={org.shortName} align="right" />
                </ListItem>
              );
            })}
            {selectedOrganisationsIds.length === 0 && (
              <ListItem key={'visibility'} button classes={{ root: classes.listItem }}>
                <ListItemText primary={visibility} />
              </ListItem>
            )}
          </List>
        </div>
        <div className={classes.toolbarContainer}>
          {/*  <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.onClose} />
               
              </>
            </ToolbarBottom> */}
        </div>
      </div>
    </Popover>
  );
};

DistributionList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisationsObject(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DistributionList));
