import * as React from 'react';
import { connect } from 'react-redux';
import { BACK_TO_SIGN_IN, SIGN_IN_NEW_PASSWORD_SENT } from '../../constants';
import { signIn as actionSignIn } from '../../actions';
import Form from './RequireNewPassword';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
});

class RequireNewPassword extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleSubmit = (values) => {
    const { createNewPassword } = this.props;
    createNewPassword(values);
  };

  render() {
    const { classes, backToSignIn } = this.props;

    return (
      <div className={classes.main}>
        <Form onSubmit={this.handleSubmit} backToSignIn={backToSignIn} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  backToSignIn: (payload) => dispatch(actionSignIn(BACK_TO_SIGN_IN, payload)),
  createNewPassword: (payload) => dispatch(actionSignIn(SIGN_IN_NEW_PASSWORD_SENT, payload)),
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(RequireNewPassword));
