import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
    Modal,
    Typography,
    ListItem,
    ListItemText,
    ListItemIcon, Checkbox
} from '@material-ui/core';

import TextInputField from '../../../../commons/components/formFields/TextInputField';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import {

} from '../../selectors';
import {
    createAdminItem,
    updateAdminItem
} from '../../actions';
import {
    CREATE_ADMIN_ITEM,
    UPDATE_ADMIN_ITEM
} from '../../constants';


const ERROR_RECIPIENT = 'At least one organisation must be selected.';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const DEFAULT_PRICE = 5.6;

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        width: '80vw',
        maxHeight: '80vh',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        display: "flex",
        flexDirection: "column"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        marginTop: '10px',
        flex: 1,
        overflowY: "auto",
        overflowX: "hidden"
    },
    containerItems: {
        backgroundColor: theme.palette.primary.row,
        padding: '10px',
    },
    banner: {
        backgroundColor: theme.palette.primary.dark,
        padding: '5px',
        color: theme.palette.text.disabled,
    },
    title: { fontSize: theme.fontSize * 1.3 },

    subheader: {
        fontSize: ".75rem",
        color: theme.palette.text.secondary,
        padding: theme.spacing(1.5)
    }



});

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class UpdateOrganisation extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            shortName: "",
            tradingPartnersIds: [],
            nameError: { value: false, message: "" },
            shortNameError: { value: false, message: "" }
        };
    }

    componentDidMount() {
        if (this.props.organisation !== null && this.props.organisation !== undefined) {
            const { organisation } = this.props
            const { name, shortName, tradingPartnersIds } = organisation
            this.setState({ name, shortName, tradingPartnersIds })
        }
    }

    componentDidUpdate(prevProps) {

    }

    handleChange = (name) => (event) => {
        const value = event.target.value
        let error = { value: false, message: "" }
        if (value === "" || value === null || value === undefined) {
            error = { value: true, message: "Value is required" }
        }
        this.setState({ [name]: event.target.value, [`${name}Error`]: error })
    }

    handleChecked = (id) => () => {
        const checked = this.state.tradingPartnersIds
        const currentIndex = checked.indexOf(id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({ tradingPartnersIds: newChecked });
    }
    getErrors = () => {
        let hasErrors = false
        let { nameError, shortNameError } = this.state
        if (this.state.name === "" || this.state.name === null || this.state.name === undefined) {
            nameError = { value: true, message: "Value is required" }
            hasErrors = true
        } else {
            nameError = { value: false, message: "" }
        }
        if (this.state.shortName === "" || this.state.shortName === null || this.state.shortName === undefined) {
            shortNameError = { value: true, message: "Value is required" }
            hasErrors = true
        } else {
            shortNameError = { value: false, message: "" }
        }
        return { hasErrors, nameError, shortNameError }
    }
    send = () => {
        const errors = this.getErrors()
        if (!errors.hasErrors) {
            const name = this.state.name
            const shortName = this.state.shortName
            const tradingPartnersIds = this.state.tradingPartnersIds

            const payload = {
                type: "organisations",
                body: {
                    name,
                    shortName,
                    tradingPartnersIds
                },
                id: this.props.organisation.id
            }
           
            this.props.update(payload)
            this.props.closeForm()
        }
        this.setState({ nameError: errors.nameError, shortNameError: errors.shortNameError })
    }

    render() {
        const { classes, open, contract, user, organisations } = this.props;
        const { tradingPartnersIds } = this.state
        return (
            <div>
                <Modal open={open} onClose={this.closeForm}>
                    <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
                        <Typography className={classes.title}>EDIT ORGANISATION</Typography>
                        <div className={classes.container}>
                            <TextInputField
                                accessor="name"
                                displayName="Organisation Full Name"
                                value={this.state.name}
                                handleChange={this.handleChange}
                                error={this.state.nameError}
                                fullWidth
                            />
                            <TextInputField
                                accessor="shortName"
                                displayName="Organisation Short Name"
                                value={this.state.shortName}
                                handleChange={this.handleChange}
                                error={this.state.shortNameError}
                                fullWidth
                            />
                            <div className={classes.subheader} >Trading Partners</div>
                            <div style={{ overflowY: "scroll", flex: 1 }}>
                                {organisations.map(org => {
                                    const id = org.id
                                    const labelId = `checkbox-list-label-${id}`;
                                    const labelId2 = `checkbox-list-label2-${id}`;
                                    return (
                                        <ListItem key={id} role={undefined} dense button onClick={this.handleChecked(id)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={tradingPartnersIds.indexOf(org.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={org.name} />
                                            <ListItemText id={labelId2} primary={org.shortName} style={{ textAlign: "right" }} />
                                        </ListItem>
                                    )
                                })}
                            </div>

                        </div>
                        <ToolbarBottom>
                            <>
                                <CancelButton onClick={this.props.closeForm} />
                                <ConfirmButton onClick={this.send} title="Update" />
                            </>
                        </ToolbarBottom>
                    </div>
                </Modal>
            </div>
        );
    }
}

UpdateOrganisation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        update: (payload) => {
            dispatch(updateAdminItem(UPDATE_ADMIN_ITEM, payload));
        },
        create: (payload) => {
            dispatch(createAdminItem(CREATE_ADMIN_ITEM, payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateOrganisation));
