import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

import MomentUtils from '@date-io/moment';

import { textField, fieldFontSize } from './formFieldStyles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';

const styles = (theme) => ({
  textStyle: {
    ...textField(theme),
  },
  root: {
    '& .MuiFilledInput-input': {
      padding: '25px 12px 7px   !important',
    },
  },
  fieldFont: {
    ...fieldFontSize(theme),
    padding: 0,
  },
});

const DateField = (props) => {
  const { classes, accessor, displayName, disablePast } = props;
  const value = props.value || null;
  const defaultValue = props.defaultValue || null;
  let textStyle = {};
  let root = null;
  if (displayName.toUpperCase().includes('HARVEST')) {
    textStyle = { color: teal[300] };
  } else if (displayName.toUpperCase().includes('DELIVERY')) {
    if (!displayName.toUpperCase().includes('MAX')) {
      textStyle = { color: lightBlue[300], fontSize: '20px' };
      root = classes.root;
    } else {
      textStyle = { color: lightBlue[300] };
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        clearable={props.clearable}
        id={props.key}
        key={props.key}
        variant="filled"
        autoOk
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        className={classes.textStyle}
        classes={{ root: root }}
        disablePast={disablePast}
        label={displayName}
        format="DD/MM/YYYY"
        defaultValue={defaultValue}
        value={value}
        onChange={props.handleDateChange(accessor)}
        InputProps={{
          disableUnderline: true,
          className: classes.fieldFont,
          style: textStyle,
        }}
        fullWidth
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateField);
