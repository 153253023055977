import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Modal, Typography, InputBase } from '@material-ui/core';

import { directions } from '../../../../commons/config/constants';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import { getContractWeek } from '../../../contracts/utils/deliveryWeeks';

import ExecuteTradeFormRow from './ExecuteTradeFormRow';
import TotalsRow from '../TotalsRow';

import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';
import orders from '../../../orders';
import {
  isOpenFormExecute,
  getRfqExecuteForm,
  getOrdersExecuteForm,
  getExecuteFormFields,
} from '../../selectors';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
  },
  section: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.row,
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
  title: { fontSize: theme.fontSize * 1.5 },
});

class ExecuteTradeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      orderRows: [],
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChecked = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleDateChange = (name) => (date) => {
    if (name === 'harvestDate') {
      const week = getContractWeek(new Date(date));

      this.setState({
        harvestDate: date,
        week,
      });
    } else {
      this.setState({
        [name]: date,
      });
    }
  };

  submit = () => {
    this.sendOrder();
  };

  getDirection = (direction) => {
    let value = directions.BUY;
    if (direction === directions.BUY) {
      value = directions.SELL;
    }
    return value;
  };

  sendOrder = () => {
    let orderRows = this.props.orders;

    if (true) {
      const createOrderPayload = {
        orderRows,
        textMessage: this.state.textMessage,
      };

      this.props.createOrder(createOrderPayload);
      this.setState({ textMessage: '' });
      this.closeForm();
    }
  };

  closeForm = () => {
    const payload = {
      type: 'execute',
      action: 'closeForm',
    };

    this.props.updateResponseForm(payload);
  };

  render() {
    const { classes, orders, open, formFields } = this.props;
    const orderData = orders || [];
    const order = orders[0] || {};
    const direction = order.direction || directions.BUY;

    return (
      <Modal open={open} onClose={this.closeForm}>
        <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
          <Typography className={classes.title}>EXECUTE?</Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <Typography className={classes.banner}>ORDER TYPE</Typography>
              <div className={classes.subsection}>
                <ReadOnlyField displayName={'Direction'} value={direction} fullWidth />
              </div>
            </div>
            <div className={classes.section}>
              <Typography className={classes.banner}>Comments</Typography>
              <div className={classes.subsection}>
                <InputBase
                  value={this.state.textMessage}
                  type="text"
                  label="Comments"
                  placeholder="Comments"
                  multiline
                  fullWidth
                  onChange={(e) => {
                    this.setState({ textMessage: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={classes.sectionFlex}>
              <Typography
                className={classes.banner}
              >{`ORDER (${orderData.length} items)`}</Typography>
              <div className={classes.orders}>
                {orderData.map((row, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ExecuteTradeFormRow
                        key={row.id}
                        orderRow={row}
                        contract={this.props.contract}
                        activeRfq={this.props.activeRfq}
                        formFields={formFields}
                        index={index}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
              <div className={classes.subsection}>
                <TotalsRow rows={orderData} direction={direction} />
              </div>
            </div>
          </div>
          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeForm} />
              <ConfirmButton onClick={() => this.submit()} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

ExecuteTradeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrdersExecuteForm(state),
    rfq: getRfqExecuteForm(state),
    formFields: getExecuteFormFields(state),
    open: isOpenFormExecute(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createOrder: (payload) => {
      dispatch(orders.actions.createOrder(orders.constants.CREATE_ORDER_FROM_RFQ, payload));
    },
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecuteTradeForm));
