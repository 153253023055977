import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';
import {
  Modal,
  TextField,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { directions} from '../../../../commons/models/constants';
import { idGenerator } from '../../../../commons/config/functions';

import { getContractWeek } from '../../../../commons/config/deliveryWeeks';
import AmendMultipleOrderFormRow from './AmendMultipleOrderFormRow';
import RfqTotalsRow from '../../../rfqs/components/TotalsRow';

/*import {
  createNewOrder, // TODO:Remove
  amendMultipleOrderFormToggle,
  amendOrder, // TODO:Remove
  createOrder, // NEW
  updateOrder,
} from '../../../../redux/actions';*/

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import { UPDATE_AMEND_MULTIPLE_ORDER_FORM, SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM } from '../../constants';
import { updateForm, updateOrders } from '../../actions';

//

//import {isOpenFormAmend ,getRfqAmendForm ,getOrdersAmendForm ,getAmendFormFields} from '../../selectors'


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 200,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  test: { color: 'green', minHeight: '56px' },
  textField: {},

  textFieldSell: {
    color: teal[300],
  },
  textFieldBuy: {
    color: lightBlue[500],
  },
  dense: {
    marginTop: 19,
  },

  container: { marginTop: '10px' },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.text.disabled,
  },
  title: { fontSize: theme.fontSize * 1.5 },

  iconButton: {
    paddingLeft: '0px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  tableCell: {
    padding: '16px 16px 16px 0px',
    flex: 1,
    maxWidth: '200px',
    minWidth: '200px',
    minHeight: '253.33px',
  },
  checkboxLabel: {
    marginLeft: 0,
    maxWidth: '200px',
  },
  customTextField: {},
});

class AmendMultipleOrderForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      recipientList: [],
      organisations: [],

      orderRows: [],
      errorRecipients: { error: '', value: false },
      defaultCalculatorId: null
    };
  }

  componentDidMount() {

    this.propsToState();

  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      this.propsToState();
    }

  }

  propsToState = () => {

    let direction = "BUY"
    let orderRows = [];
    let hasOrders = false;

    if (this.props.order) {
      if (this.props.order !== null) {
        hasOrders = true;
      }
    }

    if (hasOrders === true) {
      direction = this.props.order.orderData[0].direction
      this.props.order.orderData.forEach((o) => {
        const key = idGenerator();

        const newOrder = JSON.parse(JSON.stringify(o));
        const originalOrder = JSON.parse(JSON.stringify(o));

        orderRows.push({
          key,
          order: newOrder,
          originalOrder,
        });
      });

    }

    this.setState({
      direction,
      orderRows,
    });
  };

  updateOrderRow = (orderRow) => {
    const orderRows = [...this.state.orderRows];
    const index = orderRows.findIndex((row) => row.key === orderRow.key);
    orderRow[index] = orderRow;

    this.setState({ orderRows });
  };


  updateDefaultCalculatorId = (id) => {
    this.setState({ defaultCalculatorId: id });
  };


  handleChange = (name) => (event) => {
    switch (name) {




      default:
        this.setState({
          [name]: event.target.value,
        });
    }
  };

  handleChecked = (name) => (event) => {
    const value = event.target.checked;
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (name) => (date) => {
    if (name === 'harvestDate') {
      const week = getContractWeek(new Date(date));
      this.setState({
        harvestDate: date,
        week,
      });
    } else {
      this.setState({
        [name]: date,
      });
    }
  };

  submit = () => {
    this.sendAmend();
  };

  sendAmend = () => {
    // TODO:NEED OPTIMIZATION FOR MULTI UPDATE ORDERS AFTER ITERATIONS: EXTRACT INTO METHOD


    const orderId = this.props.order.id
    const amendOrder = {
      orderId,
      status: this.props.order.status,
      orderData: []
    }

    this.state.orderRows.forEach((row) => {
      const obj = {
        id: row.order.id,
        price: parseFloat(row.order.price),
        volume: parseInt(row.order.volume, 10),

      };
      amendOrder.orderData.push(obj)
    });
    const payload = { amendOrder }
    this.props.amendOrder(payload)
    this.onClose(null);
  };



  // getOrgansisations = () => {
  //   let orgs = this.props.organisations;

  //   if (this.props.activeRfq === undefined || this.props.activeRfq === null) {
  //   } else if (
  //     this.props.activeRfq.visibility === visibilities.SELECTED_ORGANISATIONS ||
  //     this.props.activeRfq.visibility === visibilities.TRADING_PARTNERS
  //   ) {
  //     orgs = [];
  //     this.props.activeRfq.recipientList.forEach((org) => {
  //       const obj = getOrganisationObjectFromId(org, this.props.organisations);
  //       orgs.push(obj);
  //     });
  //   }

  //   return orgs;
  // };

  getTotals = (orderRows) => {


    let totalVolume = 0;

    let totalNominal = 0;
    let avgPrice = 0;
    const direction = 'BUY';
    const unit = this.props.order !== null && this.props.order !== undefined ? this.props.order.orderData[0].contract.underlying.unit : 'KG';
    const currency = this.props.order !== null && this.props.order !== undefined ? this.props.order.orderData[0].contract.underlying.currency : '';

    if (orderRows.length !== 0) {
      const volumePerUnit = unit === 'BOX' ? 20 : 1;

      orderRows.forEach((row) => {
        const vol = Number(row.order.volume);
        const pri = Number(row.order.price);
        totalVolume += vol;

        const nominal = vol * volumePerUnit * pri;
        totalNominal += nominal;
      });
      avgPrice = totalNominal / totalVolume / volumePerUnit || 0;
    }

    return {
      volume: totalVolume,
      avgPrice,
      nominal: totalNominal,
      direction,
      currency,
      unit,
    };
  };

  onClose = () => {


    const payload = {
      item: null,
      action: 'amend',
      rfq: null,
      open: false,
      error: null,
      errors: {}
    };

    this.props.onClose(payload);
  };

  render() {
    const { classes } = this.props;

    const direction = [];
    direction.push(this.state.direction);

    const totals = this.getTotals(this.state.orderRows);

    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.onClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography className={classes.title}>AMEND RESPONSE</Typography>

            <div className={classes.container}>
              <Typography className={classes.banner}>ORDER TYPE</Typography>
              <div className={classes.containerItems}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        classes={{ root: classes.tableCell }}
                        style={{ borderBottomWidth: 0 }}
                      >
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                            className:
                              this.state.direction === directions.BUY
                                ? classes.textFieldBuy
                                : classes.textFieldSell,
                          }}
                          id="direction"
                          select
                          fullWidth
                          variant="filled"
                          label="Direction"
                          value={this.state.direction}
                          onChange={() => void 0}
                        >
                          {direction.map((d, index) => (
                            <MenuItem key={index} value={d}>
                              {d}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className={classes.container}>
              <Typography className={classes.banner}>ORDER</Typography>
              <div className={classes.containerItems}>
                {this.state.orderRows.map((row) => (
                  <AmendMultipleOrderFormRow
                    key={row.key}
                    orderRow={row}
                    updateOrderRow={this.updateOrderRow}
                    contract={this.props.contract}
                  // updateDefaultCalculatorId={this.updateDefaultCalculatorId}
                  // defaultCalculatorId={this.state.defaultCalculatorId}
                  />
                ))}

                <RfqTotalsRow totals={totals} />
              </div>
            </div>

            <ToolbarBottom>
              <>
                <CancelButton onClick={() => this.onClose()} />
                <ConfirmButton onClick={() => this.submit()} />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
      </div>
    );
  }
}

AmendMultipleOrderForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  return {
    orders: [],//getOrdersAmendForm(state),
    //formFields: getAmendFormFields(state),
    //open: isOpenFormAmend(state)
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onClose: (payload) => {
      dispatch(updateForm(UPDATE_AMEND_MULTIPLE_ORDER_FORM, payload));
    },
    amendOrder: (payload) => {
      dispatch(updateOrders(SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM, payload))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AmendMultipleOrderForm));
