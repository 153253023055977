import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { withStyles } from '@material-ui/core/styles';
import Row from './Row';
import UserAvatar from '../../../commons/components/userAvatar';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ReactJson from 'react-json-view';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  listText: {
    color: theme.palette.text.hint,
  },
});

class UserRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { classes, user, organisationsObject } = this.props;
    const orgId = user.organisationId;
    const organisation = organisationsObject[orgId];

    return (
      <Row
        onClick={() => this.setState({ expanded: !this.state.expanded })}
        expanded={this.state.expanded}
        title={`${user.firstName} ${user.lastName}`}
        edit={() => this.props.editUser(user)}
        loggedIn={user.connectionState}
      >
        <React.Fragment>
          <ListItem key={`${user.id}-organisationName`} button classes={{ root: classes.listItem }}>
            <ListItemText primary={'Organisation'} classes={{ root: classes.listText }} />
            <ListItemText primary={organisation.name} align="right" />
          </ListItem>
          <ListItem
            key={`${user.id}-organisationShortName`}
            button
            classes={{ root: classes.listItem }}
          >
            <ListItemText
              primary={'Organisation Short Name'}
              classes={{ root: classes.listText }}
            />
            <ListItemText primary={organisation.shortName} align="right" />
          </ListItem>
          {Object.keys(user).map((item) => {
            if (this.state.expanded) {
              return item !== 'appSettings' ? (
                <ListItem key={`${user.id}-${item}`} button classes={{ root: classes.listItem }}>
                  <ListItemText primary={item} classes={{ root: classes.listText }} />
                  <ListItemText primary={JSON.stringify(user[item])} align="right" />
                </ListItem>
              ) : (
                <React.Fragment>
                  <ListItem key={`${user.id}-${item}`} button classes={{ root: classes.listItem }}>
                    <ListItemText primary={item} classes={{ root: classes.listText }} />
                  </ListItem>{' '}
                  <ReactJson src={JSON.parse(user[item])} collapsed={true} theme={'harmonic'} />
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </React.Fragment>
      </Row>
    );
  }
}

UserRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserRow);
