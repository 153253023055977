import { create } from '../../../core/actions';

export const initChart = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const destroyChart = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateChart = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const initTable = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const destroyTable = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateTable = (type, payload, meta) => {
  return create(type, payload, meta);
};
