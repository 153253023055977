import React, { useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = () => {
  return {
    cell: {
      minWidth: '100%',
      minHeight: '100%',
      textAlign: 'right',
      padding: '6px',
      background: 'green',
    },
    increased: {
      background: `rgba(50, 150, 50, 0)`,
      animationName: '$fade-increased',
      animationDuration: '2400ms',
      animationTimingFunction: 'cubic-bezier(1, 0.02, 1, 0.02)',
    },
    decreased: {
      background: `rgba(150, 50, 50, 0)`,
      animationName: '$fade-decreased',
      animationDuration: '2400ms',
      animationTimingFunction: 'cubic-bezier(1, 0.02, 1, 0.02)',
    },
    unchanged: {
      background: 'transparent',
    },
    '@keyframes fade-increased': {
      '0%': {
        backgroundColor: `rgba(50, 150, 50, 1)`,
      },
      '100%': {
        backgroundColor: `rgba(50, 150, 50, 0)`,
      },
    },
    '@keyframes fade-decreased': {
      '0%': {
        backgroundColor: `rgba(150, 50, 50, 1)`,
      },
      '100%': {
        backgroundColor: `rgba(150, 50, 50, 0)`,
      },
    },
  };
};

const Cell = ({ children, highlight, classes }) => {
  if (!children) return <TableCell />;
  const [classNames, setClassNames] = useState('transparent');

  useEffect(() => {
    setClassNames(
      clsx({
        [classes.cell]: true,
        [classes.decreased]: highlight === 0,
        [classes.unchanged]: highlight === 1,
        [classes.increased]: highlight === 2,
      }),
    );

    const handler = setTimeout(() => {
      setClassNames(
        clsx({
          [classes.cell]: true,
          [classes.unchanged]: true,
        }),
      );
    }, 2500);

    return function cleanup() {
      clearTimeout(handler);
    };
  }, [highlight, children]);

  return (
    <TableCell style={{ padding: 0, boxSizing: 'border-box' }}>
      <div className={classNames}>{children}</div>
    </TableCell>
  );
};

Cell.propTypes = {
  classes: PropTypes.shape({
    cell: PropTypes.string.isRequired,
    increased: PropTypes.string.isRequired,
    decreased: PropTypes.string.isRequired,
    unchanged: PropTypes.string.isRequired,
    '@keyframes fade-increased': PropTypes.string.isRequired,
    '@keyframes fade-decreased': PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
  highlight: PropTypes.number.isRequired,
};

export default withStyles(styles)(Cell);
