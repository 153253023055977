import { takeEvery, call, put, select, fork } from 'redux-saga/effects';

import {
  GET_FXRATES_SUCCESS,
  GET_FXRATES_ERROR,
  GET_FXRATE_SUCCESS,
  GET_FXRATE_ERROR,
  GET_FXRATES,
  RESET_FXRATES,
  GET_FXRATE,
  INSERT_FXRATES_FROM_SOCKET,
  INSERT_FXRATES_FROM_SOCKET_SUCCESS,
  INSERT_FXRATES_FROM_SOCKET_ERROR,
  UPDATE_FXRATES_FROM_SOCKET,
  UPDATE_FXRATES_FROM_SOCKET_SUCCESS,
  UPDATE_FXRATES_FROM_SOCKET_ERROR,
  INIT_FXRATE_SUCCESS,
  INIT_FXRATE_ERROR,
  DESTROY_FXRATE_SUCCESS,
  DESTROY_FXRATE_ERROR,
  UPDATE_FXRATE_ERROR,
  UPDATE_FXRATE_SUCCESS,
  INIT_FXRATE,
  DESTROY_FXRATE,
  UPDATE_FXRATE,
} from '../constants';

import {
  getFxrates,
  getFxrate,
  resetFxrates,
  insertFxrates,
  initFxrate as actionInitFxrate,
  destroyFxrate as actionDestroyFxrate,
  updateFxrate as actionUpdateFxrate,
} from '../actions';

import api from '../api';
import auth from '../../auth';

import { isObject } from '../../../core/utils/functions';

import {
  getAll as getAllFxrates,
  getActiveContract,
  getAllLocalFxrates,
  getLastUpdatedTime,
} from '../selectors';

import contracts from '../../contracts';
import { SOCKET_RECONNECTED } from '../../../core/constants';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const fxrates = yield call(api.getAll, options);

    const payload = {
      items: fxrates,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getFxrates(GET_FXRATES_SUCCESS, payload, meta));

    yield fork(processFxrates);
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getFxrates(GET_FXRATES_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    const fxrate = yield call(api.get);

    const payload = {
      items: [fxrate],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getFxrate(GET_FXRATE_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getFxrate(GET_FXRATE_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* insertFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(insertFxrates(INSERT_FXRATES_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(insertFxrates(INSERT_FXRATES_FROM_SOCKET_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* updateFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(insertFxrates(UPDATE_FXRATES_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(insertFxrates(UPDATE_FXRATES_FROM_SOCKET_ERROR, { error }));
  }
}

function* initFxrate(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    const currencyPairs = [];

    const contract = yield select(getActiveContract);

    if (contract) {
      let currencies = [];

      if (
        isObject(contract.orderFields) &&
        isObject(contract.orderFields.currency) &&
        isObject(contract.orderFields.currency.values)
      ) {
        currencies = Object.keys(contract.orderFields.currency.values);
      }

      currencies.forEach((curr) => {
        currencies.forEach((c) => {
          if (c !== curr) {
            const currencyPair1 = `${c}/${curr}`;
            const currencyPair2 = `${curr}/${c}`;
            if (currencyPairs.indexOf(currencyPair1) === -1) {
              currencyPairs.push(currencyPair1);
            }
            if (currencyPairs.indexOf(currencyPair2) === -1) {
              currencyPairs.push(currencyPair2);
            }
          }
        });
      });
    }

    if (currencyPairs.length > 0) {
      const fxrates = yield select(getAllFxrates);

      if (fxrates.length > 0) {
        const items = [];

        fxrates.forEach((fxrate) => {
          fxrate.rates.forEach((r) => {
            const currency = `${fxrate.baseCurrency}/${r.quoteCurrency}`;

            if (currencyPairs.includes(currency)) {
              const { rate } = r;
              items.push({ currency, rate });
            }
          });
        });

        payload.items = items;
      }
    } else {
      payload.items = [];
    }

    if (isObject(widget)) {
      payload.widget = widget;
    }

    payload.currencyPairs = currencyPairs;

    yield put(actionInitFxrate(INIT_FXRATE_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionInitFxrate(INIT_FXRATE_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* destroyFxrate(action) {
  try {
    const payload = {
      id: action.payload.id,
    };

    const meta = { receivedAt: new Date() };

    yield put(actionDestroyFxrate(DESTROY_FXRATE_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionDestroyFxrate(DESTROY_FXRATE_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* updateFxrate(action) {
  try {
    const {
      payload: { id},
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    yield put(actionUpdateFxrate(UPDATE_FXRATE_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionUpdateFxrate(UPDATE_FXRATE_ERROR, { error }, { receivedAt: new Date() }));
  }
}

/**
 *
 * @param {*} action
 */
function* reset() {
  yield put(resetFxrates(RESET_FXRATES));
}

export function* watchGetFxrates() {
  yield takeEvery(GET_FXRATES, getAll);
}

export function* watchGetFxrate() {
  yield takeEvery(GET_FXRATE, get);
}

export function* watchInsertFxratesFromSocket() {
  yield takeEvery(INSERT_FXRATES_FROM_SOCKET, insertFromSocket);
}

export function* watchUpdateFxratesFromSocket() {
  yield takeEvery(UPDATE_FXRATES_FROM_SOCKET, updateFromSocket);
}

export function* watchResetFxrates() {
  yield takeEvery(RESET_FXRATES, reset);
}

export function* watchInitFxrate() {
  yield takeEvery(INIT_FXRATE, initFxrate);
}

export function* watchDestroyFxrate() {
  yield takeEvery(DESTROY_FXRATE, destroyFxrate);
}

export function* watchUpdateFxrate() {
  yield takeEvery(UPDATE_FXRATE, updateFxrate);
}

function* changeState(action) {
  try {
    const {
      payload: { source },
    } = action;

    if (source === 'contract') {
      yield fork(processFxrates);
    }
  } catch (error) {
    yield put(actionUpdateFxrate(UPDATE_FXRATE_ERROR, { error }, { receivedAt: new Date() }));
  }
}

export function* watchChangeStateContract() {
  yield takeEvery(contracts.constants.CHANGE_STATE_CONTRACT, changeState);
}

// TODO: DUPLICATE FUNCTIONALIT IN INIT AND processFxrates. Extract in single method
function* processFxrates() {
  try {
    const contract = yield select(getActiveContract);

    if (isObject(contract)) {
      const localFxrates = yield select(getAllLocalFxrates);

      for (let i = 0; i < localFxrates.length; i += 1) {
        const fxrate = localFxrates[i];

        const payload = { id: fxrate.id };
        const meta = { receivedAt: new Date() };

        const currencyPairs = [];

        let currencies = [];

        if (
          isObject(contract.orderFields) &&
          isObject(contract.orderFields.currency) &&
          isObject(contract.orderFields.currency.values)
        ) {
          currencies = Object.keys(contract.orderFields.currency.values);
        }

        currencies.forEach((curr) => {
          currencies.forEach((c) => {
            if (c !== curr) {
              const currencyPair1 = `${c}/${curr}`;
              const currencyPair2 = `${curr}/${c}`;
              if (currencyPairs.indexOf(currencyPair1) === -1) {
                currencyPairs.push(currencyPair1);
              }
              if (currencyPairs.indexOf(currencyPair2) === -1) {
                currencyPairs.push(currencyPair2);
              }
            }
          });
        });

        if (currencyPairs.length > 0) {
          const fxrates = yield select(getAllFxrates);

          if (fxrates.length > 0) {
            const items = [];

            fxrates.forEach((fxr) => {
              fxr.rates.forEach((r) => {
                const currency = `${fxr.baseCurrency}/${r.quoteCurrency}`;

                if (currencyPairs.includes(currency)) {
                  const { rate } = r;
                  items.push({ currency, rate });
                }
              });
            });

            payload.items = items;
          }
        } else {
          payload.items = [];
        }

        payload.currencyPairs = currencyPairs;

        yield put(actionUpdateFxrate(UPDATE_FXRATE_SUCCESS, payload, meta));
      }
    }
  } catch (error) {
    yield put(actionUpdateFxrate(UPDATE_FXRATE_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
