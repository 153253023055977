import { create } from '../../../core/actions';

export const getRFQs = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getRFQ = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const createRFQ = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateRFQs = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const insertRFQs = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const resetRFQs = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateForm = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateRFQViews = (type, payload, meta) => {
  return create(type, payload, meta);
};
