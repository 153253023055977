import React from 'react';
import PropTypes, { object } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';

import { getWeek } from '../../contracts/utils/deliveryWeeks';
import { formatDate } from '../../../commons/config/formatters';
import { isObject } from '../../../core/utils/functions';

const styles = (theme) => ({
  grid: {
    //padding: theme.spacing.unit / 2,
  },

  buyer: {
    color: lightBlue[300],
    //fontSize: theme.fontSize.md,
    fontWeight: 500,
  },
  seller: {
    color: teal[300],
    //fontSize: theme.fontSize.md,
    fontWeight: 500,
  },

  user: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100px',
    color: 'rgb(225,225,225)',
    //fontSize: theme.fontSize.sm,
    padding: 0,
  },
  avatar: {
    padding: theme.spacing.unit / 2,
  },
  details: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  topRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  volume: {
    //fontSize: theme.fontSize.sm,
    color: '#fff',
    marginLeft: theme.spacing.unit,
  },
});
const getData = (data) => {
  const obj = {
    direction: data.direction || '',
    volume: data.volume || '',
  };
  let extensions = { rfqType: 'SPOT' };
  if (data.extensions) {
    let obj = JSON.parse(data.extensions);
    if (isObject(obj)) extensions = obj;
  }

  const { rfqType, frequency, numberOfPeriods } = extensions;

  if (Object.prototype.toString.call(data.contract) === '[object Object]') {
    if (Object.prototype.toString.call(data.contract.harvestDate) === '[object Object]') {
      const minWeek = getWeek(new Date(data.contract.harvestDate.from));
      const maxWeek = getWeek(new Date(data.contract.harvestDate.to));
      let week;
      if (rfqType === 'CONTRACT') {
        week = `${frequency}-${numberOfPeriods}-DELIVERIES`;
      } else {
        if (minWeek === maxWeek) {
          week = `Week ${minWeek}`;
        } else {
          week = `Week ${minWeek}-${maxWeek}`;
        }
      }
      obj.week = week;
    }

    const { underlying } = data.contract;

    if (Object.prototype.toString.call(underlying) === '[object Object]') {
      obj.product = underlying.product;
      obj.location = underlying.locations[0];
      obj.deliveryPoint = underlying.deliveryPoints[0];
      obj.weightClass = underlying.weightClasses.join(' , ');
      obj.currency = underlying.currency;
      obj.unit = underlying.unit;
      obj.state = ` - ${underlying.states.join(', ')}`;
      obj.quality = underlying.quality ? underlying.quality.join(', ') : '';
      obj.delivery = formatDate(data.contract.deliveryDate.from);
      obj.rfqType = rfqType;
      obj.weightClassUnit = underlying.weightClasses[0].includes('LBS')
        ? ' '
        : underlying.weightClasses.includes('ANY')
        ? underlying.unit
        : 'KG';
    }
  }

  return obj;
};

const CallOut = (props) => {
  const { classes } = props;
  const data = getData(props.data);
  const buyerTitleClass = data.direction.includes('BUY') ? classes.buyer : classes.seller;
  const deliveryColor = data.direction.includes('BUY') ? lightBlue[500] : teal[500];

  return (
    <Grid container className={classes.grid} justify="center" alignItems="center">
      <div className={classes.details}>
        <div className={classes.topRow}>
          <div className={buyerTitleClass}>
            {data.direction.includes('BUY') ? 'BUYER' : 'SELLER'}
          </div>
          <div className={classes.volume}>
            {data.volume} {data.unit} {data.currency}
          </div>
        </div>

        <div className={classes.user}>
          {data.product || 'SALMON'} {data.location} {data.deliveryPoint}
        </div>
        {data.rfqType !== 'VAP' && data.rfqType !== 'VAPCONTRACT' && (
          <div className={classes.user}>
            {data.weightClass} {data.weightClassUnit}
          </div>
        )}

        <div className={classes.user}>
          {data.quality} {data.state}
        </div>
        <div className={classes.user}>
          {data.rfqType}-{' '}
          <span style={{ color: deliveryColor, paddingLeft: '2px' }}>{data.delivery}</span>
        </div>
      </div>
    </Grid>
  );
};

CallOut.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CallOut);
