import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  grid: {
    display:"flex",
    flexDirection:"column",
    paddingBottom:theme.spacing()
  },
  row:{
    paddingTop:theme.spacing(.5),
    flex:1,
    display:"flex",
    flexDirection:"row",
    justifyContent: "space-between",
    alignItems:"center"
  },
  caption:{
    padding:theme.spacing(1),
    flex:1,
    display:"flex",
    flexDirection:"row",
    justifyContent: "center",
    alignItems:"center",
    fontSize: theme.fontSize.md,
    color:theme.palette.text.primary,
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.text.secondary
  },
  key:{
    paddingRight: theme.spacing(.5),
    paddingLeft: theme.spacing(.5),
    fontSize: theme.fontSize.sm,
    color: theme.palette.text.secondary
  },
  value:{
    paddingRight: theme.spacing(.5),
    paddingLeft: theme.spacing(.5),
    fontSize: theme.fontSize.sm,
    color: theme.palette.text.primary
  }
});

const UserInfo = (props) => {
  const { classes } = props;

  return (
    <div className={classes.grid}>
      <div className={classes.caption}>
        CLICK FOR MORE DETAIL
      </div>
      <div className={classes.row}>
        <div className={classes.key}>User:</div>
        <div className={classes.value}>{props.userName}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.key}>Organisation:</div>
        <div className={classes.value}>{props.organisationName}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.key}>Org. Short Name:</div>
        <div className={classes.value}>{props.organisationShortName}</div>
      </div>
   
    </div>
  );
};

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserInfo);
