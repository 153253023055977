import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  IconButton,
  Icon,
  Button,
  TextField,
} from '@material-ui/core';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';

import { newCalculator } from '../config/calculators';

import { isOpenManagerCalculator, getCalculatorsFromSettings } from '../selectors';
import { calculatorManager, updateCalculators } from '../actions';
import { CALCULATOR_MANAGER, UPDATE_CALCULATORS } from '../constants';

const styles = (theme) => ({
  title: { fontSize: theme.fontSize * 1.5 },
  container: { backgroundColor: 'rgba(0,0,0,.1)' },
  containerItems: { textAlign: 'center' },
  banner: { backgroundColor: 'rgba(0,0,0,.3)', padding: '5px' },
  textField: { marginTop: '8px', marginBottom: '8px' },
  fab: { marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit },
  nameChange: {
    color: 'rgba(0,0,0,.3)',
    display: 'block',
    padding: '0px 25px',
  },
  nameChangeInput: { display: 'block' },
});

class ManageCalculators extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
      showText: -1,
      calculators: [],
    };
  }

  componentDidMount() {
    this.propsToState();
  }

  // TODO: EXTRACT TO SAGA
  componentDidUpdate(prevProps, prevState) {
    if (this.props.calculators !== prevProps.calculators) {
      this.propsToState();
    }
  }

  propsToState = () => {
    const { calculators } = JSON.parse(JSON.stringify(this.props));
    this.setState({ calculators });
  };

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      checked: newChecked,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  add = () => {
    const i = this.state.calculators.length + 1;
    const calculators = [...this.state.calculators];
    calculators.push(newCalculator(i));

    this.setState({ calculators });
  };

  delete = (id) => {
    let active = false;

    if (this.state.calculators.length > 1) {
      const calculators = [];
      this.state.calculators.forEach((w) => {
        if (w.id !== id) {
          calculators.push(w);
        } else {
          active = w.active;
        }
      });
      if (active) {
        calculators[0].active = true;
      }

      this.setState({ calculators });
    }
  };

  onCopy = (e, id) => {
    const calculators = [...this.state.calculators];
    const num = calculators.length;
    const i = calculators.findIndex((w) => w.id === id);
    const newCalc = newCalculator(num);

    newCalc.calculatorState = JSON.parse(JSON.stringify(calculators[i].calculatorState));

    newCalc.name = `${calculators[i].name} (COPY)`;
    newCalc.active = false;
    calculators.push(newCalc);
    this.setState({ calculators });
  };

  onSave = () => {
    const payload = {
      action: 'onlySave',
      items: this.state.calculators,
    };

    this.props.updateCalculators(payload);
    this.close();
  };

  onEditClick = (id) => {
    if (this.state.showText === id) {
      this.setState({ showText: -1 });
    } else {
      this.setState({ showText: id });
    }
  };

  onNameChange = (e, id) => {
    const calculators = [...this.state.calculators];
    const i = calculators.findIndex((w) => w.id === id);
    calculators[i].name = e.target.value;
    this.setState({ calculators });
  };

  close = () => {
    const payload = {
      action: 'closeManager',
    };

    this.props.closeCalculatorManager(payload);
  };

  render() {
    const { classes, open } = this.props;
    const { calculators, checked, showText } = this.state;

    return (
      <PopUp open={open} title="Calculators">
        <div>
          <div className={classes.container}>
            <div className={classes.containerItems}>
              <List className={classes.root}>
                {calculators.map((value, index) => (
                  <div key={index}>
                    <ListItem
                      key={value.id}
                      role={undefined}
                      dense
                      button
                      onClick={this.handleToggle(value.id)}
                    >
                      <Checkbox checked={checked.includes(value.id)} tabIndex={-1} disableRipple />
                      <ListItemText primary={value.name} />

                      <ListItemSecondaryAction>
                        <Tooltip title="COPY" aria-label="COPY">
                          <IconButton onClick={(e) => this.onCopy(e, value.id)}>
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="RENAME" aria-label="RENAME">
                          <IconButton onClick={() => this.onEditClick(value.id)}>
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="DELETE" aria-label="DELETE">
                          <IconButton onClick={() => this.delete(value.id)}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {showText === value.id && (
                      <div>
                        <TextField
                          classes={{ root: classes.nameChange }}
                          InputProps={{ className: classes.nameChangeInput }}
                          onChange={(e) => this.onNameChange(e, value.id)}
                          placeholder="add New Name"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </List>
            </div>
          </div>

          <Button aria-label="Delete" className={classes.fab} onClick={this.add}>
            <Icon className={classes.extendedIcon}>add</Icon>
            <Typography>Add New Calculator</Typography>
          </Button>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.close} />
              <ConfirmButton onClick={this.onSave} />
            </>
          </ToolbarBottom>
        </div>
      </PopUp>
    );
  }
}
ManageCalculators.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

function mapStateToProps(state) {
  return {
    calculators: getCalculatorsFromSettings(state), // state.userSettings.calculators,
    open: isOpenManagerCalculator(state), // state.userSettings.manageCalculatorsVisible,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeCalculatorManager: (payload) => {
      dispatch(calculatorManager(CALCULATOR_MANAGER, payload));
    },
    updateCalculators: (payload) => {
      dispatch(updateCalculators(UPDATE_CALCULATORS, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageCalculators));
