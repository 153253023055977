import lightBlue from '@material-ui/core/colors/lightBlue';
// import { rgbToHex } from "@material-ui/core/styles/colorManipulator";

export const dark = {
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue['400'],
      },
    },
    MuiPickersDay: {
      // day: {
      //   color: lightBlue.A700,
      // },
      isSelected: {
        backgroundColor: lightBlue['400'],
      },
      current: {
        color: lightBlue['900'],
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '2px',
        marginBottom: '2px',
      },
    },
    MuiInput: {
      input: {
        underline: {
          '&:after': {
            borderBottom: `0px !important`,
          },
        },
      },
      formControl: {
        'label + &': {
          marginTop: '20px',
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        fontSize: '16px',
        padding: 2,
        '&$focused': {
          // increase the specificity for the pseudo class
          color: 'rgba(225,225,255,.8)',
        },
      },
      shrink: {
        transform: 'translate(6px, 6px) scale(0.85) !important',
      },
    },
    MuiInputAdornment: {
      root: {
        '& [class*="MuiTypography-root"]': {
          fontSize: '0.7rem',
          whiteSpace: 'normal',
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    // PrivateSwitchBase :{
    //   root:{
    //     padding:0
    //   }
    // },

    MuiChip: {
      root: {
        minHeight: '18px',
        height: 'none',
        fontSize: '14px',
      },
    },
    MuiAvatar: {
      img: {
        backgroundColor: 'transparent',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: lightBlue[700],
        rippleBackgroundColor: lightBlue[700],
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
        fontSize: '1.2rem',
      },
      popper: {
        // backgroundColor: lightBlue[400],
        // rippleBackgroundColor: lightBlue[400],
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'rgb(255,255,255)',
      },
    },
    /*  MuiIconButton: {
      root: {
        padding: '4px',
      },
    }, */
    MuiToolbar: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px',
        '@media (min-width: 600px)': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
  },

  palette: {
    type: 'dark',

    background: {
      paper: 'rgb(39, 49, 66)',
      default: 'rgb(30,40,54)',
      widget: 'rgb(48,60,78)',
      appBar: 'rgb(48,60,78)',
      banner: 'rgb(26,32,43)',
      rfqTabBuy: 'rgb(24,59,85)',
      rfqTabSell: 'rgb(24,56,64)',
      userTab: 'rgb(30,40,54)',
      tile: 'rgb(0,0,0,.2)',
      row: 'rgb(30,40,54)',
    },
    common: { black: '#000', white: '#fff' },
    primary: {
      main: 'rgb(41,50,65)',
      dark: 'rgb(26,32,43)',
      // light: "rgba(30, 40, 54 ,1)",
      row: 'rgb(30,40,54)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgb(255, 138, 123)',
      main: 'rgb(219, 89, 79)',
      dark: 'rgb(164, 39, 38)',
      contrastText: '#fff',
    },

    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },

    text: {
      primary: '#fff',
      primaryLight: 'rgb(225,225,225)',
      opacity: 'rgb(255,255,255,.3)',
      secondary: 'rgba(225,225,255,.50)',
      disabled: 'rgba(93, 117, 152, .87)',
      hint: 'rgba(93, 117, 152 ,1)',
      subtitle: '#546A8A',
    },
    divider: 'rgba(255,255,255,.1)',
    tableBorder: 'rgba(255,255,255,.05)',
    scrollbar: ' rgb(63, 71, 83)',
    chatMessages: 'rgb(240,240,240)',
  },

  fontSize: {
    xxxl: '3.0rem',
    xxl: '2.0rem',
    xl: '1.5rem',
    lg: '1.25rem',
    md: '1.0rem',
    sm: '.875rem',
    xs: '.75rem',
    xxs: '.5rem',
  },
  typography: {
    useNextVariants: true,
    //fontSize: 11,
    //htmlFontSize: 10,
  },
};
