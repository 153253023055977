import { takeEvery, call, put, select } from 'redux-saga/effects';

import {
  RESET_USERS,
  UPDATE_USER,
  GET_USER,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  GET_USERS_SUCCESS,
  INSERT_USERS_FROM_SOCKET,
  UPDATE_USERS_FROM_SOCKET,
  UPDATE_USERS_FROM_SOCKET_SUCCESS,
  UPDATE_USERS_FROM_SOCKET_ERROR,
  INSERT_USERS_FROM_SOCKET_SUCCESS,
  INSERT_USERS_FROM_SOCKET_ERROR,
} from '../constants';

import {
  getUsers,
  getUser,
  updateUser,
  insertUsers as actionInsertUsers,
  updateUsers as actionUpdateUsers,
} from '../actions';

import api from '../api';

import auth from '../../auth';
import { SOCKET_RECONNECTED } from '../../../core/constants';

import { getLastUpdatedTime } from '../selectors';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const users = yield call(api.getAll, options);

    const payload = {
      items: users,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getUsers(GET_USERS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getUsers(GET_USERS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    const user = yield call(api.get);

    const payload = {
      items: [user],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getUser(GET_USER_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getUser(GET_USER_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    const ownUserId = yield select(auth.selectors.getUserId);
    const { userId, body } = action.payload;
    const items = {
      userId: userId === ownUserId ? null : userId,
      token,
      body,
    };
    const user = yield call(api.update, items);

    const payload = {
      items: [user],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(updateUser(UPDATE_USER_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork
    if (!checkedError) {
      yield put(updateUser(UPDATE_USER_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* updateUsersFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateUsers(UPDATE_USERS_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateUsers(UPDATE_USERS_FROM_SOCKET_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* insertUsersFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionInsertUsers(INSERT_USERS_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionInsertUsers(INSERT_USERS_FROM_SOCKET_ERROR, { error }));
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getAll);
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, get);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, update);
}

export function* watchResetUsers() {
  yield takeEvery(RESET_USERS);
}

export function* watchInsertUsersFromSocket() {
  yield takeEvery(INSERT_USERS_FROM_SOCKET, insertUsersFromSocket);
}

export function* watchUpdateUsersFromSocket() {
  yield takeEvery(UPDATE_USERS_FROM_SOCKET, updateUsersFromSocket);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
