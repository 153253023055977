import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppMenu from '../AppMenu';

import GridLayout from './GridLayout';

import { initProcess as actionInitProcess } from '../../actions';
import { INIT_PROCESS } from '../../constants';

import users from '../../../modules/users';
import organisations from '../../../modules/organisations';
import orders from '../../../modules/orders';
import contracts from '../../../modules/contracts';
import market from '../../../modules/market';
import workspaces from '../../../modules/workspaces';
import calculators from '../../../modules/calculators';
import rfqs from '../../../modules/rfqs';
import fxrates from '../../../modules/fxrates';
import trades from '../../../modules/trades';
import admin from '../../../modules/admin';
import auth from '../../../modules/auth';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    marginLeft: '55px',
    marginTop: '65px',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '12px',
      height: '12px',
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.scrollbar,
      borderRadius: '20px',
    },
    '*::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class ClientPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notification: '',
      notificationVisible: false,
      notificationError: false,
      orderVisible: false,
      rfqVisible: false,
      rfqOrder: {},
      currentOrder: {},
      matchIds: [],
      hasError: false,
      initReady: false,
      workspacesReady: false,
      marketReady: false,
      contractReady: false,
      calculatorsReady: false,
      usersReady: false,
      organisationsReady: false,
    };
  }

  componentDidMount() {
    // TODO: Temporary solution. Extract to saga and invoke makrotasks(create method for generate settimeout operation)

    const {
      init,
      initWorkspace,
      initOrdersSettings,
      initCalculatorsSettings,
      getContract,
      getMarket,
      getUsers,
      getOrganisations,
      getOrders,
      getRFQs,
      getFXRates,
      getTrades,
      getAdmins,
    } = this.props;

    setTimeout(() => {
      init();
      this.setState({ initReady: true });
    }, 0);

    setTimeout(() => {
      initWorkspace();
      this.setState({ workspacesReady: true });
    }, 0);

    setTimeout(() => {
      getContract();
      this.setState({ contractReady: true });
    }, 0);

    setTimeout(() => {
      getMarket();
      this.setState({ marketReady: true });
    }, 0);

    setTimeout(() => {
      initOrdersSettings();
    }, 0);

    setTimeout(() => {
      initCalculatorsSettings();
      this.setState({ calculatorsReady: true });
    }, 0);

    setTimeout(() => {
      getFXRates();
    }, 0);

    setTimeout(() => {
      getUsers();
      this.setState({ usersReady: true });
    }, 0);

    setTimeout(() => {
      getOrganisations();
      this.setState({ organisationsReady: true });
    }, 0);

    setTimeout(() => {
      getOrders();
    }, 0);

    setTimeout(() => {
      getRFQs();
    }, 0);
    setTimeout(() => {
      getTrades();
    }, 0);
    if (this.props.isAdmin) {
      setTimeout(() => {
        getAdmins();
      }, 0);
    }
  }

  resetMatchIds = () => {
    this.setState({ matchIds: [] }, () => this.setState({ orderVisible: true }));
  };

  onCurrentOrderChange = (currentOrder, matchIds, open) => {
    this.setState({ currentOrder, matchIds }, () => this.setState({ orderVisible: open }));
  };

  showOrderEntry = () => {
    this.setState({ orderVisible: !this.state.orderVisible });
  };

  showRfqEntry = (order) => {
    this.setState({ rfqOrder: order }, () => this.setState({ rfqVisible: !this.state.rfqVisible }));
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    // if (this.state.hasError) {
    //   return <h1>Something went wrong.</h1>;
    // }
    if (
      !this.state.workspacesReady ||
      !this.state.marketReady ||
      !this.state.contractReady ||
      !this.state.calculatorsReady ||
      !this.state.initReady ||
      !this.state.organisationsReady ||
      !this.state.usersReady
    ) {
      return <CircularProgress />;
    }

    return (
      <div className={this.props.classes.main}>
        <AppMenu />
        <GridLayout
          showRfqEntry={this.showRfqEntry}
          onCurrentOrderChange={this.onCurrentOrderChange}
          onTradeClick={this.props.onTradeClick}
        />
        <orders.components.forms.CreateOrder />
        <orders.components.forms.AmendOrderForm />
        <orders.components.forms.AmendMultipleOrderForm />
        <orders.components.ManageOrderLists />
        <rfqs.components.forms.Form />
        <rfqs.components.forms.ResponseForm />
        <rfqs.components.forms.AmendForm />
        <rfqs.components.forms.ExecuteTradeForm />
        <workspaces.components.ManageWorkspaces />
        <calculators.components.ManageCalculators />
        <trades.components.TradeDetails />
        <trades.components.PostTradeForm />
        <trades.components.EndRfqPrompt />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAdmin: auth.selectors.isAdmin(state),
  };
};
function mapDispatchToProps(dispatch) {
  return {
    init: () => dispatch(actionInitProcess(INIT_PROCESS)),
    getUsers: () => dispatch(users.actions.getUsers(users.constants.GET_USERS)),
    getOrganisations: () =>
      dispatch(organisations.actions.getOrganisations(organisations.constants.GET_ORGANISATIONS)),
    getOrders: () => dispatch(orders.actions.getOrders(orders.constants.GET_ORDERS)),
    getRFQs: () => dispatch(rfqs.actions.getRFQs(rfqs.constants.GET_RFQS)),
    getContract: () => dispatch(contracts.actions.getContract(contracts.constants.GET_CONTRACT)),
    getMarket: () => dispatch(market.actions.getMarket(market.constants.GET_MARKET)),
    getFXRates: () => dispatch(fxrates.actions.getFxrates(fxrates.constants.GET_FXRATES)),
    getTrades: () => dispatch(trades.actions.getTrades(trades.constants.GET_TRADES)),
    getAdmins: () => dispatch(admin.actions.getAdmins(admin.constants.GET_ADMINS)),
    initOrdersSettings: () =>
      dispatch(orders.actions.initOrdersSettings(orders.constants.INIT_ORDERS_SETTINGS)),
    initCalculatorsSettings: () =>
      dispatch(
        calculators.actions.initCalculatorsSettings(
          calculators.constants.INIT_CALCULATORS_SETTINGS,
        ),
      ),
    initWorkspace: () =>
      dispatch(workspaces.actions.initWorkspace(workspaces.constants.INIT_WORKSPACE)),
    onTradeClick: () => {},
  };
}

ClientPage.propTypes = {
  classes: PropTypes.object.isRequired,
  init: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientPage));
