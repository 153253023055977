import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Collapse, Grid } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import { darken } from '@material-ui/core/styles/colorManipulator';

import { getTotals } from '../../../commons/utils/functions';
import { directions, status, avatarSize } from '../../../commons/models/constants';
import UserAvatar from '../../../commons/components/userAvatar';

import GridTile from './GridTile';
import ResponseDetail from './ResponseDetail';
import TotalsRow from './TotalsRow';
import Toolbar from './Toolbar';
import RecipientsBar from './RecipientsBar';

/*import { actionTypes } from '../../../redux/constants';

import {
  updateChatViews,
  createChatRoom,
  rfqResponseFormToggle,
  amendOrderStatus, // TODO:Remove
  updateOrder, // NEW
} from '../../../redux/actions';*/
import { formatDateTime } from '../../../commons/config/formatters';

import {
  getActiveContract,
  getActiveUser,
  getOrganisations,
  getOrganisationsObject,
  getUsersObject,
  getTradingPartners,
} from '../selectors';
import orders from '../../orders';

import chat from '../../chat';

// TODO: EXTRACT ALL FUNCTIONALITY INTO SAGA and STORE
class GridRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatVisible: false,
      showDetail: false,
    };
  }

  getOrdersBySegment = (wgt, responses) => {
    const orders = [...responses]
      //   // .filter(rsp => rsp.organisation.shortName === org)
      .filter((rsp) => rsp.contract.underlying.weightClass === wgt);
    return orders;
  };
  getOrderDirection = (responses) => {
    const direction = responses[0].direction;
    return direction;
  };

  massActivate = () => {
    this.changeStatus(status.ACTIVE);
  };

  massSuspend = () => {
    this.changeStatus(status.SUSPENDED);
  };

  massCancel = () => {
    this.changeStatus(status.CANCELLED);
  };

  changeStatus = (status) => {
    const orderId = this.props.orderGroup.id;
    const amendOrder = {
      orderId,
      status,
      orderData: this.props.orderGroup.orderData,
    };
    const payload = { amendOrder };
    this.props.amendOrder(payload);
  };

  chat = (room) => {
    const { activeRfq, user, orderGroup } = this.props;
    if (!room.orderId) {
      const counterpartyOrgId =
        activeRfq.createdByUserId === user.id
          ? orderGroup.organisationId
          : activeRfq.organisationId;

      const originator = user.organisationId;

      const payload = {
        name: `RFQ ${activeRfq.direction} ${activeRfq.contract.week}`,
        type: 'RFQ',
        organisationIds: [originator, counterpartyOrgId],
        orderId: orderGroup.id,
        callback: this.toogleChat,
      };

      this.props.createRfqChat(payload);
    } else {
      this.toogleChat();
    }
  };

  // TODO. CRITICAL!!! NEED REMOVE AND EXTRACT ALL FUNCTIONALITY FROM RFQ IN SAGA. ONLY FOR RELEASE. AFTER RELEASE NEED REFACTOR.
  toogleChat = () => {
    if (!this.state.chatVisible) {
      const room = this.getRoom(this.props.chats);
      const payload = {
        action: 'markAsRead',
        items: [{ id: room.id }],
      };
      this.props.updateChats(payload);
    }
    this.setState({ chatVisible: !this.state.chatVisible });
  };

  getRoom = (rooms) => {
    const { activeRfq, orderGroup, user } = this.props;
    const counterpartyOrgId =
      activeRfq.createdByUserId === user.id ? orderGroup.organisationId : activeRfq.organisationId;

    const room = rooms.find((item) => {
      return item.orderId === orderGroup.id && item.organisationIds.includes(counterpartyOrgId);
    });

    return room;
  };

  getUserDataFromUserId = (userId, orgId) => {
    const userData = {
      userName: '',
      organisationName: '',
      organisationShortName: '',
      userId,
      organisationId: orgId,
    };

    if (this.props.usersObject[userId]) {
      const user = this.props.usersObject[userId];
      userData.userName = `${user.firstName} ${user.lastName}`;
    }

    if (this.props.organisationsObject[orgId]) {
      const org = this.props.organisationsObject[orgId];
      userData.organisationName = org.name;
      userData.organisationShortName = org.shortName;
    }

    return userData;
  };

  // TODO: REFACTOR! MODIFIED PARAMS!
  isAmendable = (isMyOrder, status) => {
    let amendable = true;
    if (
      status === 'CANCELLED' ||
      status === 'FILLED' ||
      status === 'IN-PROGRESS' ||
      isMyOrder === false
    ) {
      amendable = false;
    }
    return amendable;
  };

  isTradable = (isCompanyOrder, status, isTradingPartner) => {
    let tradable = true;

    if (
      status === 'CANCELLED' ||
      status === 'SUSPENDED' ||
      status === 'FILLED' ||
      status === 'IN-PROGRESS' ||
      isCompanyOrder ||
      !isTradingPartner
    ) {
      tradable = false;
    }
    return tradable;
  };
  isTradingPartner = (orgId) => {
    let value = false;
    this.props.tradingPartners.forEach((t) => {
      if (t.id === orgId) {
        value = true;
      }
    });
    return value;
  };

  render() {
    const {
      classes,
      weightClasses,
      chats,
      orderGroup,
      user,
      activeRfq,
      interest,
      organisationsObject,
      organisations,
    } = this.props;

    const room = this.getRoom(chats) || {};

    const isCompanyOrder = orderGroup.organisationId === user.organisationId;
    const isMyOrder = orderGroup.createdByUserId === user.id;
    const isTradingPartner = this.isTradingPartner(orderGroup.organisationId);

    const visibility = isCompanyOrder ? orderGroup.visibility : '';

    const { status: orderStatus, orderData } = orderGroup;
    const totals = getTotals(orderData, orderStatus);

    totals.isCompanyOrder = isCompanyOrder;
    totals.isMyOrder = isMyOrder;
    totals.amendable = this.isAmendable(isMyOrder, orderStatus);
    totals.tradable = this.isTradable(isCompanyOrder, orderStatus, isTradingPartner);
    totals.status = orderStatus;

    const selectedOrganisationsIds = orderGroup.selectedOrganisationsIds || [];
    const timeStamp =
      orderGroup.updatedTime === null
        ? formatDateTime(orderGroup.createdTime)
        : formatDateTime(orderGroup.updatedTime);

    let borderColor = totals.direction === directions.SELL ? teal[300] : lightBlue[300];

    if (totals.status === status.SUSPENDED || totals.status === status.IN_PROGRESS) {
      borderColor = amber[300];
    }

    if (totals.status === status.CANCELLED) {
      borderColor = red[300];
    }

    if (totals.status === status.FILLED) {
      borderColor = 'rgba(255,255,255,.1)';
    }

    const userData = this.getUserDataFromUserId(
      orderGroup.createdByUserId,
      orderGroup.organisationId,
    );
    const hasChat =
      orderGroup.organisationId === user.organisationId ||
      activeRfq.organisationId === user.organisationId;
    const isChatVisible = this.state.chatVisible;
    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.row}>
            <div
              className={classes.column1}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({ showDetail: true });
              }}
            >
              <UserAvatar {...userData} size={avatarSize.SMALL} tooltip />
            </div>
            <div className={classes.column2}>
              <div className={classes.gridTile} style={{ borderColor }}>
                {weightClasses.map((wgt, index) => {
                  const orders = this.getOrdersBySegment(wgt, orderGroup.orderData);
                  const last = index === weightClasses.length - 1;
                  const direction = this.getOrderDirection(orderGroup.orderData);
                  return (
                    <React.Fragment key={index}>
                      <GridTile orders={orders} last={last} totals={totals} direction={direction} />
                    </React.Fragment>
                  );
                })}
              </div>
              <Toolbar
                totals={totals}
                massCancel={this.massCancel}
                massSuspend={this.massSuspend}
                massActivate={this.massActivate}
                counter={this.props.addResponseForm}
                amend={this.props.addAmendForm}
                trade={this.props.addTradeForm}
                chat={() => this.chat(room)}
                rfqIsActive={activeRfq.status === 'ACTIVE'}
                hasChat={hasChat}
                interest={interest}
                numberOfMessages={room.newMessages}
                orderGroup={orderGroup}
              />

              <RecipientsBar
                recipientList={totals.isCompanyOrder ? selectedOrganisationsIds : []}
                organisations={organisations}
                organisation={organisationsObject[user.organisationId]}
                totals={totals}
                timeStamp={timeStamp}
                visibility={visibility}
              />

              <TotalsRow totals={totals} />
            </div>
          </div>
        </div>
        <Collapse in={isChatVisible}>
          <Grid container className={classes.chatContainer}>
            <Grid className={classes.chat} item xs={12} align="center">
              <chat.components.ActiveMessaging
                selectedChatId={room.id}
                isChatVisible={isChatVisible}
              />
            </Grid>
          </Grid>
        </Collapse>
        <ResponseDetail
          open={this.state.showDetail}
          onClose={() => this.setState({ showDetail: false })}
          orderGroup={orderGroup}
          activeRfq={activeRfq}
          userData={userData}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  wrapper: { display: 'flex', flex: 1, flexDirection: 'column' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column1: {
    display: 'flex',
    flex: 1,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  column2: {
    flex: 10,
    borderWidth: '1px',
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    paddingRight: theme.spacing.unit / 2,
  },
  column3: {
    flex: 1,
    padding: 4,
  },
  text: {
    textAlign: 'center',
    color: theme.palette.text.subtitle,
    flex: 1,
  },
  subheaders: {
    flexDirection: 'row',
    borderWidth: '1px',
    borderColor: theme.palette.divider,
    display: 'flex',
    height: 32,
    backgroundColor: darken(theme.palette.background.banner, 0.1),
  },
  gridTile: {
    flexDirection: 'row',
    border: '1px solid',
    borderWidth: '.1em',
    borderColor: theme.palette.divider,
    borderBottomWidth: 0,
    display: 'flex',
    height: 32,
  },
  chatContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  chat: {
    height: '500px',
  },
});

const mapStateToProps = (state) => {
  return {
    contract: getActiveContract(state),
    user: getActiveUser(state),
    organisations: getOrganisations(state), // TODO: temporary for demo
    organisationsObject: getOrganisationsObject(state),
    chats: chat.selectors.getChats(state),
    usersObject: getUsersObject(state),
    tradingPartners: getTradingPartners(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAmendMultipleOrderForm: (payload) => {
      dispatch(
        orders.actions.updateForm(orders.constants.UPDATE_AMEND_MULTIPLE_ORDER_FORM, payload),
      );
    },
    amendOrder: (payload) => {
      dispatch(
        orders.actions.updateOrders(
          orders.constants.SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM,
          payload,
        ),
      );
    },

    createRfqChat: (payload) => {
      dispatch(chat.actions.createRfqChat(payload));
    },
    updateChats: (payload) => {
      dispatch(chat.actions.updateChats(chat.constants.CHATS_UPDATE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GridRow));
