import { getFieldsArray } from '../../contracts/utils/modelFunctions';
import {
  formatPrice,
  formatDateTime,
  formatDate,
  formatList,
} from '../../../commons/config/formatters';

const dateFilter = function (filterLocalDateAtMidnight, cellValue) {
  const dateTime = new Date(
    new Date(cellValue).getFullYear(),
    new Date(cellValue).getMonth(),
    new Date(cellValue).getDate(),
  );

  if (cellValue == null) return 0;

  if (dateTime < filterLocalDateAtMidnight) {
    return -1;
  }
  if (dateTime > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const getColumns = () => {
  const fields = getFieldsArray('trade', [`blotter`]);
  const columns = [];

  const floatingFilter = true;

  fields.forEach((field) => {
    const obj = {};
    obj.headerName = field.displayName.toUpperCase();
    obj.field = field.fullAccessor;
    obj.width = 150;
    obj.resizable = true;
    obj.sortable = true;
    obj.tooltipField = field.fullAccessor;
    obj.enableCellChangeFlash = true;
    obj.floatingFilter = floatingFilter;
    obj.filter =
      field.type === 'date' || field.type === 'dateTime'
        ? 'agDateColumnFilter'
        : 'agTextColumnFilter';

    if (field.type === 'date' || field.type === 'dateTime') {
      obj.filterParams = {
        comparator: dateFilter,
        apply: false,
        newRowsAction: 'keep',
      };
    } else {
      obj.filterParams = { apply: false, newRowsAction: 'keep' };
    }

    if (field.type === 'date') {
      obj.valueFormatter = (params) => {
        return formatDate(params.value);
      };
    } else if (field.type === 'dateTime') {
      if (field.accessor === 'createdTime') {
        obj.sort = { direction: 'desc', priority: 0 };
      }
      obj.valueFormatter = (params) => {
        return params.value !== undefined ? formatDateTime(params.value) : '';
      };
    } else if (field.accessor === 'price') {
      obj.valueFormatter = (params) => {
        return formatPrice(params.value);
      };
    } else if (field === 'recipients') {
      obj.valueFormatter = (params) => {
        const list = [];
        params.forEach((p) => list.push(p.shortName));
        return formatList(list);
      };
    }

    columns.push(obj);
  });
  console.log('columns', columns);
  return columns;
};
