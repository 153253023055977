import { THEME_TYPES, INIT_THEME_SUCCESS, CHANGE_THEME_SUCCESS } from '../constants';
import { dark, light } from '../config/themes';

import auth from '../../modules/auth';

const initialState = {
  type: THEME_TYPES.dark,
  theme: dark,
  status: null,
  error: null,
  receivedAt: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_THEME_SUCCESS:
    case CHANGE_THEME_SUCCESS:
      const { type } = action.payload;

      if (typeof type !== 'undefined' && state.type !== type) {
        return {
          type,
          theme: type === THEME_TYPES.light ? light : dark,
          status: 'success',
          error: null,
          receivedAt: action.meta.receivedAt,
        };
      }

      return state;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
