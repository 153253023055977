import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography, Button, Icon } from '@material-ui/core';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import PopUp from '../../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';
import ColumnPickerRow from './ColumnPickerRow';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = (theme) => ({
  title: { fontSize: theme.fontSize * 1.5 },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    maxHeight: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    width: 640,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  listContainer: {
    overflowY: 'scroll',
  },
  textField: { marginTop: '8px', marginBottom: '8px' },
  fab: { marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit },
  nameChange: {
    color: 'rgba(0,0,0,.3)',
    display: 'block',
    padding: '0px 25px',
  },
  nameChangeInput: { color: 'rgba(255,255,255,.3)', display: 'block' },
});

class ColumnPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnState: [],
    };
  }

  componentDidMount() {
    this.propsToState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.columnState !== prevProps.columnState) {
      this.propsToState();
    }
  }

  propsToState = () => {
    const columnState = JSON.parse(JSON.stringify(this.props.columnState)) || [];
    const { columnDefs } = this.props;
    this.setState({ columnState, columnDefs });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  selectAll = (select) => {
    const newColumnState = [...this.state.columnState];
    newColumnState.forEach((item) => (item.hide = !select));
    this.setState({ columnState: newColumnState });
  };
  handleToggle = (value) => () => {
    const currentIndex = this.state.columnState.findIndex((i) => i.colId === value.colId);

    const newColumnState = [...this.state.columnState];

    newColumnState[currentIndex].hide = !this.state.columnState[currentIndex].hide;

    this.setState({ columnState: newColumnState });
  };

  getLabel = (value) => {
    const tempLabel = value.colId;
    let label = value.colId;
    const end = tempLabel.substring(tempLabel.length - 2, tempLabel.length);

    if (end === '_1') {
      label = tempLabel.substring(0, tempLabel.length - 2);
    }
    const currentIndex = this.state.columnDefs.findIndex((i) => i.field === label);
    if (currentIndex > -1) {
      label = this.state.columnDefs[currentIndex].headerName;
    }
    return label;
  };

  confirmClick = () => {
    this.props.resetColumnState(this.state.columnState);
    this.props.close();
  };

  handleDragEnd = (e) => {
    let columnState = [];
    if (e.destination !== null) {
      const index = e.destination.index;
      let columnId = e.draggableId;
      let itemToSplice = null;
      this.state.columnState.forEach((item) => {
        if (item.colId !== columnId) {
          columnState.push(item);
        } else {
          itemToSplice = item;
        }
      });
      columnState.splice(index, 0, itemToSplice);
      this.setState({ columnState });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      //<PopUp open={this.props.open} title="Columns">
      <Modal open={this.props.open}>
        <div className={`${classes.container} undraggable`}>
          <div style={{ flex: 1, paddingBottom: '8px' }}>
            <Typography className={classes.title}>{'COLUMNS'}</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(41,50,65)',
            }}
          >
            <div style={{ flex: 1, padding: '8px' }}>
              <Button variant="outlined" fullWidth onClick={() => this.selectAll(true)}>
                <Icon>{'add'}</Icon>
                {'Select All'}
              </Button>
            </div>
            <div style={{ flex: 1, padding: '8px' }}>
              <Button variant="outlined" fullWidth onClick={() => this.selectAll(false)}>
                <Icon>{'remove'}</Icon>
                {'Select None'}
              </Button>{' '}
            </div>
          </div>
          <div className={classes.listContainer}>
            <DragDropContext onDragEnd={(e) => this.handleDragEnd(e)}>
              <Droppable droppableId={'COLUMNS'}>
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.columnState.map((value, index) => {
                        const labelId = `checkbox-list-label-${value.colId}`;
                        const label = this.getLabel(value);
                        return (
                          <Draggable key={labelId} index={index} draggableId={value.colId}>
                            {(provided) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ColumnPickerRow
                                    key={value.colId}
                                    onClick={this.handleToggle(value)}
                                    label={label}
                                    checked={value.hide === false}
                                  />
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.props.close} />
              <ConfirmButton onClick={this.confirmClick} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
      //</PopUp>
    );
  }
}
ColumnPicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(ColumnPicker));
