import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { TextField, FormControl, InputAdornment } from '@material-ui/core';
import { textField } from './formFieldStyles';

const styles = (theme) => ({
  textField: textField(theme),
  inputTextHighlight: {
    color: lightBlue[300],
  },
});

const TextFieldFilled = (props) => {
  const { classes, accessor, displayName, value, error } = props;
  let textStyle = {}
  if (displayName.toUpperCase().includes("DATE")) {
    if (displayName.toUpperCase().includes("HARVEST")) {
      textStyle = { color: teal[300] }
    } else if (displayName.toUpperCase().includes("DELIVERY")) {
      textStyle = { color: lightBlue[300] }
    }
  }
  
  return (
    <FormControl className={props.noMargin ? null : classes.textField} fullWidth={props.fullWidth}>
      <TextField
        id={accessor}
        type="text"
        label={displayName}
        variant="filled"
        readOnly
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        // style={{width: props.width? props.width:"300px"}}
        value={value}
        onChange={props.handleChange(accessor)}
        error={props.error ? error.value : false}
        helperText={props.error ? error.message : ''}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          className: props.highlight ? classes.inputTextHighlight : null,
          inputProps: {
            step: props.step ? props.step : 1,
          },
          style:textStyle,
          endAdornment: (
            <InputAdornment position="end">
              {props.adornment ? props.adornment : ' '}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

TextFieldFilled.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFieldFilled);
