import lightBlue from '@material-ui/core/colors/lightBlue';

export const light = {
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue['400'],
      },
    },
    MuiPickersDay: {
      // day: {
      //   color: lightBlue.A700,
      // },
      isSelected: {
        backgroundColor: lightBlue['400'],
      },
      current: {
        color: lightBlue['900'],
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '2px',
        marginBottom: '2px',
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        fontSize: '16px',
        padding: 2,
        '&$focused': {
          // increase the specificity for the pseudo class
          color: 'rgba(0,0,0,.8)',
        },
      },
      shrink: {
        transform: 'translate(6px, 6px) scale(0.85) !important',
      },
    },
    MuiInputAdornment: {
      root: {
        '& [class*="MuiTypography-root"]': {
          fontSize: '0.7rem',
          whiteSpace: 'normal',
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },

    MuiChip: {
      root: {
        minHeight: '0px',
        height: 'none',
      },
    },
    MuiAvatar: {
      img: {
        backgroundColor: 'transparent',
      },
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: lightBlue[400],
        rippleBackgroundColor: lightBlue[400],
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
        fontSize: '1.2rem',
      },
      popper: {
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px',
        '@media (min-width: 600px)': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'rgba(20,20,20)',
      },
    },
    /*   MuiIconButton: {
      root: {
        padding: '4px',
      },
    }, */
  },
  palette: {
    common: {
      black: '#212121',
      white: '#fff',
    },
    type: 'light',
    background: {
      paper: 'rgba(245,245,245)',
      appBar: 'rgba(33,40,50)',
      widget: 'rgba(245,245,245)',
      default: 'rgba(225,228,228)',
      banner: 'rgba(225,228,228)',
      rfqTabBuy: 'rgb(24,59,85)',
      rfqTabSell: 'rgb(24,56,64)',
      userTab: 'rgba(233,237,239)',
      tile: 'rgba(0,0,0,.1)',
      row: '#fff',
    },
    primary: {
      main: 'rgba(225,228,228)', // "#E5E8EA",
      dark: 'rgba(233,237,239)', // "#EDF0F2",
      // light: "#fff",
      row: '#fff',
    },
    secondary: {
      light: 'rgba(255, 138, 123, 1)',
      main: 'rgba(219, 89, 79, 1)',
      dark: 'rgba(164, 39, 38, 1)',
      contrastText: '#000',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(20,20,20)',
      primaryLight: 'rgba(40,40,40)',
      opacity: 'rgb(0,0,0,.3)',
      secondary: 'rgba(40,40,40,.80)',
      disabled: 'rgba(93, 117, 152,1)',
      hint: 'rgba(56, 73, 95,1)',
      subtitle: '#546A8A',
    },
    divider: 'rgba(0,0,0,.1)',
    tableBorder: 'rgba(0,0,0,.05)',
    scrollbar: ' rgb(200, 200, 200)',
    chatMessages: 'rgb(252,252,252)',
  },
  fontSize: {
    xxxl: '3.0rem',
    xxl: '2.0rem',
    xl: '1.5rem',
    lg: '1.25rem',
    md: '1.0rem',
    sm: '.875rem',
    xs: '.75rem',
    xxs: '.5rem',
  },
  typography: {
    useNextVariants: true,
  },
};
