export const NAME = 'clob';

export const INIT_CLOB = 'clob/init';
export const INIT_CLOB_SUCCESS = 'clob/init/success';
export const INIT_CLOB_ERROR = 'clob/init/error';

export const DESTROY_CLOB = 'clob/destroy';
export const DESTROY_CLOB_SUCCESS = 'clob/destroy/success';
export const DESTROY_CLOB_ERROR = 'clob/destroy/error';

export const UPDATE_CLOB = 'clob/update';
export const UPDATE_CLOB_SUCCESS = 'clob/update/success';
export const UPDATE_CLOB_ERROR = 'clob/update/error';

export const GET_CLOB = 'clob/get';
export const GET_CLOB_SUCCESS = 'clob/get/success';
export const GET_CLOB_ERROR = 'clob/get/error';

export const RESET_CLOB = 'clob/reset';
export const RESET_CLOB_SUCCESS = 'clob/reset/success';
export const RESET_CLOB_ERROR = 'clob/reset/error';

// FOR DEMO; TODO: REMOVE THIS AFTER DEMO
export const TENORS = [
  {
    id: 1,
    bid: { max: 4.71, min: 4.39, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 2,
    bid: { max: 4.39, min: 4.06, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 3,
    bid: { max: 4.06, min: 3.85, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 4,
    bid: { max: 3.85, min: 3.73, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 5,
    bid: { max: 3.73, min: 3.65, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 6,
    bid: { max: 3.65, min: 3.6, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 7,
    bid: { max: 3.65, min: 3.6, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 8,
    bid: { max: 3.6, min: 3.57, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 9,
    bid: { max: 3.57, min: 3.56, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 10,
    bid: { max: 3.565, min: 3.56, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 12,
    bid: { max: 3.56, min: 3.57, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 15,
    bid: { max: 3.57, min: 3.59, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 20,
    bid: { max: 3.59, min: 3.55, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 25,
    bid: { max: 3.55, min: 3.45, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
  {
    id: 30,
    bid: { max: 3.4, min: 3.3, value: 0, changeDirection: 0 },
    ask: { value: 0, changeDirection: 0 },
  },
];
