export const fields = {
  buyer: {
    purchaseOrderNumber: {
      accessor: 'purchaseOrderNumber',
      fullAccessor: 'buyerData.purchaseOrderNumber',
      component: 'text',
      defaultValue: '',
      displayName: 'Purchase Order Number',
      type: 'string',
      blotter: true,
    },
    purchaseOrderDate: {
      accessor: 'purchaseOrderDate',
      fullAccessor: 'buyerData.purchaseOrderDate',
      component: 'date',
      defaultValue: new Date(),
      displayName: 'Purchase Order Date',
      type: 'date',
      blotter: true,
    },
    buyerNote: {
      accessor: 'buyerNote',
      fullAccessor: 'buyerData.buyerNote',
      component: 'text',
      defaultValue: '',
      displayName: 'Buyer Note',
      type: 'string',
      blotter: true,
    },
    orderSpecification: {
      accessor: 'orderSpecification',
      fullAccessor: 'buyerData.orderSpecification',
      component: 'select',
      defaultValue: null,
      displayName: 'Order Specification',
      isFile: true,
      fileType: 'ORDER_SPECIFICATION',
      type: 'file',
      blotter: false,
    },
  },
  seller: {
    invoiceNumber: {
      accessor: 'invoiceNumber',
      fullAccessor: 'sellerData.invoiceNumber',
      component: 'text',
      defaultValue: '',
      displayName: 'Invoice Number',
      type: 'string',
      blotter: true,
    },
    invoiceDate: {
      accessor: 'invoiceDate',
      fullAccessor: 'sellerData.invoiceDate',
      component: 'date',
      defaultValue: new Date(),
      displayName: 'Invoice Date',
      type: 'date',
      blotter: true,
    },
    trackingNumber: {
      accessor: 'trackingNumber',
      fullAccessor: 'sellerData.trackingNumber',
      component: 'text',
      defaultValue: '',
      displayName: 'Tracking Number',
      type: 'string',
      blotter: true,
    },
    sellerNote: {
      accessor: 'sellerNote',
      fullAccessor: 'sellerData.sellerNote',
      component: 'text',
      defaultValue: '',
      displayName: 'Seller Note',
      type: 'string',
      blotter: true,
    },
    invoice: {
      accessor: 'invoice',
      fullAccessor: 'sellerData.invoice',
      component: 'select',
      defaultValue: null,
      displayName: 'Invoice',
      isFile: true,
      fileType: 'INVOICE',
      type: 'file',
      blotter: false,
    },
    packagingList: {
      accessor: 'packagingList',
      fullAccessor: 'sellerData.packagingList',
      component: 'select',
      defaultValue: null,
      displayName: 'Packaging List',
      isFile: true,
      fileType: 'PACKAGING_LIST',
      type: 'file',
      blotter: false,
    },
    fishCv: {
      accessor: 'fishCv',
      fullAccessor: 'sellerData.fishCv',
      component: 'select',
      defaultValue: null,
      displayName: 'Fish CV',
      isFile: true,
      fileType: 'FISH_CV',
      type: 'file',
      blotter: false,
    },
    productSpecification: {
      accessor: 'productSpecification',
      fullAccessor: 'sellerData.productSpecification',
      component: 'select',
      defaultValue: null,
      displayName: 'Product Specification',
      isFile: true,
      fileType: 'PRODUCT_SPECIFICATION',
      type: 'file',
      blotter: false,
    },
  },
};

export const fieldMap = {
  ORDER_SPECIFICATION: 'orderSpecification',
  PRODUCT_SPECIFICATION: 'productSpecification',
  FISH_CV: 'fishCv',
  PACKAGING_LIST: 'packagingList',
  INVOICE: 'invoice',
};

export const getPostTradeBlotterItems = () => {
  const postTradeFields = { ...fields.buyer, ...fields.seller };
  const nextFields = {};
  Object.keys(postTradeFields).forEach((field) => {
    nextFields[field] = {
      request: false,
      response: false,
      fresh: true,
      frozen: true,
      rfqDetail: false,
      rfqForm: false,
      orderBlotter: false,
      tradeBlotter: true,
      blotter: postTradeFields[field].blotter,
      spot: true,
      contract: true,
      vap: true,
      vapcontract: true,
      displayName: postTradeFields[field].displayName,
      accessor: postTradeFields[field].accessor,
      fullAccessor: postTradeFields[field].fullAccessor,
      type: postTradeFields[field].type,
      component: null,
      defaultValue: null,
    };
  });

  return nextFields;
};
