import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Modal, Typography, Icon, IconButton, Tooltip } from '@material-ui/core';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import { Document, Page, pdfjs } from 'react-pdf';
import { lightBlue } from '@material-ui/core/colors';
import DistributionList from './DistributionList';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    //overflowY: 'scroll',
    alignItems: 'center',
  },
  button: {
    padding: theme.spacing(),
    border: `1px solid ${lightBlue[300]}`,
    '&:not([disabled])': {
      backgroundColor: 'transparent',
    },
    color: lightBlue[300],
    '&:disabled': {
      backgroundColor: theme.palette.background.default,
      color: lightBlue[900],
    },

    '&:not([disabled]):hover': {
      transition: 'all .5s ease',
      backgroundColor: lightBlue[500],
      color: 'white',
    },
    cursor: 'pointer',
    width: '70px',
    margin: '2px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(),
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pageMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resizeMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemPadding: {
    padding: '0px 8px',
  },
  title: {
    padding: theme.spacing(),
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
    fontSize: '24px',
    cursor: 'pointer',
  },
});

const FileViewer = (props) => {
  let file = props.file || {};
  let filename = file.filename || file.name;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = props;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  function scaleAdd() {
    setScale(scale + 0.25);
  }
  function scaleSubtract() {
    const newScale = scale - 0.25 > 0 ? scale - 0.25 : scale;
    setScale(newScale);
  }
  function rotateLeft() {
    const newRotate = rotate - 90 >= 0 ? rotate - 90 : 270;
    setRotate(newRotate);
  }
  function openDistributionList(event) {
    setAnchorEl(event.currentTarget);
  }
  function closeDistributionList() {
    setAnchorEl(null);
  }

  const imageScale = `${scale * 100}%`;
  const imageRotate = `rotate(${rotate}deg)`;
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div style={getModalStyle()} className={classes.paper}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <Typography className={classes.title}>{filename}</Typography>
            {file.filename ? (
              <div>
                <a href={props.file.preview} download={filename} className={classes.title}>
                  <Tooltip title={'Download'} aria-label={'Download'}>
                    <IconButton>
                      <Icon>get_app</Icon>
                    </IconButton>
                  </Tooltip>
                </a>
                <Tooltip title={'File Shared With:'} aria-label={'File Shared With:'}>
                  <IconButton onClick={openDistributionList}>
                    <Icon>people</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className={classes.pageMenuContainer}>
            <div style={{ padding: '0px 8px' }}>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '1'}
            </div>
            <div style={{ padding: '0px 8px' }}>
              <button
                className={classes.button}
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Previous
              </button>
              <button
                className={classes.button}
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next
              </button>
            </div>
            <div className={classes.resizeMenuContainer}>
              <IconButton onClick={scaleSubtract}>
                <Icon>remove</Icon>
              </IconButton>
              <div className={classes.itemPadding}>{scale * 100} %</div>
              <IconButton onClick={scaleAdd}>
                <Icon>add</Icon>
              </IconButton>
            </div>
            <div className={classes.resizeMenuContainer}>
              <IconButton onClick={rotateLeft}>
                <Rotate90DegreesCcwIcon />
              </IconButton>
            </div>
          </div>

          <button className={classes.closeButton} onClick={props.onClose}>
            <i className="material-icons" style={{ fontSize: '32px' }}>
              clear
            </i>
          </button>
        </div>
        <div className={classes.container}>
          {file.filename ? (
            file.filename.includes('pdf') ? (
              <React.Fragment>
                <div style={{ flex: 1, overflow: 'scroll' }}>
                  <Document
                    file={props.preview ? file : file.blob}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} scale={scale} rotate={rotate} />
                  </Document>
                </div>
              </React.Fragment>
            ) : (
              <img
                src={props.file.preview}
                alt="doc"
                style={{ height: 'auto', width: imageScale, transform: imageRotate }}
              />
            )
          ) : (
            'No File'
          )}
        </div>
        {anchorEl !== null && (
          <DistributionList
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={closeDistributionList}
            selectedOrganisationsIds={file.selectedOrganisationsIds || []}
            visibility={file.visibility}
          />
        )}
      </div>
    </Modal>
  );
};
FileViewer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileViewer);
