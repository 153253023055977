export const directions = {
  BUY: 'BUY',
  SELL: 'SELL',
};
export const visibilities = {
  // TODO: DUPLICATE
  PUBLIC: 'PUBLIC',
  TRADING_PARTNERS: 'TRADING_PARTNERS',
  SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS',
};

export const incoTerms = {
  FCA: 'FCA',
  DDP: 'DDP',
  DAP: 'DAP',
};

export const avatarSize = {
  LARGE: '40px',
  SMALL: '28px',
};

export const status = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  CANCELLED: 'CANCELLED',
  PARTIALLY_FILLED: 'PATRIALLY_FILLED',
  FILLED: 'FILLED',
  ENDED: 'ENDED',
  IN_PROGRESS: 'IN-PROGRESS',
};

export const errorMessages = {
  volume: 'Volume must be an integer > 0.',
  price: 'Price must be a decimal( max 2 places ) > 0.',
  recipientList: 'At least one user must be selected.',
  targetOrganisations: 'At least one user must be selected.',
};

export const tradeMatching = {
  ANY: 'ANY',
  RFQ: 'RFQ',
};

export const BOX_QUANTITY = 20;
export const PRICE_DECIMALS = 2;

export const NOTIFICATION_SOURCES = {
  EMAIL: 'email',
  // PUSH: 'push',
  SMS: 'sms',
  // WEB: 'web'
};
