import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { IconButton, Icon, Tooltip, Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import SelectField from '../../../../commons/components/formFields/SelectField';
import NumberFieldFilled from '../../../../commons/components/formFields/numberFieldFilled';

import { errorMessages } from '../../../../commons/models/constants';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';

import contractType from '../../../contracts/models/contractType';
import orderUnderlying from '../../../contracts/models/orderUnderlying';

const styles = (theme) => ({
  tableCellCalculator: {
    padding: 0,
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.2)',
  },
  containerItems: {
    padding: theme.spacing(1),
  },
  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
});

// TODO: Extract all functionality to saga ans store
class FormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  checkValid = (key, value) => {
    const returnValue = { message: '', value: false };
    switch (key) {
      case 'price':
        const price = checkPrice(value);
        returnValue.value = !price;
        returnValue.message = price ? '' : errorMessages.price;
        break;
      case 'volume':
        const volume = checkVolume(value);

        returnValue.value = !volume;
        returnValue.message = volume ? '' : errorMessages.volume;
        break;
    }
    return returnValue;
  };

  handleChange = (name) => (event) => {
    const row = { ...this.props.orderRow };
    row[name] = event.target.value;

    this.props.updateOrderRow(row);
  };

  getErrors = (key) => {
    if (this.state.errors[key]) {
      return this.state.errors[key];
    }
    return { message: '', value: false };
  };

  render() {
    const { classes, enumerations, formFields } = this.props;

    const orderRow = this.props.orderRow || {};
    const subSegment = orderUnderlying.fields[contractType.subSegment];
    const FIELD_WIDTH = '152px';

    const isOdd = this.props.index % 2 ? classes.containerOdd : classes.container;

    return (
      <>
        <div className={isOdd}>
          <div className={classes.containerItems}>
            <IconButton>
              <Avatar className={classes.small}>{this.props.index + 1}</Avatar>
            </IconButton>
          </div>
          <div className={classes.containerItems} style={{ flex: 1 }}>
            {formFields.map((field) => {
              const enums = field.accessor === 'weightClass' ? 'weightClasses' : field.accessor;
              switch (field.component) {
                case 'select':
                  return (
                    <SelectField
                      accessor={field.accessor}
                      key={`${field.accessor}-${orderRow.key}`}
                      displayName={field.displayName}
                      value={orderRow[field.accessor]}
                      values={enumerations[enums]}
                      handleChange={this.handleChange}
                      width={FIELD_WIDTH}
                    />
                  );
                case 'number':
                  return (
                    <NumberFieldFilled
                      accessor={field.accessor}
                      key={`${field.accessor}-${orderRow.key}`}
                      displayName={field.displayName}
                      value={orderRow[field.accessor]}
                      handleChange={this.handleChange}
                      error={this.getErrors(field.accessor)}
                      adornment={this.props.adornment}
                      width={FIELD_WIDTH}
                      step={1}
                    />
                  );
              }
            })}
          </div>
          <div className={classes.containerItems}>
            <Tooltip title="Copy Order" aria-label="Copy Order">
              <IconButton onClick={this.props.copyOrderRow}>
                <Icon>file_copy</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Order" aria-label="Delete Order">
              <IconButton onClick={this.props.deleteOrderRow}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </>
    );
  }
}

FormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormRow);
