import { takeEvery, put, select, fork } from 'redux-saga/effects';

import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import {
  RESET_MARKET,
  GET_MARKET,
  GET_MARKET_ERROR,
  GET_MARKET_SUCCESS,
  DESTROY_MARKET,
  DESTROY_MARKET_SUCCESS,
  DESTROY_MARKET_ERROR,
  INIT_MARKET,
  INIT_MARKET_SUCCESS,
  INIT_MARKET_ERROR,
  UPDATE_MARKET,
  UPDATE_MARKET_SUCCESS,
  UPDATE_MARKET_ERROR,
} from '../constants';

import {
  getMarket,
  updateMarket as actionUpdateMarket,
  initMarket as actionInitMarket,
  destroyMarket as actionDestroyMarket,
} from '../actions';

import marketModel from '../models/market.json';

import {
  getOrders,
  getActiveContract,
  getDefaultSegment,
  getActiveUser,
  getLocalMarketById,
  getAllLocalMarkets,
  getFxratesObject,
} from '../selectors';

import { status } from '../../../commons/models/constants';

import contracts from '../../contracts';

import { isObject } from '../../../core/utils/functions';

import { getAllContractWeeks } from '../../contracts/utils/deliveryWeeks';

import workspaces from '../../workspaces';

import ordersModule from '../../orders';

import organisationsModule from '../../organisations';

import auth from '../../auth';

const configStatus = {
  [status.SUSPENDED]: {
    title: 'Orders Suspended',
    forbidden: {
      [status.FILLED]: true,
      [status.CANCELLED]: true,
      [status.SUSPENDED]: true,
    },
    color: amber[600],
  },
  [status.CANCELLED]: {
    title: 'Orders Cancelled',
    forbidden: {
      [status.FILLED]: true,
      [status.CANCELLED]: true,
    },
    color: red[500],
  },
  [status.FILLED]: {
    title: '',
    forbidden: {},
  },
  [status.ACTIVE]: {
    title: 'Orders Activated',
    forbidden: {
      [status.FILLED]: true,
      [status.CANCELLED]: true,
      [status.ACTIVE]: true,
    },
    color: teal[300],
  },
};

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    // const contract = yield call(api.get);

    const payload = {
      items: [marketModel],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getMarket(GET_MARKET_SUCCESS, payload, meta));

    yield fork(processMarkets);
  } catch (error) {
    yield put(getMarket(GET_MARKET_ERROR, { error }));
  }
}

export function* watchGetMarket() {
  yield takeEvery(GET_MARKET, get);
}

export function* watchResetMarket() {
  yield takeEvery(RESET_MARKET);
}

function* initMarket(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    let defaultSegment = null;
    let defaultSegmentValues = [];
    let displayCurrency = null;
    let defaultCurrency = null;
    let displayCurrencyRate = null;
    let segmentValues = [];
    let displayCurrencies = [];
    let recipients = [];
    let currencies = [];
    let incoTerms = [];
    let weeks = [];
    let groupedOrders = [];
    let sendToDisabled = true;
    let currencyEnums = [];

    const contract = yield select(getActiveContract);

    const fxrates = yield select(getFxratesObject);
    const organisations = yield select(organisationsModule.selectors.getAll);
    const organisationsObject = yield select(organisationsModule.selectors.getAllObject);
    let filters = {
      sentByFilter: '',
      sentToFilter: '',
      currencyFilter: '',
      incoTermsFilter: '',
      weekFilter: '',
    };
    if (isObject(widget)) {
      payload.widget = widget;

      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'defaultSegment')
      ) {
        defaultSegment = widget.savedState.defaultSegment;
        defaultSegmentValues = contracts.utils.getDefaultSegmentValues(contract, defaultSegment);

        displayCurrency = defaultSegmentValues.currency;
        defaultCurrency = defaultSegmentValues.currency;

        displayCurrencyRate = getCurrencyRate(displayCurrency, defaultCurrency, fxrates);
      }
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'filters')
      ) {
        filters = { ...filters, ...widget.savedState.filters };
      }
    }

    payload.incoTermsEnums = ['', 'FCA', 'DDP'];

    weeks = getAllContractWeeks().values;
    weeks.unshift('');

    displayCurrency = displayCurrency || 'EUR';

    const defaultSegmentNEW = yield select(getDefaultSegment);
    defaultSegment = defaultSegment || defaultSegmentNEW;

    const user = yield select(getActiveUser);

    if (user) {
      sendToDisabled = getSendToDisabled(user, filters.sentByFilter, organisations);
    }

    if (contract) {
      currencyEnums = contracts.utils.getCurrencyEnums(contract);
      currencyEnums.unshift('');

      const { orderFields } = contract;
      //segmentValues = contracts.utils.getSegmentValues(contract);
      segmentValues = contracts.utils.getMarketSegments();

      if (isObject(orderFields)) {
        displayCurrencies = Object.keys(orderFields.currency.values);
      }

      const orders = yield select(getOrders);

      if (orders.length > 0) {
        const filteredOrders = getFilteredOrders(orders, contract, filters, {
          defaultSegment,
          displayCurrency,
          fxrates,
        });

        recipients = getRecipients(filteredOrders, organisationsObject);

        if (defaultSegment) {
          currencies = getCurrencies(filteredOrders, contract, defaultSegment);
          incoTerms = getIncoTerms(filteredOrders, contract, defaultSegment);
          weeks = getWeeks(filteredOrders, contract, defaultSegment);
        }

        groupedOrders = getGroupedOrders(filteredOrders, contract, displayCurrency, fxrates);
      }
    }

    payload.defaultSegment = defaultSegment;
    payload.defaultSegmentValues = defaultSegmentValues;
    payload.displayCurrency = displayCurrency;
    payload.defaultCurrency = defaultCurrency;
    payload.displayCurrencyRate = displayCurrencyRate;
    payload.segmentValues = segmentValues;
    payload.displayCurrencies = displayCurrencies;
    payload.recipients = recipients;
    payload.currencies = currencies;
    payload.incoTerms = incoTerms;
    payload.weeks = weeks;
    payload.groupedOrders = groupedOrders;
    payload.sendToDisabled = sendToDisabled;
    payload.currencyEnums = currencyEnums;

    payload.filters = filters;

    payload.showDisplayCurrency = false;
    payload.snackbarOpen = false;
    payload.snackbarContent = '';
    payload.snackbarColor = teal[300];

    yield put(actionInitMarket(INIT_MARKET_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionInitMarket(INIT_MARKET_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* destroyMarket(action) {
  try {
    const payload = {
      id: action.payload.id,
    };

    const meta = { receivedAt: new Date() };

    yield put(actionDestroyMarket(DESTROY_MARKET_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionDestroyMarket(DESTROY_MARKET_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* updateMarket(action) {
  try {
    const {
      payload: { id, action: actionMarket, filters, name, value, status: statusOrders },
    } = action;

    let payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    if (actionMarket === 'clearFilters') {
      const contract = yield select(getActiveContract);
      const orders = yield select(getOrders);
      const market = yield select(getLocalMarketById, id);
      const user = yield select(getActiveUser);

      const { defaultSegment, displayCurrency } = market;

      const organisationsObject = yield select(organisationsModule.selectors.getAllObject);
      const filters = {
        sentByFilter: '',
        sentToFilter: '',
        currencyFilter: '',
        incoTermsFilter: '',
        weekFilter: '',
      };

      const result = processOrders(contract, orders, filters, {
        defaultSegment,
        displayCurrency,
        organisationsObject,
        user,
      });

      const sendToDisabled = true;

      payload = {
        ...payload,
        sendToDisabled,
        filters,
        ...result,
      };
      const { widget } = market;
      if (isObject(widget)) {
        const newWidget = {
          ...widget,
          savedState: {
            ...widget.savedState,
            filters,
          },
        };

        yield put(
          workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
        );

        payload.widget = newWidget;
      }
    } else if (actionMarket === 'instrumentChange') {
      const defaultSegment = value;
      payload.defaultSegment = defaultSegment;

      const market = yield select(getLocalMarketById, id);
      const fxrates = yield select(getFxratesObject);

      const { widget, displayCurrency } = market;

      if (isObject(widget)) {
        const newWidget = {
          ...widget,
          savedState: {
            ...widget.savedState,
            defaultSegment,
          },
        };

        yield put(
          workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
        );

        payload.widget = newWidget;
      }

      const contract = yield select(getActiveContract);

      if (contract) {
        const defaultSegmentValues = contracts.utils.getDefaultSegmentValues(
          contract,
          defaultSegment,
        );
        payload.defaultSegmentValues = defaultSegmentValues;

        const defaultCurrency = defaultSegmentValues.currency;
        payload.defaultCurrency = defaultCurrency;

        const displayCurrencyRate = getCurrencyRate(displayCurrency, defaultCurrency, fxrates);
        payload.displayCurrencyRate = displayCurrencyRate;

        const orders = yield select(getOrders);
        const user = yield select(getActiveUser);
        const organisationsObject = yield select(organisationsModule.selectors.getAllObject);

        const result = processOrders(contract, orders, market.filters, {
          defaultSegment,
          displayCurrency,
          organisationsObject,
          fxrates,
          user,
        });
        payload = {
          ...payload,
          ...result,
        };
      }
    } else if (actionMarket === 'displayCurrencyChange') {
      const displayCurrency = value;
      payload.displayCurrency = displayCurrency;

      const market = yield select(getLocalMarketById, id);

      const defaultCurrency = market.defaultSegmentValues.currency;

      const fxrates = yield select(getFxratesObject);
      const displayCurrencyRate = getCurrencyRate(displayCurrency, defaultCurrency, fxrates);
      payload.displayCurrencyRate = displayCurrencyRate;

      const contract = yield select(getActiveContract);
      const orders = yield select(getOrders);

      const { defaultSegment } = market;
      const user = yield select(getActiveUser);
      const organisationsObject = yield select(organisationsModule.selectors.getAllObject);
      const result = processOrders(contract, orders, market.filters, {
        defaultSegment,
        displayCurrency,
        organisationsObject,
        fxrates,
        user,
      });

      payload = {
        ...payload,
        ...result,
      };
    } else if (actionMarket === 'changeFilter') {
      payload.filters = { [name]: value };
      if (name === 'sentByFilter') {
        const user = yield select(getActiveUser);

        if (user) {
          const organisations = yield select(organisationsModule.selectors.getAll);
          payload.sendToDisabled = getSendToDisabled(user, value, organisations);
        }
      }

      const contract = yield select(getActiveContract);
      const orders = yield select(getOrders);
      const market = yield select(getLocalMarketById, id);

      const { defaultSegment, displayCurrency } = market;
      const user = yield select(getActiveUser);
      const organisationsObject = yield select(organisationsModule.selectors.getAllObject);
      const filters = { ...market.filters, ...payload.filters };
      const result = processOrders(contract, orders, filters, {
        defaultSegment,
        displayCurrency,
        organisationsObject,
        user,
      });

      payload = {
        ...payload,
        ...result,
        filters,
      };

      const { widget } = market;

      if (isObject(widget)) {
        const newWidget = {
          ...widget,
          savedState: {
            ...widget.savedState,
            filters,
          },
        };

        yield put(
          workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
        );

        payload.widget = newWidget;
      }
    } else if (actionMarket === 'updateAllStatus') {
      if (configStatus[statusOrders]) {
        const newStatusConfig = configStatus[statusOrders];
        const contract = yield select(getActiveContract);
        const user = yield select(getActiveUser);
        const orders = yield select(getOrders);
        const market = yield select(getLocalMarketById, id);

        const { defaultSegment } = market;
        const marketValue = defaultSegment.split(' ')[0];
        const segmentValue = defaultSegment.split(' ')[1];

        const filteredOrders = orders
          .filter((order) => order.organisationId === user.organisationId)
          .filter(
            (order) => order.orderData[0].contract.underlying[contract.market] === marketValue,
          )
          .filter(
            (order) => order.orderData[0].contract.underlying[contract.segment] === segmentValue,
          )
          .filter((order) => {
            return Object.prototype.hasOwnProperty.call(order, 'rfqId')
              ? order.rfqId === null
              : true;
          })
          .filter((order) => !newStatusConfig.forbidden[order.status]);

        const amendedOrders = [];

        filteredOrders.forEach((order) => {
          const orderAmendStatus = new ordersModule.models.OrderAmendStatus(
            order,
            statusOrders,
            user.id,
          );
          amendedOrders.push(orderAmendStatus);
        });

        if (amendedOrders.length > 0) {
          yield put(
            ordersModule.actions.updateOrders(ordersModule.constants.UPDATE_ORDERS, {
              items: amendedOrders,
            }),
          );
        }

        payload.snackbarOpen = true;
        payload.snackbarContent = `${newStatusConfig.title}: ${amendedOrders.length}`;
        payload.snackbarColor = newStatusConfig.color;
      }
    } else if (actionMarket === 'changeField') {
      payload[name] = value;

      if (name === 'defaultSegment' || name === 'displayCurrency') {
        const contract = yield select(getActiveContract);
        const orders = yield select(getOrders);
        const market = yield select(getLocalMarketById, id);

        let { defaultSegment, displayCurrency } = market;

        defaultSegment = name === 'defaultSegment' ? value : defaultSegment;
        displayCurrency = name === 'displayCurrency' ? value : displayCurrency;
        const user = yield select(getActiveUser);
        const organisationsObject = yield select(organisationsModule.selectors.getAllObject);
        const result = processOrders(contract, orders, market.filters, {
          defaultSegment,
          displayCurrency,
          organisationsObject,
          user,
        });

        payload = {
          ...payload,
          ...result,
        };
      }
    }
    yield put(actionUpdateMarket(UPDATE_MARKET_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionUpdateMarket(UPDATE_MARKET_ERROR, { error }, { receivedAt: new Date() }));
  }
}

export function* watchInitMarket() {
  yield takeEvery(INIT_MARKET, initMarket);
}

export function* watchDestroyMarket() {
  yield takeEvery(DESTROY_MARKET, destroyMarket);
}

export function* watchUpdateMarket() {
  yield takeEvery(UPDATE_MARKET, updateMarket);
}

function getCurrencyRate(displayCurrency, defaultCurrency, fxrates = {}) {
  const rates =
    isObject(fxrates[defaultCurrency]) && Array.isArray(fxrates[defaultCurrency].rates)
      ? fxrates[defaultCurrency].rates
      : [];
  const fx = rates.find((r) => r.quoteCurrency === displayCurrency);

  const currencyRate = fx ? fx.rate : 1;
  return currencyRate;
}

// TODO: OPTIMISATION: INVOKE ONLY ONE FOR GENERAL CONDITION. AND USE DYNAMIC FILTERS FOR RESULT IN COMPONENTS
function getFilteredOrders(
  orders,
  contract,
  { sentByFilter, sentToFilter, currencyFilter, incoTermsFilter, weekFilter },
  { defaultSegment, displayCurrency, organisationsObject = {}, fxrates = {}, user },
) {
  const { segment, market } = contract;

  const filteredOrders = orders
    .filter(
      (order) => {
        const orderMarket = order.orderData[0].contract.underlying[market];
        const orderSegment = order.orderData[0].contract.underlying[segment];
        const item = `${orderMarket} ${orderSegment}`;
        return defaultSegment === item;
      },
      /*  defaultSegment.includes(order.orderData[0].contract.underlying[market]) &&
        defaultSegment.includes(order.orderData[0].contract.underlying[segment]), */
    )
    .filter((order) => {
      return Object.prototype.hasOwnProperty.call(order, 'rfqId') ? order.rfqId === null : true;
    })

    .filter((order) => order.status === status.ACTIVE || order.status === status.PARTIALLY_FILLED)
    .filter((order) => {
      if (sentByFilter === '') {
        return true;
      }

      const shortName = organisationsObject[order.organisationId]
        ? organisationsObject[order.organisationId].shortName
        : order.organisationId;

      return shortName === sentByFilter;
    })
    .filter((order) => {
      if (sentToFilter === '') {
        return true;
      }
      if (order.visibility === 'PUBLIC') {
        return true;
      }
      if (order.visibility === 'TRADING_PARTNERS') {
        const myOrgId = user.organisationId;

        const myOrg = organisationsObject[myOrgId];

        const myTradingPartnerIds = myOrg.tradingPartnersIds;

        const organisations = Object.values(organisationsObject);

        const sentToOrg = organisations.find((o) => o.shortName === sentToFilter);

        const sentToId = sentToOrg.id;
        const index = myTradingPartnerIds.findIndex((x) => x === sentToId);

        return index > -1;
      }
      let returnValue = false;
      order.selectedOrganisationsIds.forEach((r) => {
        const shortName = organisationsObject[r]
          ? organisationsObject[r].shortName
          : order.organisationId;
        if (shortName === sentToFilter) {
          returnValue = true;
        }
      });
      return returnValue;
    })
    .filter((order) => {
      if (currencyFilter === '') {
        return true;
      }
      return order.orderData[0].contract.underlying.currency === currencyFilter;
    })
    .filter((order) => {
      if (incoTermsFilter === '') {
        return true;
      }
      return order.orderData[0].contract.underlying.incoTerms === incoTermsFilter;
    })
    .filter((order) => {
      if (weekFilter === '') {
        return true;
      }
      return order.orderData[0].contract.week === weekFilter;
    });

  filteredOrders.forEach((order) => {
    let currencyRate = 1;
    const { currency } = order.orderData[0].contract.underlying;
    currencyRate = getCurrencyRate(displayCurrency, currency, fxrates);
    order.refPrice = order.orderData[0].price * currencyRate; // TODO: modified and duplicate. refactor
  });

  return filteredOrders;
}

function getGroupedOrders(filteredOrders, contract, displayCurrency, fxrates = {}) {
  const { subSegment } = contract;
  const subSegments = contracts.utils.getSubSegmentValues(contract);

  filteredOrders.forEach((order) => {
    let currencyRate = 1;
    const { currency } = order.orderData[0].contract.underlying;
    currencyRate = getCurrencyRate(displayCurrency, currency, fxrates);
    order.refPrice = order.orderData[0].price * currencyRate; // TODO: modified and duplicate. refactor
  });

  const rows = [];

  subSegments.forEach((sub) => {
    const subOrders = filteredOrders.filter(
      (order) => order.orderData[0].contract.underlying[subSegment] === sub,
    );

    const bids = subOrders.filter((order) => order.direction === 'BUY' && order.volume !== 0); // TOD: review for join in one iteration
    const offers = subOrders.filter((order) => order.direction === 'SELL' && order.volume !== 0);

    bids.sort((a, b) => {
      return b.refPrice - a.refPrice;
    });

    offers.sort((a, b) => {
      return a.refPrice - b.refPrice;
    });

    const obj = {
      subsegment: sub,
      bids,
      offers,
    };
    rows.push(obj);
  });
  return rows;
}

function getRecipients(orders, organisationsObject) {
  const recipients = [];
  const existRecepients = {};

  orders.forEach((order) => {
    // TODO : REPLACE: order.recipientList and use r.shortName
    order.selectedOrganisationsIds.forEach((orgId) => {
      if (!existRecepients[orgId]) {
        const shortName = organisationsObject[orgId] ? organisationsObject[orgId].shortName : null;
        if (shortName !== null) {
          recipients.push(shortName);
        }
        existRecepients[orgId] = true;
      }
    });
  });

  recipients.sort();

  return recipients;
}

function getWeeks(data, contract, defaultSegment) {
  const { segment } = contract;
  const defaultSegmentPart = defaultSegment.split(' ')[1];

  const orders = data.filter(
    (order) => order.orderData[0].contract.underlying[segment] === defaultSegmentPart,
  );

  const weeks = ['', ...new Set(orders.map((order) => order.orderData[0].contract.week))];
  weeks.sort();

  return weeks;
}

function getSendToDisabled(user, sentByFilter, organisations) {
  const index = organisations.findIndex((x) => x.shortName === sentByFilter);
  const sentByFilterId = index > -1 ? organisations[index].id : null;
  return user.organisationId !== sentByFilterId;
}

function getIncoTerms(data, contract, defaultSegment) {
  const { segment } = contract;
  const defaultSegmentPart = defaultSegment.split(' ')[1];

  // TODO: Duplicate filter as in getWeeks
  const orders = data.filter(
    (order) => order.orderData[0].contract.underlying[segment] === defaultSegmentPart,
  );

  const incoTerms = [
    '',
    ...new Set(orders.map((order) => order.orderData[0].contract.underlying.incoTerms)),
  ];
  incoTerms.sort();

  return incoTerms;
}

function getCurrencies(data, contract, defaultSegment) {
  const { segment } = contract;
  const defaultSegmentPart = defaultSegment.split(' ')[1];

  // TODO: Duplicate filter as in getWeeks
  const orders = data.filter(
    (order) => order.orderData[0].contract.underlying[segment] === defaultSegmentPart,
  );

  const currencies = [
    '',
    ...new Set(orders.map((order) => order.orderData[0].contract.underlying.currency)),
  ];
  currencies.sort();

  return currencies;
}

function processOrders(
  contract,
  orders,
  filters,
  { defaultSegment, displayCurrency, organisationsObject = {}, fxrates = {}, user },
) {
  const result = {};

  if (contract && orders.length > 0) {
    const filteredOrders = getFilteredOrders(orders, contract, filters, {
      defaultSegment,
      displayCurrency,
      organisationsObject,
      fxrates,
      user,
    });

    result.recipients = getRecipients(filteredOrders, organisationsObject);

    if (defaultSegment) {
      result.currencies = getCurrencies(filteredOrders, contract, defaultSegment);
      result.incoTerms = getIncoTerms(filteredOrders, contract, defaultSegment);
      result.weeks = getWeeks(filteredOrders, contract, defaultSegment);
    }

    result.groupedOrders = getGroupedOrders(filteredOrders, contract, displayCurrency, fxrates);
  }

  return result;
}

function* changeState(action) {
  try {
    const {
      payload: { source },
    } = action;

    if (source === 'orders' || source === 'contract') {
      yield fork(processMarkets);
    }
  } catch (error) {
    yield put(actionUpdateMarket(UPDATE_MARKET_ERROR, { error }, { receivedAt: new Date() }));
  }
}

export function* watchChangeStateOrders() {
  yield takeEvery(ordersModule.constants.CHANGE_STATE_ORDERS, changeState);
}

export function* watchChangeStateContract() {
  yield takeEvery(contracts.constants.CHANGE_STATE_CONTRACT, changeState);
}

function* processMarkets() {
  try {
    const markets = yield select(getAllLocalMarkets);

    const contract = yield select(getActiveContract);
    const orders = yield select(getOrders);
    const fxrates = yield select(getFxratesObject);
    const organisationsObject = yield select(organisationsModule.selectors.getAllObject);
    const user = yield select(getActiveUser);
    if (isObject(contract) && Array.isArray(orders) && isObject(fxrates)) {
      let currencyEnums = null;
      let segmentValues = null;
      let displayCurrencies = null;

      currencyEnums = contracts.utils.getCurrencyEnums(contract);
      currencyEnums.unshift('');

      segmentValues = contracts.utils.getMarketSegments();
      const { orderFields } = contract;

      if (isObject(orderFields)) {
        displayCurrencies = Object.keys(orderFields.currency.values);
      }

      for (let i = 0; i < markets.length; i += 1) {
        const market = markets[i];
        const { id, displayCurrency } = market;
        let { defaultSegment } = market;
        if (!defaultSegment) {
          defaultSegment = yield select(getDefaultSegment);
        }

        const payload = {
          id,
          defaultSegment,
        };

        const meta = { receivedAt: new Date() };
        const filteredOrders = getFilteredOrders(orders, contract, market.filters, {
          defaultSegment,
          displayCurrency,
          organisationsObject,
          fxrates,
          user,
        });
        payload.recipients = getRecipients(filteredOrders, organisationsObject);

        if (defaultSegment) {
          payload.currencies = getCurrencies(filteredOrders, contract, defaultSegment);
          payload.incoTerms = getIncoTerms(filteredOrders, contract, defaultSegment);
          payload.weeks = getWeeks(filteredOrders, contract, defaultSegment);
        }

        payload.groupedOrders = getGroupedOrders(
          filteredOrders,
          contract,
          displayCurrency,
          fxrates,
        );

        if (currencyEnums !== null) {
          payload.currencyEnums = currencyEnums;
        }

        if (segmentValues !== null) {
          payload.segmentValues = segmentValues;
        }

        if (displayCurrencies !== null) {
          payload.displayCurrencies = displayCurrencies;
        }
        yield put(actionUpdateMarket(UPDATE_MARKET_SUCCESS, payload, meta));
      }
    }
  } catch (error) {
    yield put(actionUpdateMarket(UPDATE_MARKET_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
