import { AppBar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  calculateModifiedDurations,
  createS1,
  createS2,
  getDateRange,
  getTenor,
  updateTenor,
} from '../utils/functions';
import Cell from './Cell';

const styles = (theme) => {
  return {
    title: {
      fontSize: '18.85px',
      padding: '8px',
    },
    table: {
      borderRadius: 0,
      boxShadow: `0px 0px #000`,
      '& .MuiTableCell-root:not(:first-child)': {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
      '& .MuiTableCell-root': {
        borderColor: theme.palette.divider,
      },
      boxSizing: 'border-box',
    },
    headerRow: {
      backgroundColor: theme.palette.background.banner,
      borderBottomColor: theme.palette.divider,
      borderBottom: '1px solid',
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
    headerText: {
      whiteSpace: 'nowrap',
      color: theme.palette.text.subtitle,
    },
    firstCell: {
      paddingVertical: theme.spacing.unit,
      width: 119,
    },
    fixedSizeCell: {
      paddingVertical: theme.spacing.unit,
      width: 110,
    },
    fixedNumberSizeCell: {
      paddingVertical: theme.spacing.unit,
      width: 30,
    },
    dynamicSizeCell: {
      paddingVertical: theme.spacing.unit,
    },
    cellText: {
      color: theme.palette.text.subtitle,
    },
    increased: {
      background: `rgba(0, 255, 0, 0)`,
      animation: `$fade-increased 2000ms ${theme.transitions.easing.easeInOut}`,
    },
    decreased: {
      background: `rgba(255, 0, 0, 0)`,
      animation: `$fade-decreased 2000ms ${theme.transitions.easing.easeInOut}`,
    },
    unchanged: {
      background: 'transparent',
    },
    '@keyframes fade-increased': {
      '0%': {
        backgroundColor: `rgba(0, 255, 0, 1)`,
      },
      '100%': {
        backgroundColor: `rgba(0, 255, 0, 0)`,
      },
    },
    '@keyframes fade-decreased': {
      '0%': {
        backgroundColor: `rgba(255, 0, 0, 1)`,
      },
      '100%': {
        backgroundColor: `rgba(255, 0, 0, 0)`,
      },
    },
  };
};

const CentralLimitOrderBook = ({ classes }) => {
  const [SWAPS, setSWAPS] = useState(getTenor());
  const [currentDate, nextDate] = getDateRange();
  const modDurations = calculateModifiedDurations(SWAPS, currentDate, nextDate);
  const directions = [classes.decreased, classes.unchanged, classes.increased];
  const s1 = createS1(SWAPS, modDurations, directions);
  const s2 = createS2(SWAPS, directions);

  useEffect(() => {
    const refreshTenorsIntervalHandler = setInterval(() => {
      setSWAPS((prevTenors) => updateTenor(prevTenors));
    }, 3000);

    return () => {
      clearInterval(refreshTenorsIntervalHandler);
    };
  }, []);

  const firstCellClasses = clsx({
    [classes.headerText]: true,
    [classes.firstCell]: true,
  });
  const fixedSizeCellClasses = clsx({
    [classes.headerText]: true,
    [classes.fixedSizeCell]: true,
  });
  const dynamicSizeCellClasses = clsx({
    [classes.headerText]: true,
    [classes.dynamicSizeCell]: true,
  });

  return (
    <Grid container spacing={1}>
      <Grid item>
        <AppBar position="static" className={classes.title}>
          {currentDate.toLocaleDateString('en-GB')}-{nextDate.toLocaleDateString('en-GB')}
        </AppBar>
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell className={fixedSizeCellClasses} />
                <TableCell className={dynamicSizeCellClasses} align="right">
                  MOD DUR
                </TableCell>
                <TableCell className={fixedSizeCellClasses} align="right">
                  TENOR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SWAPS.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" className={classes.cellText}>
                    {DateTime.fromJSDate(nextDate)
                      .plus({ days: 365 * row.id })
                      .toJSDate()
                      .toLocaleDateString('en-GB')}
                  </TableCell>
                  <TableCell align="right">{modDurations[i].toFixed(7)}</TableCell>
                  <TableCell align="right">{row.id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <AppBar position="static" className={classes.title}>
          US SWAPS 30/360 vs SOFR
        </AppBar>
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  BID SIZE
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  BID
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  ASK
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  ASK SIZE
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SWAPS.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align="right" className={classes.cellText} />
                    <Cell highlight={row.bid.changeDirection + 1}>{row.bid.value.toFixed(4)}</Cell>
                    <Cell highlight={row.ask.changeDirection + 1}>{row.ask.value.toFixed(4)}</Cell>
                    <TableCell align="right" className={classes.cellText} />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <AppBar position="static" className={classes.title}>
          SPREADS vs ONE YEAR
        </AppBar>
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell className={firstCellClasses}>TENOR</TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  BID SIZE
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  BID
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  ASK
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  ASK SIZE
                </TableCell>
                <TableCell className={fixedSizeCellClasses} align="right">
                  RATIO
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {s1.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" className={classes.cellText}>
                    1v{row.id}
                  </TableCell>
                  <TableCell align="right" className={classes.cellText} />
                  <Cell highlight={row.bid.highlight}>{row.bid.value}</Cell>
                  <Cell highlight={row.ask.highlight}>{row.ask.value}</Cell>
                  <TableCell align="right" className={classes.cellText} />
                  <TableCell align="right">{row.ratio}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <AppBar position="static" className={classes.title}>
          SPREADS vs TWO YEAR
        </AppBar>
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell className={firstCellClasses}>TENOR</TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  BID SIZE
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  BID
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  ASK
                </TableCell>
                <TableCell className={dynamicSizeCellClasses} align="right">
                  ASK SIZE
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {s2.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" className={classes.cellText}>
                    2v{row.id}
                  </TableCell>
                  <TableCell align="right" className={classes.cellText} />
                  <Cell highlight={row.bid.highlight}>{row.bid.value}</Cell>
                  <Cell highlight={row.ask.highlight}>{row.ask.value}</Cell>
                  <TableCell align="right" className={classes.cellText} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
CentralLimitOrderBook.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CentralLimitOrderBook);
