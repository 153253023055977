import React from 'react';
import PropTypes from 'prop-types';

import { AppBar, Badge, Tab, Tabs } from '@material-ui/core';


const ChatTabs = ({ tabValue, handleTabChange, groupsCount, contactsCount, recentCount }) => {
  return (
    <AppBar position="static">
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab
          style={{ minWidth: '100px' }}
          label={
            <Badge color="secondary" badgeContent={recentCount}>
              RECENT
            </Badge>
          }
        />
        <Tab
          style={{ minWidth: '100px' }}
          label={
            <Badge color="secondary" badgeContent={groupsCount}>
              ORGANISATIONS
            </Badge>
          }
        />

        <Tab
          style={{ minWidth: '100px' }}
          label={
            <Badge color="secondary" badgeContent={contactsCount}>
              USERS
            </Badge>
          }
        />
      </Tabs>
    </AppBar>
  );
};

ChatTabs.propTypes = {
  tabValue: PropTypes.number.isRequired,
  groupsCount: PropTypes.number.isRequired,
  contactsCount: PropTypes.number.isRequired,
  recentCount: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default ChatTabs;
