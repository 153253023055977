import { create } from '../../../core/actions';

export const getTrades = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getTrade = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const insertTrades = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateTrades = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const resetTrades = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const tradeDetailsOpen = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const postTradeFormOpen = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateEndRfqPrompt = (type, payload, meta) => {
  return create(type, payload, meta);
};
