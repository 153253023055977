import { directions } from '../models/constants';

export const getOrganisationShortNameFromId = (id, organisations) => {
  let shortName = 'Anon';
  const index = organisations.findIndex((c) => c.id === id);

  shortName = index !== -1 ? organisations[index].shortName : 'Anon';
  return shortName;
};

export const getOrganisationFullNameFromId = (id, organisations) => {
  let fullName = '';
  const index = organisations.findIndex((c) => c.id === id);

  fullName = index !== -1 ? organisations[index].name : 'Anon';
  return fullName;
};

export const getTotals = (orderRows, orderStatus) => {

  let totalVolume = 0;

  let totalNominal = 0;
  let direction = directions.BUY;
  let unit = '';
  let currency = '';
  let isMyOrder = false;
  let isCompanyOrder = false;
  let tradable = true;
  let amendable = true;
  let status;
  let product= "SALMON"
  const volumePerUnit = (unit, product) =>{
    let value =1
    if(unit ==='BOX'){
      if(product === "SALMON"){
        value = 20
      }else{
        value = 22.5
      }
    }
    return value
  }
  if (orderRows !== undefined) {
    if (orderRows.length !== 0) {
      orderRows.forEach((row) => {
        status = row.status
          ? row.status
          : orderStatus === null || orderStatus === undefined
          ? orderStatus
          : 'ACTIVE';
        unit = row.contract ? row.contract.underlying.unit : row.unit;
        currency = row.contract ? row.contract.underlying.currency : row.currency;
        direction = row.direction;
        const vol = Number(row.volume);
        const pri = Number(row.price);
        totalVolume += vol;
        product = row.contract ? row.contract.underlying.product : (row.product ? row.product :"SALMON");
        const nominal = vol * volumePerUnit(unit, product) * pri;
        totalNominal += nominal;
        isMyOrder = row.isMyOrder;
        isCompanyOrder = row.isCompanyOrder;
        if (
          status === 'CANCELLED' ||
          status === 'SUSPENDED' ||
          status === 'FILLED' ||
          status === 'IN-PROGRESS' ||
          isCompanyOrder
        ) {
          tradable = false;
        }
        if (
          status === 'CANCELLED' ||
          status === 'FILLED' ||
          status === 'IN-PROGRESS' ||
          isMyOrder === false
        ) {
          amendable = false;
        }
      });
    }
  }
  const avgPrice = totalNominal / totalVolume / volumePerUnit(unit, product) || 0;

  return {
    volume: totalVolume,
    avgPrice,
    nominal: totalNominal,
    direction,
    unit,
    currency,
    isMyOrder,
    isCompanyOrder,
    tradable,
    amendable,
    status,
  };
};

export const idGenerator = () => {
  return (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
};

export const getTradeTotals = (orderRows) => {
  let totalVolume = 0;
  let totalNominal = 0;
  let direction = directions.BUY;
  let unit = '';
  let currency = '';
  let isMyOrder = false;
  let isCompanyOrder = false;
  let tradable = true;
  let amendable = true;
  let status;
  let product = "SALMON"
  const volumePerUnit = (unit, product) =>{
    let value =1
    if(unit ==='BOX'){
      if(product === "SALMON"){
        value = 20
      }else{
        value = 22.5
      }
    }
    return value
  } 
  if (orderRows !== undefined) {
    if (orderRows.length !== 0) {
      orderRows.forEach((r) => {
        const row = r.order ? r.order : r;

        status = row.status;
        unit = row.contract ? row.contract.underlying.unit : row.unit;

        currency = row.contract ? row.contract.underlying.currency : row.currency;
        direction = row.direction;
        const vol = Number(row.volume);
        const pri = Number(row.price);
        totalVolume += vol;
        product = row.contract ? row.contract.underlying.product : (row.product ? row.product :"SALMON");
        const nominal = vol * volumePerUnit(unit,product) * pri;
        totalNominal += nominal;
        isMyOrder = row.isMyOrder;
        isCompanyOrder = row.isCompanyOrder;
        if (
          row.status === 'CANCELLED' ||
          row.status === 'SUSPENDED' ||
          row.status === 'FILLED' ||
          row.status === 'IN-PROGRESS' ||
          row.isCompanyOrder
        ) {
          tradable = false;
        }
        if (
          row.status === 'CANCELLED' ||
          row.status === 'FILLED' ||
          row.status === 'IN-PROGRESS' ||
          row.isMyOrder === false
        ) {
          amendable = false;
        }
      });
    }
  }
  const avgPrice = totalNominal / totalVolume / volumePerUnit(unit, product) || 0;

  return {
    volume: totalVolume,
    avgPrice,
    nominal: totalNominal,
    direction,
    unit,
    currency,
    isMyOrder,
    isCompanyOrder,
    tradable,
    amendable,
    status,
  };
};
