import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';

import { directions, status, PRICE_DECIMALS } from '../../../commons/models/constants';

class GridTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handlePopover = (event) => {
    const anchorEl = this.state.anchorEl === null ? event.currentTarget : null;
    this.setState({ anchorEl });
  };

  getTotals = (orders) => {
    let totalVolume = 0;
    let totalOriginalVolume = 0;
    let totalNominal = 0;
    let status;
    orders.forEach((order) => {
      totalVolume += parseInt(order.volume);
      totalOriginalVolume += parseInt(order.originalVolume, 10);
      status = order.status;
      const nominal = parseInt(order.originalVolume, 10) * parseFloat(order.price);
      totalNominal += nominal;
    });
    const price = totalOriginalVolume === 0 ? 0 : totalNominal / totalOriginalVolume;

    return { volume: totalVolume, price: price.toFixed(PRICE_DECIMALS), status };
  };

  render() {
    const { orders, classes } = this.props;
    const id = orders.length !== 0 ? orders[0].id : new Date().toString();
    const totals = this.getTotals(orders);
    totals.status = this.props.totals.status;
    const { volume } = totals;
    const { price } = totals;
    const direction = this.props.direction;
    let containerStyle =
      direction === directions.BUY ? classes.cellContainerBuy : classes.cellContainerSell;
    let volumeStyle = direction === directions.BUY ? classes.volumeCellBuy : classes.volumeCellSell;
    let priceStyle = direction === directions.BUY ? classes.priceCellBuy : classes.priceCellSell;

    if (totals.status === status.SUSPENDED || totals.status === status.IN_PROGRESS) {
      containerStyle = classes.cellContainerSuspended;
      volumeStyle = classes.volumeCellSuspended;
      priceStyle = classes.priceCellSuspended;
    }
    if (totals.status === status.CANCELLED) {
      containerStyle = classes.cellContainerCancelled;
      volumeStyle = classes.volumeCellCancelled;
      priceStyle = classes.priceCellCancelled;
    }
    if (totals.status === status.FILLED) {
      containerStyle = classes.cellContainerFilled;
      volumeStyle = classes.volumeCellFilled;
      priceStyle = classes.priceCellFilled;
    }
    const cellContainer =
      this.props.last === true ? classes.cellContainerLast : classes.cellContainer;
    return (
      <React.Fragment>
        {/*    <div key={id} className={classes.container}> */}
        {orders.length > 0 ? (
          <div className={`${cellContainer} ${containerStyle}`}>
            <div className={volumeStyle}>{volume}</div>

            <div className={priceStyle}>{price}</div>
          </div>
        ) : (
          <div
            className={`${cellContainer} ${containerStyle}`}
            style={{ backgroundColor: 'transparent' }}
          >
            <div className={volumeStyle}></div>

            <div className={priceStyle}></div>
          </div>
        )}
        {/* </div> */}
      </React.Fragment>
    );
  }
}
const styles = (theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRight: '1px solid',
    borderRightColor: fade(theme.palette.text.primary, 0.25),
  },
  cellContainerLast: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cellContainerEmpty: {
    backgroundColor: 'transparent',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: 'transparent',
  },
  cellContainerBuy: {
    backgroundColor: darken(lightBlue[700], 0.6),
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: lightBlue[300],
  },
  cellContainerSell: {
    backgroundColor: darken(teal[700], 0.6),
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: teal[300],
  },
  cellContainerSuspended: {
    backgroundColor: darken(amber[500], 0.6),
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: amber[300],
  },
  cellContainerCancelled: {
    backgroundColor: darken(red[500], 0.6),
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: red,
  },
  cellContainerFilled: {
    backgroundColor: 'transparent',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: theme.palette.divider,
  },

  priceCellBuy: {
    fontSize: '1.5rem',
    color: lightBlue[300],
  },
  priceCellSell: {
    fontSize: '1.5rem',
    color: teal[300],
  },
  priceCellSuspended: {
    fontSize: '1.5rem',
    color: amber[300],
  },
  priceCellCancelled: {
    fontSize: '1.5rem',
    color: red[300],
  },
  priceCellFilled: {
    fontSize: '1.5rem',
    color: '#AAA',
  },
  volumeCellBuy: {
    color: lightBlue[400],
    fontSize: '1rem',
  },
  volumeCellSell: {
    color: teal[400],
    fontSize: '1rem',
  },

  volumeCellCancelled: {
    color: red[400],
    fontSize: '1rem',
  },

  volumeCellSuspended: {
    color: amber[400],
    fontSize: '1rem',
  },

  volumeCellFilled: {
    color: '#CCC',
    fontSize: '1rem',
  },

  gridRowContainer: {
    borderWidth: 1,
    borderBottomColor: theme.palette.divider,
  },
});

const contract = {}; // state.orders.contract,

const mapStateToProps = (state) => {
  return {
    contract,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(GridTile));
