import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.banner,
    borderBottomColor: theme.palette.divider,
    borderBottom: '1px solid',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  headerCellFirst: {
    paddingVertical: theme.spacing.unit,
    paddingLeft: 0,
    flex: 1,
  },
  headerCell1: {
    paddingVertical: theme.spacing.unit,
    flex: 1,
  },
  headerCell2: {
    paddingVertical: theme.spacing.unit,

    flex: 2,
  },
  headerText: {
    color: theme.palette.text.subtitle,
    textAlign: 'center',
  },
});

const MarketViewHeaders = (props) => {
  const { classes } = props;
  return (
    <div className={classes.headerRow}>
      <div className={classes.headerCellFirst}>
        <div className={classes.headerText}>WGT</div>
      </div>
      <div className={classes.headerCell1}>
        <div className={classes.headerText} />
      </div>
      <div className={classes.headerCell1}>
        <div className={classes.headerText} />
      </div>
      <div className={classes.headerCell1}>
        <div className={classes.headerText} />
      </div>
      <div className={classes.headerCell2}>
        <div className={classes.headerText}>BID</div>
      </div>
      <div className={classes.headerCell2}>
        <div className={classes.headerText}>OFFER</div>
      </div>
      <div className={classes.headerCell1}>
        <div className={classes.headerText} />
      </div>
      <div className={classes.headerCell1}>
        <div className={classes.headerText} />
      </div>
      <div className={classes.headerCell1}>
        <div className={classes.headerText} />
      </div>
    </div>
  );
};
MarketViewHeaders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketViewHeaders);
