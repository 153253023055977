import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  IconButton,
  Icon,
  Button,
  TextField,
} from '@material-ui/core';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import PopUp from '../../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import { getVisibleEditorOrderLists, getOrderListsEditorOrderLists } from '../../selectors';

import { UPDATE_EDITOR_ORDER_LISTS } from '../../constants';

import { editorOrderLists as actionEditorOrderLists } from '../../actions';

const styles = (theme) => ({
  title: { fontSize: theme.fontSize * 1.5 },
  container: { backgroundColor: 'rgba(0,0,0,.1)' },
  containerItems: { textAlign: 'center' },
  banner: { backgroundColor: 'rgba(0,0,0,.3)', padding: '5px' },
  textField: { marginTop: '8px', marginBottom: '8px' },
  fab: { marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit },
  nameChange: {
    color: 'rgba(0,0,0,.3)',
    display: 'block',
    padding: '0px 25px',
  },
  nameChangeInput: { color: 'rgba(255,255,255,.3)', display: 'block' },
});

class ManageOrderLists extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
      showText: -1,
    };
  }

  handleToggle = (value) => () => {

    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  addOrderList = () => {
    const payload = {
      action: 'addOrderList',
    };

    this.props.updateEditorOrderLists(payload);
  };

  deleteOrderList = (id) => {
    const payload = {
      action: 'deleteOrderList',
      orderListId: id,
    };

    this.props.updateEditorOrderLists(payload);
  };

  onCopy = (e, id) => {
    const payload = {
      action: 'copyOrderList',
      orderListId: id,
    };

    this.props.updateEditorOrderLists(payload);
  };

  onSave = () => {
    const payload = {
      action: 'saveOrderLists',
    };

    this.props.updateEditorOrderLists(payload);

    this.closeEditor();
  };

  onEditClick = (id) => {
    if (this.state.showText === id) {
      this.setState({ showText: -1 });
    } else {
      this.setState({ showText: id });
    }
  };

  onNameChange = (e, id) => {
    const payload = {
      action: 'changeNameOrderList',
      orderListId: id,
      name: e.target.value,
    };

    this.props.updateEditorOrderLists(payload);
  };

  closeEditor = () => {
    const payload = {
      action: 'showOrderListEditor',
      show: false,
    };

    this.props.updateEditorOrderLists(payload);
  };

  render() {
    const { classes, orderLists } = this.props;
    const { checked } = this.state;

    return (
      <PopUp open={this.props.open} title="Order Lists">
        <div>
          <div className={classes.container}>
            <div className={classes.containerItems}>
              <List className={classes.root}>
                {orderLists.map((value, index) => (
                  <div key={index}>
                    <ListItem
                      key={value.id}
                      role={undefined}
                      dense
                      button
                      onClick={this.handleToggle(value.id)}
                    >
                      <Checkbox
                        checked={checked.indexOf(value.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={value.name} />

                      <ListItemSecondaryAction>
                        <Tooltip title="COPY" aria-label="COPY">
                          <IconButton onClick={(e) => this.onCopy(e, value.id)}>
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="RENAME" aria-label="RENAME">
                          <IconButton onClick={() => this.onEditClick(value.id)}>
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="DELETE" aria-label="DELETE">
                          <IconButton onClick={() => this.deleteOrderList(value.id)}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {this.state.showText === value.id && (
                      <div>
                        <TextField
                          classes={{ root: classes.nameChange }}
                          InputProps={{ className: classes.nameChangeInput }}
                          onChange={(e) => this.onNameChange(e, value.id)}
                          placeholder="add New Name"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </List>
            </div>
          </div>

          <Button
            // variant="flat"
            aria-label="Delete"
            className={classes.fab}
            onClick={this.addOrderList}
          >
            <Icon className={classes.extendedIcon}>add</Icon>
            <Typography>Add New OrderList</Typography>
          </Button>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeEditor} />
              <ConfirmButton onClick={this.onSave} />
            </>
          </ToolbarBottom>
        </div>
      </PopUp>
    );
  }
}
ManageOrderLists.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

const rooms = {}; // state.rooms,
const orders = [];
const trades = [];
const notifications = []; // state.notifications,

function mapStateToProps(state) {
  return {
    // login: state.logins,
    rooms,
    orders,
    trades,
    notifications,
    orderLists: getOrderListsEditorOrderLists(state),
    open: getVisibleEditorOrderLists(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEditorOrderLists: (payload) => {
      dispatch(actionEditorOrderLists(UPDATE_EDITOR_ORDER_LISTS, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageOrderLists));
