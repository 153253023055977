import { DateTime } from 'luxon';
import BondValuation from './bond';
import { TENORS } from '../constants';

const random = (min = 0, max = 1) => Math.random() * (max - min) + min;

export const getTenor = () => TENORS;
export const updateTenor = (prevTenor) => {
  const newTenor = [];

  prevTenor.forEach((tenor) => {
    const bid = random(tenor.bid.min, tenor.bid.max);
    const ask = bid + random(0.001, 0.01);

    let bidDirection = 0;
    let askDirection = 0;

    if (bid > tenor.bid.value) {
      bidDirection = 1;
    } else if (bid < tenor.bid.value) {
      bidDirection = -1;
    }

    if (ask > tenor.ask.value) {
      askDirection = 1;
    } else if (ask < tenor.ask.value) {
      askDirection = -1;
    }

    newTenor.push({
      id: tenor.id,
      bid: {
        min: tenor.bid.min,
        max: tenor.bid.max,
        value: bid,
        changeDirection: bidDirection,
      },
      ask: {
        value: ask,
        changeDirection: askDirection,
      },
    });
  });

  return newTenor;
};

export function getDateRange() {
  const current = DateTime.now();
  const next = current.plus({ days: 2 });

  return [current.toJSDate(), next.toJSDate()];
}

const DECREASED = 0;
const UNCHANGED = 1;
const INCREASED = 2;

let S1_BUFFER = [];
let S2_BUFFER = [];

export function createS1(swaps, modDurations) {
  const buffer = swaps.map((row, i) => {
    const aBID = swaps[i].bid.value;
    const bBID = swaps[0].bid.value;
    const aASK = swaps[i].ask.value;
    const bASK = swaps[0].ask.value;

    const bidValue = i > 0 ? aBID - bBID : null;
    const askValue = i > 0 ? aASK - bASK : null;
    const ratio = i > 0 ? BondValuation.ratio(modDurations[0], modDurations[i]).toFixed(4) : null;

    let bidClassName = UNCHANGED;
    let askClassName = UNCHANGED;

    if (S1_BUFFER[i]) {
      if (bidValue < parseFloat(S1_BUFFER[i].bid.value)) bidClassName = DECREASED;
      if (bidValue > parseFloat(S1_BUFFER[i].bid.value)) bidClassName = INCREASED;

      if (askValue < parseFloat(S1_BUFFER[i].ask.value)) askClassName = DECREASED;
      if (askValue > parseFloat(S1_BUFFER[i].ask.value)) askClassName = INCREASED;
    }

    return {
      id: row.id,
      bid: {
        value: bidValue === null ? '' : bidValue.toFixed(4),
        highlight: bidClassName,
      },
      ask: {
        value: askValue === null ? '' : askValue.toFixed(4),
        highlight: askClassName,
      },
      ratio,
    };
  });

  S1_BUFFER = buffer;

  return buffer;
}

export function createS2(swaps) {
  const buffer = swaps.map((row, i) => {
    const aBID = swaps[i].bid.value;
    const bBID = swaps[1].bid.value;
    const aASK = swaps[i].ask.value;
    const bASK = swaps[1].ask.value;

    const bidValue = i > 1 ? aBID - bBID : null;
    const askValue = i > 1 ? aASK - bASK : null;

    let bidClassName = UNCHANGED;
    let askClassName = UNCHANGED;
    if (S2_BUFFER[i]) {
      if (bidValue < parseFloat(S2_BUFFER[i].bid.value)) bidClassName = DECREASED;
      if (bidValue > parseFloat(S2_BUFFER[i].bid.value)) bidClassName = INCREASED;

      if (askValue < parseFloat(S2_BUFFER[i].ask.value)) askClassName = DECREASED;
      if (askValue > parseFloat(S2_BUFFER[i].ask.value)) askClassName = INCREASED;
    }

    return {
      id: row.id,
      bid: {
        value: bidValue === null ? '' : bidValue.toFixed(4),
        highlight: bidClassName,
      },
      ask: {
        value: askValue === null ? '' : askValue.toFixed(4),
        highlight: askClassName,
      },
    };
  });

  S2_BUFFER = buffer;

  return buffer;
}

export function calculateModifiedDurations(swaps, currentDate, nextDate) {
  return swaps.map((row) =>
    BondValuation.modifiedDuration(
      nextDate,
      DateTime.fromJSDate(nextDate)
        .plus({ days: 365 * row.id })
        .toJSDate(),
      row.bid.value,
      row.bid.value,
      2,
    ),
  );
}
