import { create } from '../../../core/actions';

export const initOrdersSettings = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getOrders = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getOrder = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const createOrder = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const insertOrders = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateOrders = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateDataOrder = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const resetOrders = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const updateForm = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const ordersManager = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const editorOrderLists = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const changeStateOrders = (type, payload, meta) => {
  return create(type, payload, meta);
};
