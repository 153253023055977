import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { TableCell, InputBase, Checkbox, TextField, InputAdornment } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';

import CustomSelect from './CustomSelect';
import OrderRecipients from './OrderRecipients';

import { directions, visibilities } from '../../../../commons/models/constants';

import { getSubSegmentValues } from '../../../contracts/utils/functions';

const styles = (theme) => ({
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.tableBorder,
  },
  cellRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  priceBuy: {
    color: lightBlue[300],
  },
  priceSell: {
    color: teal[300],
  },
  number: {},
  adornment: {
    fontSize: `${theme.fontSize.xs} !important`,
  },
});

const numberFormat = (number) => {
  return Math.round(number * 100) / 100;
};

const getAdornment = (field, props) => {
  switch (field) {
    case 'volume':
      return 'KG';

    case 'mid':
      return props.localCurrency;

    case 'spread':
      return props.localCurrency;

    case 'price':
      return props.finalCurrency;

    default:
      return '';
  }
};

const OrderCell = (props) => {
  switch (props.cellData.name) {
    case 'product':
      const products = getSubSegmentValues(props.contract);
      return (
        <TableCell className={props.classes.tableCell} classes={{ root: props.classes.cellRoot }}>
          <CustomSelect
            enumOptions={products}
            accessor={props.cellData.id}
            onChange={props.onProductTableUpdate}
            value={props.cellData.value}
            cellData={props.cellData}
          />
        </TableCell>
      );

    case 'direction':
      const directionValues = Object.values(directions);
      const classNameDir =
        props.order.direction === 'BUY' ? props.classes.priceBuy : props.classes.priceSell;
      return (
        <TableCell className={props.classes.tableCell}>
          <CustomSelect
            enumOptions={directionValues}
            accessor={props.cellData.id}
            onChange={props.onProductTableUpdate}
            cellData={props.cellData}
            className={classNameDir}
            value={props.cellData.value}
          />
        </TableCell>
      );

    case 'visibility':
      const visibilityValues = Object.values(visibilities);

      return (
        <TableCell className={props.classes.tableCell}>
          <CustomSelect
            enumOptions={visibilityValues}
            accessor={props.cellData.id}
            onChange={props.onProductTableUpdate}
            value={props.cellData.value}
            cellData={props.cellData}
          />
        </TableCell>
      );

    case 'recipients':
      return (
        <TableCell className={props.classes.tableCell}>
          {props.order.visibility === 'SELECTED_ORGANISATIONS' ? (
            <OrderRecipients
              onChange={props.onProductTableUpdate}
              accessor={props.cellData.id}
              name={props.cellData.name}
              value={props.cellData.value}
              recipients={props.tradingPartners}
            />
          ) : null}
        </TableCell>
      );

    case 'id':
      return (
        <TableCell className={props.classes.tableCell}>
          <InputBase
            type="text"
            fullWidth
            onKeyDown={props.onKeyDown}
            name={props.cellData.name}
            id={props.cellData.id}
            value={props.cellData.value}
            onChange={props.onProductTableUpdate(props.cellData.id)}
          />
        </TableCell>
      );
    case 'anonymous':
    case 'active':
      return (
        <TableCell className={props.classes.tableCell}>
          <Checkbox
            name={props.cellData.name}
            id={props.cellData.id}
            checked={props.cellData.value}
            onChange={props.onProductTableUpdate(props.cellData.id)}
          />
        </TableCell>
      );
    case 'harvestDate':
      return null;

    default:
      const classNameNumber =
        props.cellData.name === 'price'
          ? props.order.direction === 'BUY'
            ? props.classes.priceBuy
            : props.classes.priceSell
          : props.classes.number;
      return (
        <TableCell className={props.classes.tableCell}>
          <TextField
            id={props.cellData.id}
            type="number"
            fullWidth
            // className={classNameNumber}
            ref={props.ref}
            value={
              props.cellData.name === 'volume'
                ? Number(props.cellData.value).toFixed(0)
                : numberFormat(props.cellData.value)
            }
            onChange={props.onProductTableUpdate(props.cellData.id)}
            textalign="right"
            onKeyDown={props.onKeyDown}
            name={props.cellData.name}
            InputProps={{
              disableUnderline: true,
              className: classNameNumber,
              inputProps: {
                step: props.cellData.name === 'volume' ? 1 : 0.1,
                style: { textAlign: 'center' },
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography
                  classes={{ root: props.classes.adornment }}
                >
                  {getAdornment(props.cellData.name, props)}
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
      );
  }
};
OrderCell.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderCell);
