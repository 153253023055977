import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton } from '../../../commons/components/buttons';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';

import { getOrganisationFullNameFromId } from '../../../commons/utils/functions';
import TrafficLights from '../../../commons/components/trafficLights';
import { visibilities } from '../../../commons/models/constants';

import { status } from '../../../commons/models/constants';

import { UPDATE_RESPONSE_FORM } from '../../rfqs/constants';
//import {updateForm} from '../../rfqs/actions'
import { updateForm, updateOrders } from '../actions';
import { UPDATE_ORDERS } from '../constants';

import {
  getActiveUser,
  getUsersObject,
  getOrganisationsObject,
  getOrganisations,
  getById,
} from '../selectors';

import { getKeyValuePairsFromOrder } from '../../contracts/utils/modelFunctions';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing.unit,
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  trafficLights: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '8px',
  },
  orderRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 2px',
  },
  orderRowAlt: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class OrderDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (value) => {
    const { activeOrder } = this.state;

    switch (value) {
      case 'Amend':
        const payload = {
          action: 'amendOrder',
          order: activeOrder,
          open: true,
        };

        this.props.updateAmendOrderForm(payload);
        break;
      default:
        if (value) {
          const order = {
            orderId: activeOrder.id,
            status: String(value),
          };

          const payload = {
            items: [order],
          };

          this.props.updateOrders(payload);
        }
        break;
    }
  };

  cancel = () => {
    if (this.props.order.status !== status.CANCELLED) {
      const order = {
        orderId: this.props.order.id,
        status: status.CANCELLED,
      };

      const payload = {
        items: [order],
      };

      this.props.updateOrders(payload);
    }
    this.props.onClose();
  };

  suspend = () => {
    if (
      this.props.order.status !== status.CANCELLED &&
      this.props.order.status !== status.SUSPENDED
    ) {
      const order = {
        orderId: this.props.order.id,
        status: status.SUSPENDED,
      };

      const payload = {
        items: [order],
      };

      this.props.updateOrders(payload);
    }
    this.props.onClose();
  };

  activate = () => {
    if (this.props.order.status !== status.CANCELLED && this.props.order.status !== status.ACTIVE) {
      const order = {
        orderId: this.props.order.id,
        status: status.ACTIVE,
      };

      const payload = {
        items: [order],
      };

      this.props.updateOrders(payload);
    }
    this.props.onClose();
  };

  // TODO:Remove
  amendOrder = () => {
    const order = this.props.orderById;

    if (order.status !== status.CANCELLED) {
      const payload = {
        type: 'amend',
        action: 'openForm',
        rfq: null,
        orders: order,
        open: true,
      };
      console.log('button click', payload);
      this.props.updateAmendOrderForm(payload);
    }
    this.props.onClose();
  };

  getRecipients = (order, organisations, organisationId) => {
    const selectedOrganisationsIds =
      order !== null
        ? order.organisationId === organisationId
          ? order.selectedOrganisationsIds
          : []
        : [];
    const visibility =
      order !== null ? (order.organisationId === organisationId ? order.visibility : '') : '';
    let list = [];
    const orgs = [...organisations];

    if (visibility === visibilities.SELECTED_ORGANISATIONS) {
      selectedOrganisationsIds.forEach((r) => {
        let shortName = r.shortName || null;
        if (shortName === null) {
          shortName = getOrganisationFullNameFromId(r, orgs);
        }
        list.push(shortName);
      });
    } else {
      list = [visibility];
    }

    list.sort();

    return list;
  };

  render() {
    const { classes, users, organisations, user, orgs } = this.props;

    const order = this.props.orderById;

    const isCompanyOrder = order.organisationId === user.organisationId;
    const isMyOrder = order.userId === user.id;

    const userData = {
      'User Name': `${users[order.userId].firstName} ${users[order.userId].lastName}`,
      'Organisation Name': organisations[order.organisationId].name,
      'Org Short Name': organisations[order.organisationId].shortName,
    };

    let orderRows = [];
    if (order.orderData) {
      orderRows = getKeyValuePairsFromOrder(order);
    }

    const list = this.getRecipients(order, orgs, user.organisationId);

    return (
      <PopUp
        open={this.props.open}
        title="ORDER DETAIL"
        onClose={this.props.onClose}
        containerWidth={1000}
      >
        <div className={classes.container}>
          {isMyOrder && (
            <div className={classes.trafficLights}>
              <TrafficLights
                cancelAll={this.cancel}
                suspendAll={this.suspend}
                activateAll={this.activate}
                orderClick={this.amendOrder} // TODO:Remove
                // orderClick={this.updateOrder} // NEW
                edit
              />
            </div>
          )}

          <div className={classes.listContainer}>
            <List open classes={{ root: classes.list }}>
              {Object.keys(userData).map((item, index) => (
                <ListItem key={index} button classes={{ root: classes.listItem }}>
                  <ListItemText primary={item} className={classes.key} />
                  <ListItemText primary={userData[item]} align="right" />
                </ListItem>
              ))}
              {isCompanyOrder === true && (
                <ListItem key="recipients" button classes={{ root: classes.listItem }}>
                  <ListItemText primary="Order Sent To:" className={classes.key} />
                  <ListItemText
                    primary={
                      <>
                        {list.map((v) => {
                          return <div>{v}</div>;
                        })}
                      </>
                    }
                    align="right"
                  />
                </ListItem>
              )}
              <ListItem key={'Comments'} button classes={{ root: classes.listItem }}>
                <ListItemText primary={'Comments'} className={classes.key} />
                <ListItemText primary={order.textMessage} align="right" />
              </ListItem>
            </List>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              {orderRows.map((orderRow, index) => {
                return (
                  <div className={orderRows.length < 6 ? classes.orderRow : classes.orderRowAlt}>
                    <div className={classes.itemTitle}>{`Item ${index + 1}`}</div>
                    {orderRow.map((item) =>
                      orderRows.length < 6 ? (
                        <ReadOnlyField displayName={item.key} value={item.value} fullWidth />
                      ) : (
                        <ReadOnlyField displayName={item.key} value={item.value} />
                      ),
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.onClose} />
                {/* <ConfirmButton onClick={this.onSave} /> */}
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
OrderDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const rooms = []; // state.rooms,

function mapStateToProps(state, props) {
  const orderId = props.order ? props.order.id : null;
  return {
    user: getActiveUser(state),
    users: getUsersObject(state),
    organisations: getOrganisationsObject(state),
    rooms,
    orgs: getOrganisations(state),
    orderById: getById(state, orderId),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAmendOrderForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    updateOrders: (payload) => {
      dispatch(updateOrders(UPDATE_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderDetail));
