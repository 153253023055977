import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import {
  Modal,
  Typography,
  InputBase,
  Button,
  Icon,
  FormControl,
  Menu,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';

import { directions, visibilities } from '../../../../commons/models/constants';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import {
  CancelButton,
  ConfirmButton,
  ResetButton,
  SaveButton,
  ClearButton,
} from '../../../../commons/components/buttons';
import { checkVolume } from '../../../../commons/config/formatters';
import SelectField from '../../../../commons/components/formFields/SelectField';
import DirectionField from '../../../../commons/components/formFields/DirectionField';
import DateField from '../../../../commons/components/formFields/dateField';
import MultipleSelectField from '../../../../commons/components/formFields/multipleSelectField';
import MultipleSelectFieldOrgs from '../../../../commons/components/formFields/multipleSelectFieldOrgs';
import DateTimeField from '../../../../commons/components/formFields/dateTimeField';
import NumberFieldFilled from '../../../../commons/components/formFields/numberFieldFilled';
import FormRow from './FormRow';
import CheckCommentsPrompt from '../CheckCommentsPrompt';
import SaveRfqForm from './SaveRfqForm';

import { idGenerator } from '../../../../commons/config/functions';
import { getContractWeek, getFilteredDates } from '../../../contracts/utils/deliveryWeeks';

import {
  getActiveContract,
  //getActiveMarket,
  isOpenFormCreate,
  getOrganisations,
  getRfqFormOrder,
  getActiveUser,
  getRfqFormFields,
  getEnumerations,
  getRfqOrderRows,
  getTradingPartners,
  getContractType,
  getSavedRfqPresets,
} from '../../selectors';

import { updateForm as actionUpdateForm, createRFQ as actionCreateRFQ } from '../../actions';
import { UPDATE_FORM, CREATE_RFQ } from '../../constants';
import { isArray } from 'lodash';
import { ORDERS_MANAGER_TABLE_ADD_ROW_SUCCESS } from '../../../orders/constants';
const FIELD_WIDTH = '152px';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    //height: '70vh',
    //maxHeight: '70vh',
    flex: 1,
    overflowY: 'scroll',
  },
  section: {
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    alignItems: 'center',
  },
  subsectionNoFlex: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.row,
    //overFlowY: "scroll"
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: "scroll"
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    color: theme.palette.text.hint,
    fontSize: theme.fontSize.sm,
  },
  title: { fontSize: theme.fontSize.lg },
  filesEmpty: {
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(),
    margin: theme.spacing(),
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    color: theme.palette.text.disabled,
  },
});

const ERROR_VOLUME = 'Volume must be an integer > 0.';
const ERROR_RECIPIENT = 'At least one organisation must be selected.';

// TODO: Extract all functionality to saga ans store and constants in config
class Form extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorVolume: { message: '', value: false },
      errorPrice: { message: '', value: false },
      errorSelectedOrganisations: { message: '', value: false },
      promptOpen: false,
      saveFormOpen: false,
      anchorEl: null,
    };
  }

  getErrors = () => {
    const order = this.props.order || {};
    let errorVolume = checkVolume(order.volume)
      ? { message: '', value: false }
      : { message: ERROR_VOLUME, value: true };
    let errorPrice = { message: '', value: false };
    let errorSelectedOrganisations = { message: '', value: false };
    if (order.visibility === 'SELECTED_ORGANISATIONS') {
      if (order.selectedOrganisationsIds.length === 0) {
        errorSelectedOrganisations = { message: ERROR_RECIPIENT, value: true };
      }
    }
    return { errorVolume, errorPrice, errorSelectedOrganisations };
  };
  addOrderRow = () => {
    const order = this.props.order;
    const totalVolume = order.volume;
    const orderRowsOld = this.props.orderRows;
    const increment = parseInt(totalVolume / (orderRowsOld.length + 1));
    const finalIncrement =
      orderRowsOld.length === 0
        ? totalVolume
        : parseInt(totalVolume - increment * orderRowsOld.length);

    const row = {
      key: idGenerator(),
    };
    const fields = this.props.fields.breakDownRowFields;
    fields.forEach((field) => {
      let orderValue = field.defaultValue;
      if (order[field.accessor]) {
        if (order[field.accessor] !== null && order[field.accessor] !== undefined) {
          if (isArray(order[field.accessor])) {
            orderValue = order[field.accessor][0];
          } else {
            orderValue = order[field.accessor];
          }
        }
      }
      row[field.accessor] = orderValue;
    });
    row.weightClass = order.weightClasses[0] || '1-2';
    const orderRows = [...orderRowsOld, row];
    for (let i = 0; i < orderRows.length; i++) {
      if (i === orderRows.length - 1) {
        orderRows[i].volume = finalIncrement;
      } else {
        orderRows[i].volume = increment;
      }
    }
    const totals = this.updateTotals(orderRows);

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };

  deleteOrderRow = (key) => {
    const orderRowsOld = [...this.props.orderRows];
    const index = orderRowsOld.findIndex((i) => i.key === key);
    orderRowsOld.splice(index, 1);
    const totalVolume = this.props.order.volume;
    const increment = parseInt(totalVolume / orderRowsOld.length);
    const finalIncrement =
      orderRowsOld.length === 0
        ? totalVolume
        : parseInt(totalVolume - increment * (orderRowsOld.length - 1));
    const orderRows = [...orderRowsOld];
    for (let i = 0; i < orderRows.length; i++) {
      if (i === orderRows.length - 1) {
        orderRows[i].volume = finalIncrement;
      } else {
        orderRows[i].volume = increment;
      }
    }
    let totals = {};
    if (orderRows.length !== 0) {
      totals = this.updateTotals(orderRows);
    }

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };

  copyOrderRow = (key) => {
    const orderRows = [...this.props.orderRows];
    const index = orderRows.findIndex((i) => i.key === key);

    if (index > -1) {
      const foundRow = orderRows[index];
      const newRow = Object.assign({}, foundRow);
      newRow.key = idGenerator();
      orderRows.splice(index, 0, newRow);
    }

    const totals = this.updateTotals(orderRows);

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };
  updateOrderRow = (row) => {
    const orderRows = [...this.props.orderRows];
    const index = orderRows.findIndex((i) => i.key === row.key);
    orderRows[index] = row;
    const totals = this.updateTotals(orderRows);

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };
  updateTotals = (rows) => {
    let allWeights = [];
    let allPresentation = [];
    let allQuality = [];
    let volume = 0;
    rows.forEach((r) => {
      allWeights.push(r.weightClass);
      allPresentation.push(r.presentation);
      allQuality.push(r.quality);
      volume += Number(r.volume);
    });
    const weightClasses = [...new Set(allWeights)];
    let presentation = [...new Set(allPresentation)];
    let quality = [...new Set(allQuality)];
    if (presentation.includes('ANY')) {
      presentation = ['ANY'];
    }
    if (quality.includes('ANY')) {
      quality = ['ANY'];
    }

    const values = this.props.enumerations.weightClasses;

    weightClasses.sort((a, b) => values.indexOf(a) - values.indexOf(b));

    return { weightClasses, presentation, quality, volume };
  };

  adjustOrderQuantities = (totalVolume) => {
    const { orderRows } = this.props;

    if (orderRows.length > 0) {
      const increment = parseInt(totalVolume / orderRows.length);
      const finalIncrement =
        orderRows.length === 0
          ? totalVolume
          : parseInt(totalVolume - increment * (orderRows.length - 1));
      for (let i = 0; i < orderRows.length; i++) {
        let volume = Number(orderRows[i].volume);
        if (i === orderRows.length - 1) {
          volume = finalIncrement;
        } else {
          volume = increment;
        }
        orderRows[i].volume = volume;
      }
    }
    return orderRows;
  };

  handleChange = (name) => (event) => {
    let payload = {
      type: 'create',
      action: 'update',
      value: event.target.value,
      name,
    };

    switch (name) {
      case 'week':
        payload.action = 'updateDates';
        break;
      case 'validity':
        const value =
          this.props.order.timeInForce === null ? moment().format('YYYY-MM-DDTHH:mm') : null;
        payload.action = 'updateValidity';
        payload.value = value;
        payload.name = 'timeInForce';
        break;
      case 'volume':
        const orderRows = this.adjustOrderQuantities(payload.value);
        const { weightClasses } = this.props.order;
        const volume = Number(event.target.value);
        payload = {
          type: 'create',
          action: 'updateOrderRows',
          value: { orderRows, totals: { weightClasses, volume: volume } },
          name,
        };

        break;
      default:
    }
    this.props.updateForm(payload);
  };

  handleChangeMultiple = (name) => (event) => {
    let value;
    const oldValue = this.props.order[name] || [];
    if (name === 'weightClasses') {
      value = event.target.value;

      this.props.orderRows.forEach((row) => {
        if (!value.includes(row.weightClass)) {
          value.push(row.weightClass);
        } else {
        }
      });
      const values = this.props.enumerations['weightClasses'];
      value.sort((a, b) => values.indexOf(a) - values.indexOf(b));
    }
    if (name === 'quality' || name === 'presentation') {
      value = [...event.target.value];
      this.props.orderRows.forEach((row) => {
        if (!value.includes(row[name])) {
          value.push(row[name]);
        } else {
        }
      });
      const values = this.props.enumerations[name];
      value.sort((a, b) => values.indexOf(a) - values.indexOf(b));
    }
    if (event.target.value.indexOf('ANY') === -1) {
      if (event.target.value.length === 0) {
        value = ['ANY'];
      } else {
        value = event.target.value;
      }
    } else if (oldValue.indexOf('ANY') === -1) {
      value = ['ANY'];
    } else {
      value = event.target.value;
      const removed = value.splice(value.indexOf('ANY'), 1);
    }

    const payload = {
      type: 'create',
      action: 'update',
      value: value,
      name,
    };
    this.props.updateForm(payload);
  };

  handleDateChange = (name) => (date) => {
    let {
      minHarvestDate,
      maxHarvestDate,
      minDeliveryDate,
      maxDeliveryDate,
      minFreezeDate,
      maxFreezeDate,
      processingDate,
      useByDate,
      week,
      states,
    } = this.props.order;
    const fresh = states === 'FRESH' ? 'FRESH' : 'FROZEN';
    if (name === 'minHarvestDate') {
      if (date > moment(minDeliveryDate)) {
        minDeliveryDate = date;
        if (fresh === 'FROZEN') {
          week = getContractWeek(new Date(date));
        }
      }

      if (date > moment(maxDeliveryDate)) {
        maxDeliveryDate = date;
      }
      if (fresh !== 'FROZEN') {
        if (date > moment(maxHarvestDate)) {
          maxHarvestDate = date;
        }
        maxHarvestDate = date;
        week = getContractWeek(new Date(date));
      }

      minHarvestDate = date;
      if (date > moment(minFreezeDate)) {
        minFreezeDate = date;
      }
    } else if (name === 'minDeliveryDate') {
      if (fresh === 'FROZEN') {
        week = getContractWeek(new Date(date));
      }
      minDeliveryDate = date;
      maxDeliveryDate = date;
    } else if (name === 'maxHarvestDate') {
      maxHarvestDate = date;
    } else if (name === 'maxDeliveryDate') {
      maxDeliveryDate = date;
      if (date < moment(maxHarvestDate)) {
        maxHarvestDate = date;
      }
      if (date < moment(maxFreezeDate)) {
        maxFreezeDate = date;
      }
      if (fresh === 'FROZEN') {
        maxHarvestDate = date;
        maxFreezeDate = date;
      }
    } else if (name === 'minFreezeDate') {
      minFreezeDate = date;
      if (fresh !== 'FROZEN') {
        if (date > moment(maxFreezeDate)) {
          maxFreezeDate = date;
        }

        maxFreezeDate = date;
      }
      if (date < moment(minHarvestDate)) {
        minHarvestDate = date;
      }
    } else if (name === 'maxFreezeDate') {
      maxFreezeDate = date;
    } else if (name === 'useByDate') {
      useByDate = date;
    } else if (name === 'processingDate') {
      processingDate = date;
    }

    const newValues = {
      minHarvestDate,
      maxHarvestDate,
      minDeliveryDate,
      maxDeliveryDate,
      minFreezeDate,
      maxFreezeDate,
      useByDate,
      processingDate,
      week,
    };

    const payload = {
      type: 'create',
      action: 'changeDates',
      value: newValues,
    };

    this.props.updateForm(payload);
  };
  handleStartDateChange = (name) => (date) => {
    const payload = {
      type: 'create',
      action: 'update',
      value: date,
      name,
    };

    this.props.updateForm(payload);
  };

  createRFQ = () => {
    const errors = this.getErrors();
    const { errorVolume, errorSelectedOrganisations } = errors;
    const { order } = this.props;
    if (errorVolume.value === false && errorSelectedOrganisations.value === false) {
      if (order.rfqType === 'CONTRACT' && order.textMessage === '') {
        this.setState({ promptOpen: true });
      } else {
        this.sendRfq();
      }
    }
  };

  sendRfq = () => {
    const { orderRows } = this.props;
    this.props.createRFQ({ orderRows });
    this.setState({ promptOpen: false });
    this.closeForm();
  };

  getMinDate = (field) => {
    const { order } = this.props;

    switch (field) {
      case 'minHarvestDate':
        return moment().add(-730, 'days');
      case 'maxHarvestDate':
        return order.minHarvestDate;
      case 'minDeliveryDate':
        return moment().add(-7, 'days');
      case 'maxDeliveryDate':
        return order.minDeliveryDate;
      case 'minFreezeDate':
        return moment().add(-730, 'days');
      case 'maxFreezeDate':
        return order.minHarvestDate;
      case 'processingDate':
        return order.states === 'FRESH' ? moment().add(-14, 'days') : moment().add(-730, 'days');
      default:
        return moment().add(-14, 'days');
    }
  };

  getMaxDate = (field) => {
    const { order } = this.props;
    switch (field) {
      case 'minFreezeDate':
        return order.maxDeliveryDate;
      case 'maxFreezeDate':
        return order.maxDeliveryDate;
      default:
        return new Date(2100, 1, 1);
    }
  };

  closeForm = () => {
    const payload = {
      type: 'create',
      action: 'close',
    };

    this.props.updateForm(payload);
  };
  resetForm = () => {
    const payload = {
      type: 'create',
      action: 'reset',
    };

    this.props.updateForm(payload);
  };
  saveForm = () => {
    this.setState({ saveFormOpen: true });
  };
  saveRfqPreset = (title) => {
    const next = [...this.props.savedRfqPresets];
    const index = next.findIndex((item) => item.name === title);
    const nextRfq = JSON.parse(JSON.stringify(this.props.order));
    const nextOrderRows = JSON.parse(JSON.stringify(this.props.orderRows));
    nextRfq.orderRows = nextOrderRows;
    if (index === -1) {
      const newpreset = {
        name: title,
        id: idGenerator(),
        data: nextRfq,
      };
      next.push(newpreset);
    } else {
      next[index].data = nextRfq;
    }
    const payload = {
      type: 'create',
      action: 'savePresets',
      item: next,
    };

    this.props.updateForm(payload);
    this.setState({ saveFormOpen: false });
  };
  chooseRfqPreset = (preset) => {
    const payload = {
      type: 'create',
      action: 'choosePreset',
      item: preset,
    };

    this.props.updateForm(payload);
    this.setState({ anchorEl: null });
  };
  openPreset = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  render() {
    const {
      classes,
      fields,
      enumerations,
      order,
      contractType,
      open,
      savedRfqPresets,
    } = this.props;
    const formFields = fields.formFields || [];
    const rfqTypeFields = fields.rfqTypeFields || [];
    const breakDownRowFields = fields.breakDownRowFields || [];
    const subSegment = contractType.rfqSubSegment || '';
    const subSegmentObject = formFields.find((f) => f.accessor === subSegment) || {};
    const errors = this.getErrors();
    const { errorSelectedOrganisations, errorVolume } = errors;
    const periods =
      order.contractFrequency === 'MONTHLY' ? 12 : order.contractFrequency === 'QUARTERLY' ? 4 : 52;
    const periodsEnum = [];
    let i;
    for (i = 1; i <= periods; i++) {
      periodsEnum.push(i);
    }
    const mainForm = () => {
      return (
        <Modal open={open} onClose={this.closeForm}>
          <div style={getModalStyle()} className={classes.paper}>
            <Typography className={classes.title}>RFQ REQUEST</Typography>
            <div className={classes.container}>
              {true && (
                <div className={classes.section}>
                  <Typography className={classes.banner}>ORDER TYPE</Typography>
                  <div className={classes.subsection}>
                    <DirectionField
                      accessor="direction"
                      displayName="Direction"
                      value={order.direction}
                      values={Object.keys(directions)}
                      handleChange={this.handleChange}
                      fullWidth
                    />

                    <SelectField
                      accessor="visibility"
                      displayName="Visibility"
                      value={order.visibility}
                      values={visibilities}
                      handleChange={this.handleChange}
                      fullWidth
                    />

                    {order.visibility === 'SELECTED_ORGANISATIONS' && (
                      <MultipleSelectFieldOrgs
                        key="selectedOrganisationsIds"
                        accessor="selectedOrganisationsIds"
                        displayName="Visible To:"
                        value={order.selectedOrganisationsIds}
                        values={this.props.tradingPartners}
                        handleChange={this.handleChange}
                        errorMessage={errorSelectedOrganisations.message}
                        errorValue={errorSelectedOrganisations.value}
                        fullWidth
                      />
                    )}
                  </div>
                </div>
              )}

              {true && (
                <div className={classes.section}>
                  <Typography className={classes.banner}>COMMENTS</Typography>
                  <div className={classes.subsection}>
                    <InputBase
                      value={order.textMessage}
                      type="text"
                      label="Comments"
                      placeholder="Please add order comments"
                      multiline
                      fullWidth
                      onChange={this.handleChange('textMessage')}
                      style={{ fontSize: '16px' }}
                    />
                  </div>
                </div>
              )}
              <div className={classes.section}>
                <Typography className={classes.banner}>RFQ TYPE</Typography>
                <div className={classes.subsectionNoFlex}>
                  {rfqTypeFields.map((field) => {
                    switch (field.component) {
                      case 'select':
                        return (
                          <React.Fragment key={field.accessor}>
                            <SelectField
                              accessor={field.accessor}
                              displayName={field.displayName}
                              value={order[field.accessor]}
                              values={enumerations[field.accessor]}
                              handleChange={this.handleChange}
                              width={FIELD_WIDTH}
                            />
                          </React.Fragment>
                        );

                      case 'date':
                        return (
                          <React.Fragment key={field.accessor}>
                            <DateField
                              accessor={field.accessor}
                              displayName={field.displayName}
                              value={order[field.accessor]}
                              disablePast={field.disablePast}
                              handleDateChange={this.handleStartDateChange}
                              //minDate={this.getMinDate(field.accessor)}
                              //maxDate={this.getMaxDate(field.accessor)}
                              width={FIELD_WIDTH}
                              clearable={field.clearable}
                            />
                          </React.Fragment>
                        );

                      default:
                        return null;
                    }
                  })}

                  <SelectField
                    accessor="validity"
                    displayName="Validity"
                    value={order.timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                    values={['GTC', 'TIME IN FORCE']}
                    handleChange={this.handleChange}
                    width={FIELD_WIDTH}
                  />
                  {order.timeInForce !== null && order.timeInForce !== 'null' && (
                    <DateTimeField
                      accessor="timeInForce"
                      displayName="Time In Force"
                      value={order.timeInForce}
                      handleChange={this.handleChange}
                    />
                  )}
                </div>
              </div>

              <div className={classes.section}>
                <Typography className={classes.banner}>PRODUCT</Typography>
                <div className={classes.subsectionNoFlex}>
                  {formFields.map((field) => {
                    if (field.accessor !== subSegment) {
                      switch (field.component) {
                        case 'select':
                          return (
                            <React.Fragment key={field.accessor}>
                              <SelectField
                                accessor={field.accessor}
                                displayName={field.displayName}
                                value={order[field.accessor]}
                                values={enumerations[field.accessor]}
                                handleChange={this.handleChange}
                                width={FIELD_WIDTH}
                              />
                            </React.Fragment>
                          );

                        case 'date':
                          return (
                            <React.Fragment key={field.accessor}>
                              <DateField
                                accessor={field.accessor}
                                displayName={field.displayName}
                                value={order[field.accessor]}
                                disablePast={field.disablePast}
                                handleDateChange={this.handleDateChange}
                                minDate={this.getMinDate(field.accessor)}
                                maxDate={this.getMaxDate(field.accessor)}
                                width={FIELD_WIDTH}
                                clearable={field.clearable}
                              />
                            </React.Fragment>
                          );

                        case 'multiSelect':
                          return (
                            <React.Fragment key={field.accessor}>
                              <MultipleSelectField
                                accessor={field.accessor}
                                displayName={field.displayName}
                                value={order[field.accessor]}
                                values={enumerations[field.accessor] || []}
                                handleChange={this.handleChangeMultiple}
                                any
                                width={FIELD_WIDTH}
                              />
                            </React.Fragment>
                          );

                        default:
                          return null;
                      }
                    }
                  })}
                </div>
              </div>

              <div className={classes.sectionFlex}>
                <div
                  className={classes.banner}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{ padding: 0, fontSize: 'inherit' }}
                  >{`ORDER (${this.props.orderRows.length} items)`}</Typography>
                </div>
                <div className={classes.subsection}>
                  <MultipleSelectField
                    accessor={'weightClasses'}
                    displayName={'Weight Classes'}
                    value={order['weightClasses'] || []}
                    values={enumerations['weightClasses'] || []}
                    handleChange={this.handleChangeMultiple}
                    fullWidth
                  />
                  <NumberFieldFilled
                    accessor="volume"
                    displayName="Total Volume"
                    value={order.volume}
                    handleChange={this.handleChange}
                    error={errorVolume}
                    adornment={order.unit}
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <Button
                      style={{ fontSize: 'inherit' }}
                      aria-label="Delete"
                      onClick={this.addOrderRow}
                    >
                      <Icon className={classes.extendedIcon}>add</Icon>
                      <Typography style={{ textAlign: 'right' }}>Add Breakdown Item</Typography>
                    </Button>
                  </FormControl>
                </div>
                <div className={classes.orders}>
                  {this.props.orderRows.map((row, index) => (
                    <FormRow
                      key={row.key}
                      orderRow={row}
                      copyOrderRow={() => this.copyOrderRow(row.key)}
                      deleteOrderRow={() => this.deleteOrderRow(row.key)}
                      updateOrderRow={this.updateOrderRow}
                      index={index}
                      enumerations={enumerations}
                      formFields={breakDownRowFields}
                      adornment={order.unit}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/*    <Button aria-label="Delete" onClick={this.addOrderRow}>
                        <Icon className={classes.extendedIcon}>add</Icon>
                        <Typography>Add Breakdown Item</Typography>
                    </Button> */}

            <ToolbarBottom>
              <>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <CancelButton onClick={this.closeForm} />
                  <ResetButton onClick={this.resetForm} />
                  <SaveButton onClick={this.saveForm} />
                  <ClearButton onClick={this.openPreset} />
                </div>

                <ConfirmButton onClick={this.createRFQ} />
              </>
            </ToolbarBottom>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={() => this.setState({ anchorEl: null })}
              MenuListProps={{ style: { minWidth: '240px' } }}
            >
              {savedRfqPresets.length === 0 && (
                <div className={classes.filesEmpty}>
                  <div>No Saved Presets</div>
                  <Icon>filter_none</Icon>
                </div>
              )}
              {savedRfqPresets.map((preset) => {
                return (
                  <MenuItem key={preset.id} onClick={() => this.chooseRfqPreset(preset)}>
                    {preset.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Modal>
      );
    };

    if (this.state.promptOpen) {
      return (
        <CheckCommentsPrompt
          open={this.state.promptOpen}
          onClose={() => this.setState({ promptOpen: false })}
          continue={this.sendRfq}
        />
      );
    } else if (this.state.saveFormOpen) {
      return (
        <SaveRfqForm
          open={this.state.saveFormOpen}
          onClose={() => this.setState({ saveFormOpen: false })}
          savedRfqPresets={savedRfqPresets}
          saveRfqPreset={this.saveRfqPreset}
        />
      );
    } else {
      return mainForm();
    }
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const matchIds = []; // state.client.orderEntryMatchIds,

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state),
    user: getActiveUser(state),
    tradingPartners: getTradingPartners(state),
    contract: getActiveContract(state),
    open: isOpenFormCreate(state),
    matchIds,
    order: getRfqFormOrder(state),
    fields: getRfqFormFields(state),
    enumerations: getEnumerations(state),
    contractType: getContractType(state),
    orderRows: getRfqOrderRows(state),
    savedRfqPresets: getSavedRfqPresets(state) || [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (payload) => {
      dispatch(actionUpdateForm(UPDATE_FORM, payload));
    },
    createRFQ: (payload) => {
      dispatch(actionCreateRFQ(CREATE_RFQ, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Form));
