import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import UserAvatar from '../userAvatar';
import { formatDateTime } from '../../config/formatters';
import { getContacts } from '../../selectors';
const styles = (theme) => ({
  notifications: {
    width: '440px',
  },
  notificationHeader: {
    color: fade(theme.palette.text.primary, 0.7),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.spacing.unit * 1.5,
    backgroundColor: theme.palette.primary.main,
  },
  listItem: {
    backgroundColor: theme.palette.background.row,
    margin: '2px',
  },
  messageContainer: {
    // backgroundColor: theme.palette.background.widget,
    overflowY: 'scroll',
    padding: theme.spacing.unit,
  },
  primaryText: {
    display: 'inline',
    fontSize: theme.fontSize.md,
  },
  secondaryText: {
    color: theme.palette.text.subtitle,
    fontSize: theme.fontSize.sm,
  },
});

const ChatNotification = (props) => {
  const { notification, classes, contacts } = props;
  const userId = notification.createdBy || '';
  const userIndex = contacts.findIndex((c) => c.id === userId);
  const timestamp = formatDateTime(notification.createdTime);
  let org = '';
  let username = '';
  if (userIndex > -1) {
    org = contacts[userIndex].organisationId;
    username = `${contacts[userIndex].firstName} ${contacts[userIndex].lastName}`;
  }

  return (
    <ListItem alignItems="flex-start" className={classes.listItem}>
      <ListItemAvatar>
        <UserAvatar organisationId={org} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography
              component="span"
              variant="h1"
              className={classes.primaryText}
              color="textPrimary"
            >
              {username}
            </Typography>
            <Typography
              component="span"
              variant="body1"
              className={classes.primaryText}
              color="textSecondary"
            >
              {' '}
              {' - '}
              {notification.text}
            </Typography>
          </>
        }
        secondary={
          <Typography className={classes.secondaryText}>
            {notification.type} - {timestamp}
          </Typography>
        }
      />
    </ListItem>
  );
};

ChatNotification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    contacts: getContacts(state),
  }
}
export default connect(mapStateToProps)(withStyles(styles)(ChatNotification));
