import { create } from '../../../core/actions';

export const getContracts = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const getContract = (type, payload, meta) => {
  return create(type, payload, meta);
};

export const changeStateContract = (type, payload, meta) => {
  return create(type, payload, meta);
};
