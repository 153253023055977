import {
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_ERROR,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_ERROR,
  RESET_CONTRACTS,
} from '../constants';

import { insertItems } from '../../../core/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACTS:
      return {
        ...state,
        status: 'pending',
      };
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_CONTRACTS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case GET_CONTRACT:
      return {
        ...state,
        status: 'pending',
      };
    case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_CONTRACT_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_CONTRACTS:
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
